<template>
	<header class="header">
		<LogoComponent :auth="auth" />
        <div v-if="auth && !projectRoles && !publicCastingDashboard && !isActorPortfolio" class="header-content">
            <div class="nav-project">
                <router-link to="/active/projects">Active Projects</router-link>
                <router-link to="/archived/projects">Archived Projects</router-link>
            </div>
        </div>
        <div v-if="auth && projectRoles || publicCastingDashboard || isActorPortfolio" class="header-content">
            <div class="select-project" v-if="projectRoles">
                <span class="select-project-text">Casting Roles for the</span>
                <Multiselect 
                    v-model="project" 
                    :options="projects" 
                    :searchable="false" 
                    :close-on-select="true" 
                    :show-labels="false"
                    :allow-empty="false"
                    track-by="name"
                    label="name" 
                    placeholder="Films" 
                    class="project-multiselect header-multiselect"
                    @input="onChange"
                />
            </div>
            <div v-else class="select-project">
                <span class="select-casting-text">Role:</span>
                <Multiselect 
                    v-model="role" 
                    :options="roles" 
                    :searchable="false" 
                    :close-on-select="true" 
                    :show-labels="false"
                    :allow-empty="false"
                    track-by="name"
                    label="name" 
                    placeholder="Role" 
                    class="project-multiselect casting-multiselect"
                    @input="changeRole"
                />
                <span> | </span>
                <span class="select-casting-text" style="margin-left: 25px;">Film:</span>
                <Multiselect 
                    v-model="project" 
                    :options="projects" 
                    :searchable="false" 
                    :close-on-select="true" 
                    :show-labels="false"
                    :allow-empty="false"
                    track-by="name"
                    label="name" 
                    placeholder="Film" 
                    class="project-multiselect casting-multiselect"
                    @input="changeProject"
                />
            </div>
        </div>
        <div v-if="auth" class="logout">
            <button @click="logout">Logout</button>
        </div>
    </header>
</template>

<script>
import LogoComponent from '../components/LogoComponent.vue';
import Multiselect from 'vue-multiselect';
import { mapGetters } from 'vuex';

export default {
    components: {
        LogoComponent,
        Multiselect
    },
    props: {
        projectRoles: {
            default: false,
            type: Boolean
        },
        publicCastingDashboard: {
            default: false,
            type: Boolean
        },
        isActorPortfolio: {
            default: false,
            type: Boolean
        }
    },
    data() {
        return {
            auth: false,
            project: null,
            role: null
        }
    },
    watch: {
        getProject: {
            handler(getProject) {
                this.project = {
                    id: getProject.id,
                    name: getProject.name
                }
            }
        },
        getRole: {
            handler(getRole) {
                this.role = {
                    id: getRole.id,
                    name: getRole.name
                }
            }
        }
    },
    created() {
        this.auth = this.$route.meta.layout === 'dashboard-layout';
    },
    computed: {
        ...mapGetters(['getProjects', 'getProject', 'getRole', 'getRoles']),
        projects() {
            if (this.publicCastingDashboard || this.isActorPortfolio) {
                return this.getProjects.filter(item => item.attributes.roles.length).map(project => {
                    return {
                        id: project.id,
                        name: project.attributes.name
                    } 
                })
            }
            return this.getProjects.map(project => {
                return {
                    id: project.id,
                    name: project.attributes.name
                }
            })
        },
        roles() {
            return this.getRoles.map(role => {
                return {
                    id: role.id,
                    name: role.attributes.name
                }
            })
        }
	},
    methods: {
        logout() {
            EventBus.$emit('logout')
        },
        onChange(project) { this.$router.push(`/projects/${project.id}/roles`); },
        changeRole(role) { this.$router.push(`/projects/${this.project.id}/roles/${role.id}`); },
        changeProject(project) {
            this.$store.dispatch('GET_ROLES', project.id).then(roles => {
                this.role = {
                    id: roles[0].id,
                    name: roles[0].attributes.name
                };
                localStorage.setItem('project_id', project.id);
                this.$router.push(`/projects/${project.id}/roles/${roles[0].id}`);
            });
        }
    }
}
</script>

<style>
    .header {
        width: 100%;
        height: 71px;
        background: #252F44;
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
        display: flex;
        align-items: center;
        font-family: system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
        line-height: 1.5;
    }
    .header-content {
        width: 100%;
        display: flex;
        justify-content: space-around;
    }
    .logout {
        margin-right: 25px;
    }
    .nav-project a {
        text-decoration: none;
        margin: 30px;
        color: rgba(255, 255, 255, 0.7);
    }
    .nav-project a:hover {
        color: rgba(255, 255, 255, 0.9);
    }
    .nav-project a.router-link-exact-active {
        font-size: 16px;
        color:white;
        padding-bottom: 22px;
        border-bottom: 4px solid;
        border-image: linear-gradient(269.46deg, #EE6E17 1.55%, #FFCC48 49.94%, #EE6E17 98.73%);
        border-image-slice: 1;
    }
    .logout button {
        border: 1px solid white;
        background: transparent;
        color: rgba(255, 255, 255, 0.7);
        border-radius: 10px;
        padding: 6px 12px;
    }
    .logout button:hover {
        color: rgba(255, 255, 255, 0.9);
        border-color: rgba(255, 255, 255, 0.9);
    }
    .select-project {
        display: flex;
        align-items: center;
        color: rgba(255, 255, 255, 0.7);
    }
    .select-project-text {
        width: 153px;
    }
    .header-multiselect > .multiselect__tags,
    .casting-multiselect > .multiselect__tags {
        border: none;
        background: #252F44;
        width: 215px !important;
        padding-top: 10px;
        padding-left: 0px !important;
    }
    .header-multiselect > .multiselect__tags:hover,
    .casting-multiselect > .multiselect__tags:hover {
        cursor: pointer;
    }
    .header-multiselect > .multiselect__tags > .multiselect__single,
    .casting-multiselect > .multiselect__tags > .multiselect__single { 
        background: #252F44;
        padding: 2px !important;
    }
    .header-multiselect > .multiselect__content-wrapper {
        width: 345px !important;
        border: none;
        right: -30px;
    }
    .header-multiselect > .multiselect__select,
    .casting-multiselect > .multiselect__select {
        left: 160px !important;
        top: 1px !important; 
    }
    .header-multiselect,
    .casting-multiselect {
        width: 44% !important;
    }
    .select-casting-text {
        width: 45px;
    }
    .casting-multiselect > .multiselect__content-wrapper {
        width: 250px !important;
        border: none;
        right: 20px;
    }
    .casting-multiselect > .multiselect__content-wrapper > ul > li {
        width: 250px;
    }
    .header-multiselect > .multiselect__content-wrapper > ul > li {
        width: 345px;
    }
</style>