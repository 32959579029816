<template>
    <div class="project-info-container">
        <div class="btn-container">
            <div class="user-info">
                <img v-if="getMe.avatar" :src="getMe.avatar" alt="avatar" />
                <div v-else class="user-avatar"><span>{{ getMe.first_name[0] }}</span></div>
                <div class="btn-container-text">
                    <span>Created by</span><br />
                    {{getMe.first_name}} {{getMe.last_name}}
                </div>
            </div>
            <div class="btn-project container-border">
                <template v-if="getProject.status !== 2">
                    <button class="btn-action" @click="edit(getProject)">
                        <img src="@/assets/images/edit.png" alt="edit" />
                    </button>
                    <div class="btn-container-text">Edit <br />Film Info</div>
                </template>
            </div>
            <div class="btn-project">
                <button class="btn-action" v-b-modal.archive-project>
                    <img 
                        :src="getProject.status !== 2 ? require('@/assets/images/archive.png') : require('@/assets/images/unarchive.png')" 
                        :alt="getProject.status !== 2 ? 'archive' : 'unarchive'"
                    />
                </button>
                <div class="btn-container-text">{{getProject.status !== 2 ? 'Archive' : 'Unarchive'}}<br />Project </div>
            </div>
        </div>
        <div>
            <div class="wrapper-add-role">
                <template>
                    <button class="btn-action btn-add-role-project" @click="showModal = true">
                        <img src="@/assets/images/plus.png" alt="edit" width="15" height="15" />
                    </button>
                    <span class="text-add-role">Add a Role for casting</span>
                </template>
            </div>
        </div>
        <div class="project-info">
            <div class="project-img">
                <img :src="getProject.placeholder" />
            </div>
            <div class="project-text">
                <div>
                    <span>Project genre:</span><br />
                    {{getProject.genre && getProject.genre.name}}
                </div>
                <div style="margin: 18px 0px;">
                    <span>Project type:</span><br />
                    {{getProject.project_type && getProject.project_type.name}}
                </div>
                <div>
                    <span>Production company:</span><br />
                    {{getMe.company_name}}
                </div>
            </div>
        </div>
        <div class="project-descr">{{getProject.description}}</div>
        <CreateProject :is-edit="isEdit" :current-project="getProject" @undoEdit="unEdit" />
        <ArchiveProject />
        <role-creating-modal
            v-if="showModal"
            :project-id="$route.params.id"
            @hide="showModal = false"
        />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import project from '@/mixins/project';
import CreateProject from './components/CreateProjects.vue';
import ArchiveProject from './components/ArchiveProjects.vue';
import RoleCreatingModal from '@/components/popups/role-creating/index';

export default {
    components: {
        CreateProject,
        ArchiveProject,
        RoleCreatingModal
    },
    data() {
        return {
            showModal: false
        }
    },
    mixins: [project],
    computed: {
		...mapGetters(['getMe', 'getProject'])
	}
}
</script>

<style>
    .btn-container {
        width: 342px;
        height: 77px;
        display: flex;
    }
    .user-avatar {
        background-color: #00B683;
        border-radius: 50%;
        width: 38px;
        height: 38px;
        text-align: center;
        line-height: 38px;
    }
    .user-avatar span {
        color: white;
        height: 38px;
        width: 38px;
        font-size: 20px;
    }
    .user-info {
        width: 142px;
    }
    .btn-container-text {
        margin-top: 7px;
        font-size: 14px;
        color: white;
        line-height: 1.2;
    }
    .btn-container-text span {
        color: rgba(255, 255, 255, 0.5);
    }
    .project-info {
        margin-top: 20px;
        width: 336px;
        height: 305px;
        border-top: 1px solid rgba(255, 255, 255, 0.05);
        border-bottom: 1px solid rgba(255, 255, 255, 0.05);
        padding: 40px 0px;
        display: flex;
    }
    .project-img, .project-img img {
		width: 146px;
        height: 220px;
		border-radius: 9px;
		object-fit: cover;
	}
    .project-text {
        margin: 17px 0px 0 30px;
        width: 160px;
        font-size: 16px;
        color: white;
    }
    .project-text span {
        color: rgba(255, 255, 255, 0.5);
        font-style: italic;
    }
    .project-descr {
        overflow-y: auto;
        max-height: calc(100vh - 615px);
        margin: 20px 0;
        color: white;
        width: 340px;
    }
    .user-info img {
        height: 38px;
        width: 38px;
        border-radius: 50%;
        object-fit: cover;
    }
    .wrapper-add-role {
        padding-top: 20px;
    }
    .btn-add-role-project {
        height: 40px !important;
        width: 40px !important;
        margin: 0 !important;
    }
    .text-add-role {
        color: white;
        padding-left: 10px;
    }
</style>