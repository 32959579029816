import Vue from 'vue';
import VueRouter from 'vue-router';
import LogIn from '../view/auth/LogIn.vue';
import SignUp from '../view/auth/SignUp.vue';
import ForgotPassword from '../view/auth/ForgotPassword.vue';
import UserProjects from '../view/dashboard/UserProjects.vue';
import ProjectRoles from '../view/dashboard/ProjectRoles.vue';
import PublicCastingDashboard from '../view/dashboard/PublicCastingDashboard.vue';
import ActorPortfolio from '../view/dashboard/ActorPortfolio.vue';

import middlewarePipeline from '../middleware/middlewarePipeline';
import auth from '../middleware/auth';
import store from '../store/index';

Vue.use(VueRouter);

const routes = [
    { path: '/', name: 'logIn', component: LogIn, meta: { layout: 'auth-layout' } },
    { path: '/signup', name: 'singUp', component: SignUp, meta: { layout: 'auth-layout' } },
    { path: '/forgot/password', name: 'forgotPassword', component: ForgotPassword, meta: { layout:'auth-layout' }},
    { path: '/active/projects', name: 'activeProject', component: UserProjects, meta: { layout: 'dashboard-layout', middleware: [auth] } },
    { path: '/archived/projects', name: 'archivedProject', component: UserProjects, meta: { layout: 'dashboard-layout', middleware: [auth] } },
    { path: '/projects/:id/roles', name: 'projectRoles', component: ProjectRoles, meta: { layout: 'dashboard-layout', middleware: [auth] } },
    { path: '/projects/:project_id/roles/:role_id', name: 'publicCastingDashboard', component: PublicCastingDashboard, meta: { layout: 'dashboard-layout', middleware: [auth] } },
    { path: '/role/:roleId/application/:applicationId', name: 'actorPortfolio', component: ActorPortfolio, meta: { layout: 'dashboard-layout', middleware: [auth] } },
]

const router = new VueRouter({
    mode: 'history',
    routes 
})

router.beforeEach((to, from, next) => {
    if (!to.meta.middleware) {
        return next()
    }
    const middleware = to.meta.middleware;
    const context = {
        to,
        from,
        next,
        store
    };

    return middleware[0]({
        ...context,
        next: middlewarePipeline(context, middleware, 1)
    })
});

export default router