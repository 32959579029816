import axiosIns from '@/libs/axios'

export default {
    getProjects(status) {
        return axiosIns.get(`/projects?filterByStatus=${status}`)
    },
    createProject(data) {
        return axiosIns.post('/projects', data)
    },
    getProject(id) {
        return axiosIns.get(`/projects/${id}`)
    },
    updateProject(data, id) {
        return axiosIns.put(`/projects/${id}`, data)
    },
    deleteProject(id) {
        return axiosIns.delete(`/projects/${id}`)
    },
    archiveProject(id) {
        return axiosIns.post(`/projects/${id}/archive`)
    },
    unArchiveProject(id) {
        return axiosIns.post(`/projects/${id}/restore`)
    },
    getActiveProjectsAndRoles() {
        return axiosIns.get('/projects/active-projects-and-roles')
    }
}