import axios from 'axios'
import Cookies from 'js-cookie'
import { Notification } from 'element-ui'

class AxiosResolver {
  axiosIns = null

  jwtConfig = {
    baseURL: process.env.VUE_APP_ENDPOINT,
  }

  isAlreadyFetchingAccessToken = false

  subscribers = []

  request = []

  constructor(axiosIns) {

    this.axiosIns = axiosIns.create(this.jwtConfig)

    this.axiosIns.interceptors.request.use(request => {

      var ajaxCalls = [];
      const origOpen = XMLHttpRequest.prototype.open
      XMLHttpRequest.prototype.open = function(method, url) {
        ajaxCalls.push({method: method, url: url})
        origOpen.apply(this, arguments)
      };
      setTimeout(() => { this.request = ajaxCalls })

      this.showLoader()

      const locale = Cookies.get('locale')

      if (locale) {
        /* eslint-disable no-param-reassign */
        request.headers.common['X-localization'] = locale
      }

      request.headers.common.Accept = 'application/json'
      request.headers.common.ContentType = 'application/json'

      if (this.getAccessToken() && ! request.headers.common.Authorization) {
        /* eslint-disable no-param-reassign */
        request.headers.common.Authorization = `Bearer ${this.getAccessToken()}`
      }

      return request
    }, error => {
      this.hideLoader()
      return Promise.reject(error)
    })

    this.axiosIns.interceptors.response.use(
      successRes => {
        if (this.request) { this.showLoader() }
        this.request = this.request.filter(item => item.url !== successRes.request.responseURL)
        if (this.request.length === 0) { this.request.push({url: false}) }
        if (this.request.length === 1) { this.hideLoader() }

        return successRes
      },
      error => {
        const { config, response } = error
        const originalRequest = config

        this.hideLoader()

        if (response && response.status === 401 && !!this.getAccessToken() && !!this.getRefreshToken()) {
          if (this.isRefreshAccessTokenEndpoint(response.config.url)) {
            this.logout()

            return Promise.reject(response)
          }
          
          if (! this.isAlreadyFetchingAccessToken) {
            this.isAlreadyFetchingAccessToken = true

            this.refreshToken().then(r => {
              this.isAlreadyFetchingAccessToken = false

              this.setAccessToken(r.data[0].access_token)
              this.setRefreshToken(r.data[0].refresh_token)
              this.onAccessTokenFetched(r.data[0].access_token)
            })
          }

          return new Promise(resolve => {
            this.addSubscriber(accessToken => {
              originalRequest.headers.Authorization = `Bearer ${accessToken}`
              resolve(this.axiosIns(originalRequest))
            })
          })
        }else {
          Notification({
            title: 'Error',
            message: error.response.data?.message ? error.response.data.message : error.message,
            type: 'error',
            duration: 5000
          })
        }

        return Promise.reject(error)
      },
    )
  }

  getInstance() {
    return this.axiosIns
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter(callback => callback(accessToken))
  }

  addSubscriber(callback) {
    this.subscribers.push(callback)
  }

  getAccessToken() {
    return Cookies.get('access_token')
  }

  getRefreshToken() {
    return Cookies.get('refresh_token')
  }

  setAccessToken(value) {
    Cookies.set('access_token', value)
  }

  setRefreshToken(value) {
    Cookies.set('refresh_token', value)
  }

  removeAccessRefreshTokens() {
    Cookies.remove('access_token')
    Cookies.remove('refresh_token')
  }

  isRefreshAccessTokenEndpoint(path) {
    return path === '/user/refresh-access-token'
  }

  refreshToken() {
    return this.getInstance().post('/user/refresh-access-token', {
      remember_me: (Cookies.get('remember_me') === 'true')
    }, {
      headers: {
        'Authorization': `Bearer ${this.getRefreshToken()}`
      }
    })
  }

  logout() {
    this.isAlreadyFetchingAccessToken = false

    this.removeAccessRefreshTokens()

    EventBus.$emit('logout')
  }

  // eslint-disable-next-line class-methods-use-this
  showLoader() {
    EventBus.$emit('showLoader')
  }

  // eslint-disable-next-line class-methods-use-this
  hideLoader() {
    EventBus.$emit('hideLoader')
  }
}

const axiosIns = new AxiosResolver(axios).getInstance()

export default axiosIns
