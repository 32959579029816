<template>
	<div class="projects">
		<div :class="[!status && !getProjects.length ? 'no-projects-container' : 'projects-container']">
			<div :class="[!status && !getProjects.length ? '' : 'all-projects']">
				<div v-for="(project, index) in getProjects" :key="project.id" class="project">
					<div class="film-container" @click="projectDetails(project)">
						<div class="film-img">
							<img :src="project.attributes.placeholder" />
						</div>
						<div class="film-info">
							<div class="genre-icon-container">
								<img :src="project.attributes.genre.icon" class="genre-icon" />
							</div>
							<div style="height: 135px; width: 260px;">
								<div class="film-info-name">{{project.attributes.name}}</div>
								<div class="film-info-other">Term: {{project.attributes.start_date}} - {{project.attributes.deadline}}</div>
								<div class="film-info-other">Company: {{getMe.company_name}}</div>
							</div>
							<div class="film-info-other">{{project.attributes.genre.name}} | {{project.attributes.project_type.name}}</div>
						</div>
					</div>
					<div class="film-action">
						<div class="avatar">
							<img v-if="getMe.avatar" :src="getMe.avatar" alt="avatar" />
							<div v-else class="user-avatar">
								<span style="height: 36px; width: 36px">
									{{ getMe.first_name[0] }}
								</span>
							</div>
						</div>
						<div :style="status ? 'width: 213px;' : 'width: 255px;'">by {{getMe.first_name}} {{getMe.last_name}}</div>
						<!-- <button v-if="status" class="btn-action">
							<img src="@/assets/images/users.png" alt="users" />
						</button> -->
						<div @mouseover="removeHoverHint(index)" @mouseout="addHoverHint(index)">
							<button 
								v-if="status" 
								class="btn-action edit-project"
								@click="edit(project)"
							>
								<img src="@/assets/images/edit.png" alt="edit" />
							</button>
							<div class="hint-edit-project">Edit project</div>
							<button 
								:class="['btn-action', status ? 'archive-project' : 'unarchive-project']" 
								@click="archive(project.id)" 
							>
								<img 
									:src="status ? require('@/assets/images/archive.png') : require('@/assets/images/unarchive.png')" 
									:style="status ? '' : 'margin: 3px -2px;'"
									:alt="status ? 'archive' : 'unarchive'"
								/>
							</button>
							<div :class="[status ? 'hint-archive-project' : 'hint-unarchive-project']">{{status ? 'Archive project' : 'Move to active projects'}}</div>
							<button class="btn-action delete-project" v-b-modal.delete-project @click="projectId = project.id">
								<img src="@/assets/images/remove.png" alt="remove" />
							</button>
							<div class="hint-delete-project">Delete project</div>
						</div>
					</div>
					<div class="hint">
						<div>open casting roles list</div>
					</div>
				</div>
				<!-- <div v-if="status" class="create-project">
					<button v-b-modal.create-project><img src="@/assets/images/plus.png" alt="plus" /></button>
					<div>Create New Projects</div>
				</div> -->
			</div>
			<!-- <CreateProject :is-edit="isEdit" :current-project="currentProject" @undoEdit="unEdit"/> -->
			<DeleteProject :project-id="projectId" />
			<div v-if="!status && !getProjects.length" class="no-archived-projects">No Projects yet</div>
		</div>
	</div>
</template>

<script>
// import CreateProject from './components/CreateProjects.vue';
import { mapGetters, mapActions } from 'vuex';
import project from '@/mixins/project';
import DeleteProject from './components/DeleteProjects.vue';


export default {
	mixins: [project],
	components: {
		// CreateProject,
		DeleteProject
	},
	data() {
		return {
			projectId: null,
			status: null
		}
	},
	watch: {
		$route: {
			handler(route) {
				this.status = route.name === 'activeProject';

				if (this.status) this.GET_PROJECTS(1)
				else this.GET_PROJECTS(2)
			},
			immediate: true
		}
	},
	computed: {
		...mapGetters(['getMe', 'getProjects'])
	},
	methods: {
		...mapActions(['GET_PROJECTS']),
		removeHoverHint(index) { this.hint('none', index); },
		addHoverHint(index) { this.hint('', index); },
		hint(v, index) {
			Array.from(document.getElementsByClassName("hint")).forEach((item, i) => {
				if (index === i) item.style.display = v;
			});
		},
		projectDetails(project) {
			this.$router.push(`/projects/${project.id}/roles`);
		}
	}
}
</script>

<style>
	/* .create-project {
		width: 400px;
		height: 217px;
		background: linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0.15) 100%);
		border: 1px dashed #FFFFFF;
		border-radius: 24px;
		text-align: center;
		padding-top: 70px;
	}
	.create-project button {
		width: 55px;
		height: 55px;
		background: rgba(255, 255, 255, 0.15);
		border: 1px solid #FFFFFF;
		border-radius: 50%;
	}
	.create-project div {
		color: #FFFFFF;
		font-size: 16px;
		margin-top: 10px;
	}
	.create-project button:hover {
		background: rgba(133, 126, 126, 0.15);
	} */
	.archived-projects {
		color: white;
	}
	.all-projects {
        display: grid;
        grid-template-columns: repeat(auto-fill,minmax(420px, 1fr));
    }
	.project {
		height: 217px;
		width: 400px;
		border: 0.5px solid rgba(255, 255, 255, 0.2);
		border-radius: 24px;
		margin-bottom: 55px;
		background: rgba(255, 255, 255, 0.07);
		color: white;
		font-family: 'Jost';
	}
	.film-container {
		display: flex;
		width: 397px;
		height: 172px;
		border: 3px solid #2F3C56;
		border-radius: 24px;
		background: #252F44;
	}
	.film-img, .film-img img {
		width: 110px;
		height: 166px;
		border-radius: 24px 0px 0px 24px;
		object-fit: cover;
	}
	.film-action {
		display: flex;
		align-items: center;
		height: 42px;
		padding-left: 10px;
		position: relative;
	}
	.film-info {
		padding: 2px 10px;
	}
	.film-info-name {
		font-size: 22px;
		width: 245px;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		line-height: 1.1;
		margin-bottom: 8px;
	}
	.film-info-other {
		font-size: 16px;
	}
	.avatar {
		margin-right: 10px;
	}
	.avatar img, .avatar div{
		height: 36px;
		width: 36px;
		border-radius: 50%;
		object-fit: cover;
	}
	.btn-action {
		height: 32px;
		width: 32px;
		border-radius: 50%;
		background: transparent;
		border: 1px solid rgba(255, 255, 255, 0.3);
		margin: 0 5px;
	}
	.btn-action img {
		margin-bottom: 4px;
	}
	.btn-action:hover {
		border-color: rgba(255, 255, 255, 0.9);
	}
	.hint {
		display: none;
		margin-top: 16px;
		position: relative;
	}
	.hint:before, 
	.hint-edit-project:before, 
	.hint-archive-project:before, 
	.hint-unarchive-project:before, 
	.hint-delete-project:before  {
		position: absolute;
		bottom: 30px;
		transform: rotate(360deg);
		content: '';
		border-style: solid;
		border-width: 0 16px 12px 16px;
		border-color: transparent transparent rgba(255, 255, 255, 0.07) transparent;
	}
	.hint-edit-project:before {
		bottom: 30px;
		left: 39px;
	}
	.hint-unarchive-project:before {
		left: 85px;
	}
	.hint-archive-project:before {
		left: 57px;
	}
	.hint-delete-project:before {
		left: 49px;
	}
	.project:hover .hint {
		display: flex;
		justify-content: center;
	}
	.project:hover {
		cursor: pointer;
	}
	.hint div, 
	.hint-edit-project, 
	.hint-archive-project, 
	.hint-unarchive-project,
	.hint-delete-project {
		width: 175px;
		height: 30px;
		background: rgba(255, 255, 255, 0.07);
		text-align: center;
		border-radius: 24px;
		line-height: 30px;
	}
	.hint-edit-project, 
	.hint-archive-project, 
	.hint-unarchive-project,
	.hint-delete-project  {
		position: absolute;
		top: 57px;
		left: 233px;
		width: 115px;
		display: none;
	}
	.hint-archive-project {
		left: 258px;
		width: 145px;
	}
	.hint-unarchive-project {
		left: 230px;
		width: 200px;
	}
	.hint-delete-project {
		left: 307px;
		width: 127px;
	}
	.edit-project:hover ~ .hint-edit-project, 
	.archive-project:hover ~ .hint-archive-project,
	.unarchive-project:hover ~ .hint-unarchive-project,
	.delete-project:hover ~ .hint-delete-project {
		display: block
	}
	.no-archived-projects {
		font-weight: 600;
		font-size: 40px;
		line-height: 49px;
		color: #FFFFFF;
		opacity: 0.2;
	}
	.no-projects-container {
		display: flex;
		justify-content: center;
		align-items: center;
		height: calc(100vh - 87px);
	}
	.genre-icon-container {
		text-align: end;
		position: relative;
	}
	.genre-icon {
		position: absolute;
		margin: -17px;
		height: 45px;
		filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(224deg) brightness(102%) contrast(101%);
	}
</style>