<template>
	<div>
		<HeaderComponent :project-roles="isProjectRoles" :public-casting-dashboard="isPublicCastingDashboard" :is-actor-portfolio="isActorPortfolio" />
		<div class="dashboard">
			<div :class="['dashboard-container', {dc: !show}, {'dashboard-container-details': isProjectRoles || isPublicCastingDashboard || isActorPortfolio}]">
				<router-view />
			</div>
			<div :class="['aside-container', {'aside-hide': !show}, {'aside-container-details': isProjectRoles || isPublicCastingDashboard || isActorPortfolio}]">
				<UserDetails v-if="isProjects"/>
				<ProjectDetails v-if="isProjectRoles" />
				<CastingDetails v-if="isPublicCastingDashboard || isActorPortfolio" />
			</div>
			<div :class="['aside-btn', {'aside-close': !show}, {'aside-btn-details': isProjectRoles || isPublicCastingDashboard || isActorPortfolio}]" @click="aside">
				<img src="@/assets/images/arrows.png" alt="arrows" />
			</div>
		</div>
		<SpinnerEpic />
    </div>
</template>

<script>
import HeaderComponent from '../components/HeaderComponent.vue';
import SpinnerEpic from '../components/SpinnerEpic.vue';
import UserDetails from '../components/UserDetails.vue';
import ProjectDetails from '../view/dashboard/ProjectDetails.vue';
import CastingDetails from '../view/dashboard/CastingDetails.vue';
import userNotification from '@/api/userNotification';


export default {
	components: {
		HeaderComponent,
		SpinnerEpic,
		UserDetails,
		ProjectDetails,
		CastingDetails
	},
	data() {
		return {
			counter: 0,
			show: true,
			active: false,
			isProjectRoles: null,
			isPublicCastingDashboard: null,
			isActorPortfolio: null,
			isProjects: null
		}
	},
	watch: {
		$route: {
			handler(route) { 
				this.isProjects = route.name === 'activeProject' || route.name === 'archivedProject'; 
				this.isProjectRoles = route.name === 'projectRoles'; 
				this.isPublicCastingDashboard = route.name === 'publicCastingDashboard';
				this.isActorPortfolio = route.name === 'actorPortfolio';

				if (this.isProjects ) {
					userNotification.getStatusAboutNewNotifications().then(res => {
						if (res.data.new_notification) this.$store.dispatch('GET_NOTIFICATIONS');
					})
				}

				if (this.isProjects || this.isProjectRoles) {
					localStorage.setItem('tab', 1);
				}

				if (this.isProjectRoles || this.isPublicCastingDashboard || this.isActorPortfolio) {
					if (this.isProjectRoles) {
						localStorage.setItem('project_id', this.$route.params.id);
					}
					if (this.isPublicCastingDashboard) {
						localStorage.setItem('role_id', this.$route.params.role_id);
						this.$store.dispatch('GET_ROLE_APPLICATIONS', this.$route.params.role_id);
					}
					if (this.isPublicCastingDashboard || this.isActorPortfolio) {
						this.$store.dispatch('GET_ROLE', { project_id: localStorage.getItem('project_id'), role_id: localStorage.getItem('role_id') });
					}
					if (this.isActorPortfolio) {
						this.$store.dispatch('GET_APPLICATION', { role_id: this.$route.params.roleId, application_id: this.$route.params.applicationId });
					}

					this.$store.dispatch('GET_PROJECTS', 1);
					this.$store.dispatch('GET_PROJECT', localStorage.getItem('project_id'));
					this.$store.dispatch('GET_ROLES', localStorage.getItem('project_id'));
				}
			},
			immediate: true
		}
	},
	methods: {
		aside() {
			this.counter += 1; 
			if (this.counter % 2 !== 0) this.show = false;
			else this.show = true;
		}
	}
}
</script>

<style>
	.dashboard {
		position: relative;
		background-image: url("@/assets/images/background.png");
		line-height: initial;
	}
	.dashboard-container {
		position: relative;
		z-index: 1;
		border-left: 1px solid rgba(255, 255, 255, 0.2);
		min-height: calc(100vh - 71px);
		margin-left: 280px;
	}
	.aside-container {
		position: absolute;
		z-index: 2;
		background: #252F44;
		width: 280px;
		top: 0;
		min-height: calc(100vh - 71px);
		padding: 45px 20px 10px 32px;
		font-family: 'Jost';
		font-style: normal;
	}
	.aside-btn {
		position: absolute;
		z-index: 3;
		background: #252F44;
		border: 1px solid rgba(255, 255, 255, 0.2);
		width: 20px;
		height: 56px;
		left: 260px;
		top: 50%;
		cursor: pointer;
		border-radius: 20px 0px 0px 20px;
		border-right-style: none;
	}
	.aside-hide {
		display: none;
	}
	.aside-close {
		left: 0 !important;
		border-radius: 0px 20px 20px 0px;
		border-right-style: solid;
	}
	.dc {
		margin-left: 0 !important;
	}
	.aside-btn img {
		margin: 22px 0 0 5px
	}
	.aside-close img {
		transform: rotate(180deg);
		margin-left: 2px;
	}
	.aside-container-details {
		width: 400px;
		padding-top: 32px;
	}
	.dashboard-container-details {
		margin-left: 400px;
	}
	.aside-btn-details {
		left: 380px;
	}
</style>