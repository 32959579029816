<template>
    <div class="roles-container-list">
        <roles-item
            v-for="(role, index) in roles" :key="index"
            :role="role"
        />
    </div>
    
</template>

<script>
import RolesItem from '@/components/roles/roles-item'
import { mapGetters } from 'vuex'

export default {
    name: 'RolesList',
    components: {
        RolesItem
    },
    data() {
        return {
            getImagePlus: require('@/assets/images/plus.png')
        }
    },
    computed: {
        ...mapGetters({ roles: 'getRoles' })
    }
}
</script>