import axiosIns from '@/libs/axios'

export default {
    getSelfTapeMaterials(application_selftape_id) {
        return axiosIns.get(`/application-selftape-materials/${application_selftape_id}/application-selftape`)
    },
    deleteSelfTapeMaterial(application_selftape_material_id) {
        return axiosIns.delete(`/application-selftape-materials/${application_selftape_material_id}`)
    },
    updateSelfTapeMaterial(application_selftape_id, data) {
        return axiosIns.post(`/application-selftape-materials/${application_selftape_id}/update`, data)
    },
    createSelfTapeMaterial(application_selftape_id, data) {
        return axiosIns.post(`/application-selftape-materials/${application_selftape_id}/audition`, data)
    }
}