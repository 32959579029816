<template>
    <label :class="['e-checkbox', 'e-checkbox_size_' + size]">
        <input type="checkbox" v-model="propStatus">
        <span :class="['switch', 'switch_size_' + size, 'switch_color_' + switchColor]"></span>
    </label>
</template>

<script>

export default {
    name: 'e-checkbox',
    props: {
        switchColor: {
            type: String,
            default: 'white'
        },
        size: {
            type: String,
            default: 'm'
        },
        status: {
            type: Boolean,
            required: true
        },
    },
    model: {
        prop: 'status',
        event: 'changeStatus'
    },
    computed: {
        propStatus: {
            get() { return this.status },
            set(value) { this.$emit('changeStatus', value) }
        }
    }
};
</script>
