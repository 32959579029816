<template>
    <div class="roles">
        <div class="roles-container">
            <RolesList />
        </div>
    </div>
</template>

<script>
import RolesList from '@/components/roles/roles-list'

export default {
    components: {
        RolesList
    }
}
</script>

<style lang="scss">
    @import '@/assets/styles/pages/roles-list.sass';
</style>
