<template>
    <div class="container-spinner" v-if="isSpinner" id="spinner">
        <OrbitSpinner
            :animation-duration="1500"
            :size="80"
            color="#ee6e17"
		/>
    </div>
</template>

<script>
import { OrbitSpinner } from 'epic-spinners'

export default {
	components:{
        OrbitSpinner
	},  
	computed:{
		isSpinner () { return this.$store.getters.isSpinner; }	      
	},
	beforeMount() {
		EventBus.$on('showLoader', () => { this.$store.commit("SET_IS_SPINNER", true) })
		EventBus.$on('hideLoader', () => { this.$store.commit("SET_IS_SPINNER", false) })
	},
}
</script>

<style>
	.container-spinner {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100vh;
		background-color: rgba(0,0,0,.7);
		display: flex;
		justify-content: center;
		align-items: center;
		z-index: 1500;
	}
</style>