<template>
    <div>
        <div 
            v-for="notification in notifications" 
            :key="notification.id" 
            :class="['notification-wrapper', { attention: notification.attributes.title === 'Attention!' }]"
            @click="readNotifications(notification.id)"
        >
            <div class="notification-placeholder">
                <img :src="notification.attributes.placeholder" alt="placeholder" />
            </div>
            <div class="notification-info">
                <div :class="['notification-status', { 'attention-status': notification.attributes.title === 'Attention!' }]">{{notification.attributes.status_value}}</div>
                <div class="notification-title">{{notification.attributes.title}}</div>
                <div class="notification-body">{{notification.attributes.body}}</div>
            </div>
        </div>
    </div>
</template>

<script>
import userNotification from '@/api/userNotification';

export default {
    props: {
        notifications: {
            default: () => [],
            type: Array
        }
    },
    methods: {
        readNotifications(id) {
            userNotification.readNotification(id).then(() => {
                this.$store.dispatch('GET_NOTIFICATIONS');
            })
        }
    }
};
  </script>

  <style>
    .notification-wrapper {
        display: flex;
        background: #2C374C;
        border-radius: 20px;
        margin-bottom: 25px;
        border: 0.5px solid #00B682;
        cursor: pointer;
    }
    .attention {
        border: 0.5px solid #ED5D6D;
    }
    .attention-status {
        color: #ED5D6D !important;
    }
    .notification-placeholder {
        height: 120px;
        padding: 3px;
    }
    .notification-placeholder img {
        height: 115px;
        border-radius: 15px;
    }
    .notification-info {
        width: 100%;
        margin: 2px 10px 10px;
    }
    .notification-status {
        font-size: 17px;
        text-align: end;
        font-style: italic;
        color: #00B682;
    }
    .notification-title, .notification-body {
        text-align: left;
    }
    .notification-title {
        font-size: 18px;
    }
    .notification-body {
        font-size: 17px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
  </style>