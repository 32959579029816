<template>
    <div class="audition-invitation">
        <b-modal
            v-if="isMaterialType"
            id="audition-invitation-modal"
            ref="ref-modal"
            centered
            v-model="isShowModal"
            hide-footer
            hide-header
            @hide="$emit('hide')"
            size="huge"
        >
            <div class="audition-invitation-body">
                <!-- Close icon in the upper right corner -->
                <div class="audition-invitation-close">
                    <span class="audition-invitation-close__icon">
                        <img
                            @click="isShowModal = false"
                            :src="getImageClose"
                            alt="Close audition invitation"
                            height="20"
                        />
                    </span>
                </div>

                <!-- Title -->
                <div class="audition-invitation-title">
                    <span>Audition Invitation</span>
                </div>

                <!-- Header line -->
                <div class="audition-invitation-header-line">
                    <img :src="getImageHeaderLine" alt="Header line">
                </div>

                <!-- Form -->
                <div class="audition-invitation-form">
                    <!-- Checkbox -->
                    <div class="audition-invitation-form__checker">
                        <span
                            :class="[{ disabled: form.status }, 'public-casting-label']"
                        >
                            Offline
                        </span>
                        <e-checkbox
                            switch-color="orange"
                            v-model="form.status"
                        />
                        <span
                            :class="[{ disabled: !form.status }, 'private-casting-label']"
                        >
                            Online
                        </span>
                    </div>

                    <!-- Form | location, meeting-link -->
                    <div class="audition-invitation-form__meeting-link" v-if="form.status">
                        <input 
                            type="text"
                            placeholder="Meeting link (zoom, google meet, skype, etc)"
                            v-model="form.meetingLink"
                        />
                        <img :src="getImageMeetingLink" alt="Meeting link" />
                    </div>
                    <div class="audition-invitation-form__location" v-else>
                        <input
                            type="text"
                            placeholder="Audition location (Country, City, Address)"
                            v-model="form.location"
                        />
                        <img :src="getImageLocation" alt="location" />
                    </div>

                    <!-- Line -->
                    <div class="audition-invitation-form__line">
                        <img :src="getImageLine" alt="line" />
                    </div>

                    <!-- Upload materials -->
                    <div class="upload-materials">
                        <div
                            class="upload-materials__list"
                            v-for="(item, index) in uploadMaterials"
                            :key="index"
                        >
                            <template v-if="!item.name">
                                <Multiselect
                                    :options="materialTypesOptions"
                                    v-model="materialType"
                                    :searchable="false"
                                    :close-on-select="true"
                                    :show-labels="false"
                                    :allow-empty="false"
                                    placeholder="Choose material type"
                                    label="name"
                                />
                                <div class="upload-file-section">
                                    <span class="upload-file-section__title">
                                        Upload preparation material
                                    </span>
                                    <label :for="'file-input_' + index">
                                        <img
                                            :class="[{ disabled: !materialType.name }, 'upload-file-section__icon']"
                                            :src="getImageUpload"
                                            alt="upload"
                                        />
                                    </label>
                                    <input
                                        v-if="materialType.name"
                                        class="file-input"
                                        :id="'file-input_' + index"
                                        type="file"
                                        @change="previewFiles"
                                    />
                                </div>
                            </template>
                            <template v-else>
                                <div class="upload-materials__uploaded-item">
                                    <span>{{ item.name }}</span>
                                    <div class="upload-materials-actions">
                                        <label :for="'file-edit_' + index">
                                            <img
                                                :src="getImageEdit"
                                                alt="edit"
                                            />
                                        </label>
                                        <input class="file-input" :id="'file-edit_' + index" type="file" @change="e => editMaterialFile(e, item.id, index)"/>

                                        <img
                                            :src="getImageVerticalLine"
                                            class="upload-materials-actions__vertical-line"
                                            alt="vertical-line"
                                        />
                                        <img
                                            @click="deleteMaterialFile(item.id, index)"
                                            :src="getImageDelete"
                                            alt="delete"
                                        />
                                    </div>
                                </div>
                            </template>
                        </div>
                        <div class="upload-materials-button" @click="addUploadMaterial">
                            <span class="upload-materials-button__title">
                                Upload more materials
                            </span>
                            <img :src="getImagePlus" alt="plus" />
                        </div>
                    </div>

                    <!-- Line -->
                    <div class="audition-invitation-form__line">
                        <img :src="getImageLine" alt="line" />
                    </div>

                    <!-- Date -->
                    <div class="audition-invitation-form__date">
                        <div class="e-date-picker">
                            <e-date-picker
                                placeholder="Audition Date"
                                type="single"
                                v-model="form.auditionDate"
                            >
                            </e-date-picker>
                        </div>
                        <div class="audition-invitation-form__placeholder-width"></div>
                        <!-- Time -->
                        <div class="audition-invitation-date-time">
                            <b-form-timepicker
                                v-model="form.auditionTime"
                                locale="en"
                                placeholder="Audition time"
                            ></b-form-timepicker>
                        </div>
                    </div>
                </div>

                <!-- Footer line -->
                <div class="audition-invitation-footer-line">
                    <img :src="getImageFooterLine" alt="Footer line">
                </div>

                <!-- Submit -->
                <div class="audition-invitation-submit">
                    <button @click="submit" :class="{'active': isFormFilled}">
                        {{ this.audition === null ? 'Request Audition' : 'Save changes' }}
                    </button>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
import 'vue2-datepicker/index.css';
import notify from '@/mixins/notify';
import eCheckbox from "@/elements/inputs/e-checkbox";
import Multiselect from 'vue-multiselect';
import eDatePicker from "@/elements/e-date-picker";
import { isEmpty } from 'lodash';
import date from '@/mixins/date';
import moment from 'moment';
import other from '@/api/other';
import audition from '@/api/audition';
import auditionMaterial from '@/api/auditionMaterial';

export default {
    name: 'AuditionInvitation',
    mixins: [date, notify],
    components: {
        eCheckbox,
        Multiselect,
        eDatePicker
    },
    props: {
        audition: {
            type: Object,
            default: () => null
        }
    },
    data() {
        return {
            getImageClose: require('@/assets/images/close.png'),
            getImageHeaderLine: require('@/assets/images/header-line-role.svg'),
            getImageFooterLine: require('@/assets/images/footer-line-role.svg'),
            getImageLocation: require('@/assets/images/location-audition.svg'),
            getImageMeetingLink: require('@/assets/images/meeting-link.svg'),
            getImageLine: require('@/assets/images/line.svg'),
            getImageUpload: require('@/assets/images/upload.png'),
            getImageEdit: require('@/assets/images/edit.svg'),
            getImageVerticalLine: require('@/assets/images/vertical-line.svg'),
            getImageDelete: require('@/assets/images/delete.svg'),
            getImagePlus: require('@/assets/images/edit.svg'),
            isShowModal: true,
            form: {
                status: true,
                location: '',
                meetingLink: '',
                auditionDate: [],
                auditionTime: ''
            },
            uploadMaterials: [{}],

            materialTypesOptions: [],
            materialType: [],
            materialTypes: [],
            removeMaterialId: [],
            editMaterialId: [],
            isMaterialType: false,
            isUploadMaterials: false
        }
    },
    methods: {
        async getMaterialType() {
            try {
                let res = await other.getMaterialTypes()
                this.materialTypesOptions = res.data
                this.isMaterialType = true;
            } catch (err) {
                return err
            }
        },
        addUploadMaterial() {
            if (
                this.uploadMaterials.some(i => i.name === undefined) ||
                isEmpty(this.materialType)
            ) {
                return false
            }

            this.materialType = []
            this.uploadMaterials.push({})
        },
        previewFiles(event) {
            try {
                this.isUploadMaterials = true
                const file = event.target.files[0]

                let lastIndex = Object.keys(this.uploadMaterials)[this.uploadMaterials.length - 1]
                this.uploadMaterials.splice(lastIndex, 1, file)

                this.materialTypes.push(this.materialType)
            } catch (error) {
                return error
            }
        },
        async deleteMaterialFile(id, index) {
            if (id) this.removeMaterialId.push(id)

            this.uploadMaterials.splice(index, 1)
            this.materialTypes.splice(index, 1)

            if (this.uploadMaterials.length === 0) {
                this.materialType = []
                this.uploadMaterials.push({})
            } 
        },
        async editMaterialFile(event, id, index) {
            const file = event.target.files[0]

            if (id) {
                this.uploadMaterials.map((el, i) => {
                    if (i === index) el.name = file.name
                })
                this.editMaterialId.push({id: id, name: file.name});
            }

            this.uploadMaterials.splice(index, 1, file)
        },
        async submit() {
            const formData = new FormData();

            if (this.form.status) { // online
                if (!this.form.meetingLink.length) return this.notifyWarning('Meeting Link can`t be empty!')
                formData.append('address', this.form.meetingLink)

                formData.append('type', 1)
            } else {
                if (!this.form.location.length) return this.notifyWarning('Location can`t be empty!')
                formData.append('address', this.form.location)

                formData.append('type', 0)
            }

            if (!this.form.auditionDate.length) {
                return this.notifyWarning('Audition Date can`t be empty!')
            }
            formData.append('audition_date', this.format(this.form.auditionDate[0]))

            if (!this.form.auditionTime.length) {
                return this.notifyWarning('Audition time can`t be empty!')
            }
            
            formData.append('audition_time', moment(this.form.auditionTime, 'hh:mm').format("HH:mm"))

            if (this.audition === null) {
                this.materialTypes.forEach(item => {
                    formData.append('materials[][material_type_id]', item.id)
                })
                this.uploadMaterials.forEach(item => {
                    if (item.name) {
                        formData.append('materials[][attachment]', item)
                    }
                })

                let res = await audition.createAudition(this.$route.params.applicationId, formData)
                if (res.status === 200) {
                    this.notifySuccess('Audition created!')
                    this.isShowModal = false
                }
            } else {
                formData.append('_method', 'PUT')

                let res = await audition.updateAudition(this.$route.params.applicationId, formData)
                if (res.status === 200) {
                    if (this.removeMaterialId.length) {
                        this.removeMaterialId.forEach(id => { auditionMaterial.deleteAuditionMaterial(id) })
                    }

                    if (this.editMaterialId.length) {
                        this.editMaterialId.forEach(el => { 
                            this.uploadMaterials.filter(i => i.name === el.name).map((item, index) => {
                                this.auditionMaterials(item, index, el.id, 'update')
                            })
                        });
                    }

                    if (this.isUploadMaterials) {
                        if (this.editMaterialId.length > 0) {
                            this.editMaterialId.forEach(el => {
                                this.uploadMaterials.filter(i => i.name !== el.name).map((item, index) => {
                                    this.auditionMaterials(item, index, this.audition.id)
                                })
                            })
                        }else {
                            this.uploadMaterials.map((item, index) => {
                                this.auditionMaterials(item, index, this.audition.id) 
                           })
                        }
                    }

                    this.notifySuccess('Successfully Updated!')
                }
            }

            this.$store.dispatch('GET_APPLICATION', { role_id: this.$route.params.roleId, application_id: this.$route.params.applicationId })
            this.$emit('getAudition')
            this.isShowModal = false
        },
        auditionMaterials(item, index, id, v) {
            let formData = new FormData()

            if (item.name && !item.id) {
                formData.append('material_type_id', this.materialTypes[index].id)
                formData.append('attachment', item)

                if (v) auditionMaterial.updateAuditionMaterial(id, formData)
                if (!v) auditionMaterial.createAuditionMaterial(id, formData)
            }
        },
        parseAudition() {
            if (this.audition.type) {
                this.form.status = true
                this.form.meetingLink = this.audition.address
            } else {
                this.form.status = false
                this.form.location = this.audition.address
            }

            const newDate = new Date(this.audition.audition_datetime)
            const date = new Date(newDate.toISOString().slice(0, -1))
            this.form.auditionTime = moment(date).format('H:mm')
            this.form.auditionDate = [new Date(date)]
        },
        async getAuditionMaterial() {
            try {
                let res = await auditionMaterial.getAuditionMaterial(this.audition.id)
                
                if (res.status === 200 && res.data.attachments?.length) {
                    res.data.attachments.forEach(material => {
                        this.uploadMaterials.unshift({
                            id: material.id,
                            name: material.name
                        })
                        this.materialTypes.push(material.attachment_type)
                    })
                }
            } catch (resErr) {
                return resErr
            }
        }
    },
    computed: {
        isFormFilled() {
            if (this.form.status) { // online
                if (!this.form.meetingLink.length) {
                    return false
                }
            } else {
                if (!this.form.location.length) {
                    return false
                }
            }

            if (!this.form.auditionDate.length || !this.form.auditionTime.length) {
                return false
            }
            
            return true
        }
    },
    created() {
        if (this.audition !== null) {
            this.parseAudition()
            this.getAuditionMaterial()
        }
        this.getMaterialType()
    }
}
</script>