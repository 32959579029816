import axiosIns from '@/libs/axios'

export default {
    getRoleApplications(id) {
        return axiosIns.get(`/roles/${id}/applications`)
    },
    getApplication(role_id, application_id) {
        return axiosIns.get(`/roles/${role_id}/applications/${application_id}`)
    },
    giveRole(application_id) {
        return axiosIns.get(`/applications/${application_id}/accept`)
    },
    rejectApplication(application_id) {
        return axiosIns.get(`/applications/${application_id}/reject`)
    }
}