import axiosIns from '@/libs/axios'

export default {
    createSelfTape(application_id, data) {
        return axiosIns.post(`/applications/${application_id}/selftape-request`, data)
    },
    getSelfTape(application_id) {
        return axiosIns.get(`/applications/${application_id}/selftape-request`)
    },
    updateSelfTape(application_id, data) {
        return axiosIns.put(`/applications/${application_id}/selftape-request`, data)
    }
}