<template>
	<b-modal 
        id="notifications" 
        size="lg"
        centered 
        footer-class="justify-content-center footer-pop-up"
        header-class="header-pop-up"
        body-class="body-pop-up body-pop-up-notifications"
        content-class="content-notifications"
        no-close-on-esc
        no-close-on-backdrop
        hide-footer
        scrollable
    >
        <template #modal-header>
            <div>Notifications Center</div>
            <button @click="$bvModal.hide('notifications')">
                <img src="@/assets/images/close.png" alt="close" />
            </button>
        </template>
        <template #default>
            <div class="body-pop-up-modal body-pop-up-modal-notifications">
                <template v-if="newNotifications.length">
                    <div class="notification">New</div>
                    <NotificationsList :notifications="newNotifications" />
                </template>
                <template v-if="earlierNotifications.length">
                    <div class="notification">Earlier</div>
                    <NotificationsList :notifications="earlierNotifications" />
                </template>
                <div class="notification" v-if="!earlierNotifications.length && !newNotifications.length">No Notifications yet</div>
            </div>
        </template>
    </b-modal>
</template>

<script>
import NotificationsList from './NotificationsList.vue';
import { mapGetters } from 'vuex'

export default {
    components: {
        NotificationsList
    },
    created() {
        this.$store.dispatch('GET_NOTIFICATIONS');
    },
    computed: {
        ...mapGetters(['getNotifications']),
        newNotifications() { return this.getNotifications.filter(item => !item.attributes.is_read) },
        earlierNotifications() { return this.getNotifications.filter(item => item.attributes.is_read) }
    }
}
</script>

<style>
    .content-notifications {
        background: #252F44 !important;
        border-radius: 24px !important;
        line-height: 1.5;
        font-family: 'Jost';
        font-style: normal;
        font-weight: 400;
    }
    .body-pop-up-modal-notifications {
        line-height: 1.5 !important;
        height: fit-content !important;
    }
    .body-pop-up-notifications {
        margin: 0 10px 0 !important;
        border: none !important;
    }
    .notification {
        margin: 0px 8px 10px;
        text-align: left;
    }
</style>