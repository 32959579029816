<template>
	<div class="signup">
        <div class="content">
            <div class="wtb">
                <img src="@/assets/images/wtb.png" alt="Welcome Back!" />
            </div>
            <div class="auth-text">
                Please fill out your information below to register your account
            </div>
            <div :class="['full-name', {'log': validationFirstName && validationLastName}]">
                <div class="name">
                    <div class="general-hint">
                        <label>
                            <transition name="fade-transform">
                                <span v-if="form.first_name" class="input-hint input-signup-hint" style="left: 110px">First name</span>
                            </transition>
                            <input 
                                v-model="form.first_name" 
                                type="text" 
                                placeholder="First name" 
                                :class="{'is-invalid': !validationFirstName}"
                            />
                        </label>
                    </div>
                    <div v-if="!validationFirstName" class="invalid invalid-name">
                        <img src="@/assets/images/invalid.png" alt="invalid" />
                        <span>Invalid First Name</span>
                    </div>
                </div>
                <div>
                    <div class="general-hint">
                        <label>
                            <transition name="fade-transform">
                                <span v-if="form.last_name" class="input-hint input-signup-hint" style="left: 110px">Last name</span>
                            </transition>
                            <input 
                                v-model="form.last_name" 
                                type="text" 
                                placeholder="Last name"
                                :class="{'is-invalid': !validationLastName}" 
                            />
                        </label>
                        <div v-if="!validationLastName" class="invalid invalid-name">
                            <img src="@/assets/images/invalid.png" alt="invalid" />
                            <span>Invalid Last Name</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="pr-i log signup-input">
                <label>
                    <transition name="fade-transform">
                        <span v-if="form.company_name" class="input-hint input-signup-hint">Company name</span>
                    </transition>
                    <input v-model="form.company_name" type="text" placeholder="Company name" />
                </label>
            </div>
            <div :class="['pr-i', 'signup-input', {'log': validationPhone}]">
                <label>
                    <transition name="fade-transform">
                        <span v-if="form.phone_number" class="input-hint input-signup-hint">Phone number</span>
                    </transition>
                    <input 
                        v-model="form.phone_number" 
                        type="text" 
                        v-mask ="{regex: '[0-9 +]{5,}', placeholder: ''}"
                        placeholder="Phone number" 
                        :class="{'is-invalid': !validationPhone}"
                    />
                </label>
                <div v-if="!validationPhone" class="invalid invalid-phone">
                    <img src="@/assets/images/invalid.png" alt="invalid" />
                    <span>Invalid phone number. Please enter minimum of 3 and maximum of 15 characters</span>
                </div>
            </div>
            <div :class="['pr-i', 'signup-input', {'log': validationEmail}]">
                <label>
                    <transition name="fade-transform">
                        <span v-if="form.email" class="input-hint input-signup-hint">Personal email</span>
                    </transition>
                    <input 
                        v-model="form.email" 
                        type="email" 
                        placeholder="Personal email" 
                        :class="{'is-invalid': !validationEmail}"
                    />
                </label>
                <div v-if="!validationEmail" class="invalid">
                    <img src="@/assets/images/invalid.png" alt="invalid" />
                    <span>Please enter a valid email address</span>
                </div>
            </div>
            <div class="pr-i log signup-input signup-password">
                <label>
                    <transition name="fade-transform">
                        <span v-if="form.password" class="input-hint input-signup-hint">Password</span>
                    </transition>
                    <input 
                        v-model="form.password" 
                        :type="type" 
                        placeholder="Password" 
                        :class="{'is-invalid': !validationPassword}"
                    />
                </label>
                <div><button @click="togglePassword()" :class="type === 'password' ? 'show-password' : 'hide-password'" /></div>
                <div v-if="!validationPassword" class="invalid invalid-password">
                    <img src="@/assets/images/invalid.png" alt="invalid" />
                    <span>
                        Please enter minimum eight characters, at least one uppercase <br />
                        letter, one lowercase letter, one number and one special character
                    </span>
                </div>
            </div>
            <div class="btn-signup">
                <button :class="['btn-continue', {'btn-inactive': !active}]" @click="signup">Continue</button>
            </div>
        </div>
        <div class="footer">
            by continuing you agree to our         
                <a href="https://www.boompp.com/terms_ppsuite/" target="_blank" class="footer-text">Terms & Conditions</a>
            and 
                <a href="https://www.boompp.com/privacy/" target="_blank" class="footer-text">Privacy Policy</a> 
        </div>
    </div>
</template>

<script>
import auth from '@/mixins/auth';

export default {
    mixins: [auth],
    data() {
        return {
            form: {
                first_name: '',
                last_name: '',
                company_name: '',
                phone_number: ''
            },
            validationPhone: true,
            validationFirstName: true,
            validationLastName: true,
            active: false
        }
    },
    watch: {
        'form.first_name'() { this.validationFirstName = true; },
        'form.last_name'() { this.validationLastName = true; },
        'form.phone_number'() { this.validationPhone = true; },
        form: {
            handler(form) {
                if (form.first_name.length >= 2 && form.last_name.length >= 2 && form.company_name.length > 0 
                    && this.form.phone_number.replace(/[^0-9]/g, '').length > 5 && form.email.length > 4 && form.password.length > 8
                ){ 
                    this.active = true; 
                    this.$emit('activeRegister', this.active);
                }else { 
                    this.active = false; 
                    this.$emit('activeRegister', this.active);
                }
            },
            deep: true
        }
    },
    methods: {
        validName(value){
            var re= /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ðáéíóúäëïöüÄ'łŁőŐűŰZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ ,.'-]{2,100}$/;
            return re.test(value);
        },
        signup() {
            const phone = this.form.phone_number.replace(/[^0-9]/g, '');
            if (phone.length >= 5 && phone.length <= 15) this.validationPhone = true;
            else this.validationPhone = false;

            this.form.password_confirmation = this.form.password;

            if ( !this.validName(this.form.first_name) || !this.form.first_name.length) this.validationFirstName = false;
            if ( !this.validName(this.form.last_name) || !this.form.last_name.length) this.validationLastName = false;
            if ( !this.validEmail()) this.validationEmail = false;
            if ( !this.validPassword()) this.validationPassword = false;
            if ( !this.validationFirstName || !this.validationLastName || !this.validationEmail || !this.validationPassword || !this.validationPhone ) return; 

            this.$store.dispatch('REGISTER', this.form)
                .then(() => {
                    localStorage.setItem('switch', false);
                    this.$router.push({ path: '/active/projects' }); 
                })
        }
    }
}
</script>

<style>
    .signup {
        font-family: 'Jost';
        font-style: normal;
        font-weight: 400;
    }
    .content {
        text-align: center;
    }
    .wtb {
        margin: 100px 0 20px;
    }
    .footer {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 40px;
        text-align: center;
        color: rgba(255, 255, 255, 0.7);
    }
    .full-name {
        display: flex;
        justify-content: center;
    }
    .full-name div input {
        width: 290px;
        height: 44px;
        background: #2f384b;
        border: 0.5px solid rgba(139, 136, 136, 0.5);
        border-radius: 48px;
        padding: 21px;
        color: white;
        font-weight: 400;
    }
    .full-name div input:focus{
        outline: none;
        border-color: rgba(175, 172, 172, 0.8);
    }
    .full-name div input::placeholder {
        color: rgba(255, 255, 255, 0.4);
    }
    .name {
        margin-right: 20px;
    }
    .invalid-phone {
        right: 27px;
    }
    .btn-signup {
        margin-top: 20xp;
    }
    .invalid-name {
        right: 60px;
    }
    .btn-inactive {
        background: rgba(238, 110, 23, 0.1);
    }
    .btn-inactive:hover {
        background: rgba(238, 110, 23, 0.15);
    }
    .footer-text {
        color: white;
    }
    .footer-text:hover {
        color: rgba(243, 241, 241, 0.8);
    }
    .input-signup-hint {
        width: 140px;
        left: 420px;
    }
    @media(max-height: 930px) { 
		.wtb {
			margin-top: 30px;
		}
	}
    @media(max-height: 800px) { 
        .wtb img {
            height: 25px;
        }
        .wtb {
            margin-top: 15px;
            margin-bottom: 15px;
        }
        .auth-text {
            margin-bottom: 20px;
        }
        .signup-password {
            margin: 0;
        }
        .signup-input button {
            bottom: 28px;
        }
        .signup-input input,
        .full-name div input { 
            padding: 10px;
            height: 32px;
        }
        .input-signup-hint {
            bottom: 22px;
            font-size: 12px;
        }
	}
</style>