<template>
    <div class="switch-container">
        <label class="btn-color-mode-switch">
            <input type="checkbox" name="color_mode" id="color_mode" v-model="checked" value="true" @change="changeSwitch">
            <label for="color_mode" data-on="sign up" data-off="log in" class="btn-color-mode-switch-inner"></label>
        </label>
    </div>
</template>

<script>
export default {
	data() {
		return {
			checked: localStorage.getItem('switch') === 'true'
		}
	},
	methods: {
		changeSwitch() {
			localStorage.setItem('switch', this.checked);
            
			if (this.checked) this.$router.push( { path:'/signup' } ); 
			else this.$router.push( { path:'/' } );
            
            this.$emit('switch', this.checked);
		}
	}
}
</script>

<style>
    /*Switch styles*/

    .switch-container {
        vertical-align: middle;
        text-align: center;
        margin-top: 50px;
    }
    label.btn-color-mode-switch-inner {
        margin: 0px;
        width: 280px;
        height: 40px;
        background: #252F44;;
        border-radius: 32px;
        overflow: hidden;
        position: relative;
        transition: all 0.3s ease;
        display: block;
        border: 1px solid rgba(255, 255, 255, 0.7);
    }
    label.btn-color-mode-switch-inner:before {
        content: attr(data-on);
        position: absolute;
        top: 7px;
        right: 35px;
        color: rgba(255, 255, 255, 0.5);

    }
    label.btn-color-mode-switch-inner:after {
        content: attr(data-off);
        width: 140px;
        height: 33px;
        background: #fff;
        border-radius: 32px;
        position: absolute;
        left: 2px;
        top: 3px;
        text-align: center;
        transition: all 0.7s ease;
        padding: 5px 0px;
        font-weight: 600;
    }
    label.btn-color-mode-switch-inner:before, label.btn-color-mode-switch-inner:after {
        cursor: pointer;
        font-family: 'Jost', sans-serif;
    }
    .btn-color-mode-switch input[type="checkbox"] {
        width: 50px;
        height: 25px;
        opacity: 0;
        position: absolute;
        top: 0;
        z-index: 1;
        margin: 0px;
    }
    .btn-color-mode-switch input[type="checkbox"]:checked + label.btn-color-mode-switch-inner {
        color: #252F44;
    }
    .btn-color-mode-switch input[type="checkbox"]:checked + label.btn-color-mode-switch-inner:after {
        content: attr(data-on);
        left: 136px;
    }
    .btn-color-mode-switch input[type="checkbox"]:checked + label.btn-color-mode-switch-inner:before {
        content: attr(data-off);
        right: auto;
        left: 50px;
    }
</style>