import Vue from 'vue';
import Vuex from 'vuex';
import properties from './module/properties';
import users from './module/users';
import projects from './module/projects';
import roles from './module/roles';
import applications from './module/applications';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    properties,
    users,
    projects,
    roles,
    applications
  }
})