<template>
    <b-modal 
        id="archive-project" 
        size="lg"
        centered 
        footer-class="justify-content-center footer-pop-up"
        header-class="header-pop-up"
        body-class="body-pop-up"
        content-class="content-pop-up"
        no-close-on-esc
        no-close-on-backdrop
    >
        <template #modal-header>
            <div>{{getProject.status !== 2 ? 'Archive Project' : 'Unarchive Project'}}</div>
            <button @click="$bvModal.hide('archive-project')">
                <img src="@/assets/images/close.png" alt="close" />
            </button>
        </template>
        <template #default>
            <div class="body-pop-up-modal">
                {{ getProject.status !== 2 ? 'Are you sure you want to archive the project?' : 'Are you sure you want to unarchive the project?'}}
            </div>
        </template>
        <template #modal-footer>
            <button class="btn-continue btn-inactive" style="width: 220px;" @click="archive(getProject.id)">
                {{getProject.status !== 2 ? 'Archive' : 'Unarchive'}}
            </button>
        </template>
    </b-modal>
</template>

<script>
import { mapGetters } from 'vuex';
import project from '@/mixins/project';

export default {
    mixins: [project],
    watch: {
        getProject: {
            handler(project) {
                if (project.status !== 2) this.status = true;
                else this.status = false;
            },
            immediate: true
        }
    },
    computed: {
        ...mapGetters(['getProject'])
    }
}
</script>
