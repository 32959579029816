export default {
    methods: { 
        notifySuccess(text) {
            this.$notify({
                title: 'Success',
                message: text,
                type: 'success',
                duration: 5000,
                dangerouslyUseHTMLString: true
            });
        },
        notifyError(text) {
            this.$notify({
                title: 'Error',
                message: text,
                type: 'error',
                duration: 5000
            });
        },
        notifyWarning(text) {
            this.$notify({
                title: 'Warning',
                message: text,
                type: 'warning',
                duration: 5000
            });
        },
        notifyInfo(text) {
            this.$notify.info({
                title: 'Info',
                message: text,
                duration: 5000
            });
        }
    }
}