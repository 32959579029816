<template>
    <div>
        <div class="btn-container role-container">
            <div class="user-info">
                <img v-if="getMe.avatar" :src="getMe.avatar" alt="avatar" />
                <div v-else class="user-avatar"><span>{{ getMe.first_name[0] }}</span></div>
                <div class="btn-container-text">
                    <span>Created by</span><br />
                    {{getMe.first_name}} {{getMe.last_name}}
                </div>
            </div>
            <!-- <div class="btn-casting container-border"> -->
                <!-- <template>
                    <button class="btn-action">
                        <img src="@/assets/images/edit.png" alt="edit" />
                    </button>
                    <div class="btn-container-text">Edit <br />Role Info</div>
                </template> -->
            <!-- </div> -->
            <div class="btn-casting container-border">
                <template>
                    <button class="btn-action" @click="roleUrl">
                        <img src="@/assets/images/clipboard.svg" alt="copy" width="20" height="20" />
                    </button>
                    <div class="btn-container-text">Copy <br />Role URL</div>
                </template>
            </div>
            <div class="btn-casting">
                <button class="btn-action">
                    <img src="@/assets/images/archive.png" alt="archive" />
                </button>
                <div class="btn-container-text">Archive<br />Role </div>
            </div>
        </div>
        <div class="role-info">
            <div class="casting-details">
                <div class="casting-details-title">Casting Details</div>
                <div class="casting-details-box">
                    <div class="casting-details-box-title">
                        <img src="@/assets/images/film.svg" alt="film" />
                        <span>Role and Film</span>
                    </div>
                    <div class="casting-details-box-desc"><span>Role:</span> {{getRole && getRole.name}} | <span>Film:</span> {{getRole.project_name}}</div>
                </div>
                <div v-if="productionLocation()" class="casting-details-box">
                    <div class="casting-details-box-title">
                        <img src="@/assets/images/location.svg" alt="location" />
                        <span>Production location</span>
                    </div>
                    <div class="casting-details-box-desc">
                        {{productionLocation()}}
                    </div>
                </div>
                <div v-if="getRole.pick_shooting_dates?.length" class="casting-details-box">
                    <div class="casting-details-box-title">
                        <img src="@/assets/images/location.svg" alt="location" />
                        <span>Production dates</span>
                    </div>
                    <div class="casting-details-box-desc">
                        {{date(getRole.pick_shooting_dates && getRole.pick_shooting_dates[0].date)}} -
                        {{date(getRole.pick_shooting_dates && getRole.pick_shooting_dates[getRole.pick_shooting_dates.length - 1].date)}}
                    </div>
                </div>
                <div class="casting-details-box" :style="getRole.materials?.length ? '' : 'border-bottom: none;'">
                    <div class="casting-details-box-title">
                        <img src="@/assets/images/business.svg" alt="payment options" />
                        <span>Payment options</span>
                    </div>
                    <div class="casting-details-box-desc">{{getRole.rate}} {{getRole.currency && getRole.currency.symbol}} {{getRole.payment_type && getRole.payment_type.name}}</div>
                </div>
                <div v-if="getRole.materials?.length" class="casting-details-box" style="border-bottom: none;">
                    <div class="casting-details-box-title">
                        <img src="@/assets/images/materials.svg" alt="materials" />
                        <span>Materials</span>
                    </div>
                    <div class="casting-details-box-desc materials" @click="materials">See the preparation materials</div>
                    <div class="preparation-materials" v-for="material in getRole.materials" :key="material.id">
                        <div class="casting-details-box-desc">
                            {{material.attachment.attachment_type.name}}:
                        </div>
                        <div class="casting-details-box-title" style="position: relative;">
                            <img :src="material.icon_url" alt="payment options" />
                            <span>{{material.attachment.name}}</span>
                            <a :href="material.attachment.location" target="_blank">
                                <button class="btn-action btn-download-materials">
                                    <img src="@/assets/images/download.svg" alt="download" />
                                </button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="role-details"> 
                <div class="role-details-title">Role Details</div>
                <div class="role-details-info">
                    <div v-if="getRole.personal_skills?.length" class="role-details-info-box">
                        <span class="role-details-info-title">Required Skills:</span><br />
                        <span class="role-details-info-desc" v-for="(skill, index) in getRole.personal_skills" :key="skill.id">
                            {{skill.name}} 
                            <span v-if="index !== (getRole.personal_skills.length - 1)">&#183;</span>
                        </span>
                    </div>
                    <div v-if="getRole.ethnicity" class="role-details-info-box">
                        <span class="role-details-info-title">Ethnicity:</span><br />
                        <span class="role-details-info-desc">{{getRole.ethnicity && getRole.ethnicity.name}}</span>
                    </div>
                    <div class="role-details-info-box">
                        <template v-if="genderAndActingAge()">
                            <span class="role-details-info-title">Gender and acting age:</span><br />
                            <span class="role-details-info-desc">
                                {{genderAndActingAge()}}
                            </span>
                        </template>
                        <div class="role-details-info-desc role-description">{{getRole.description}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import moment from 'moment';
import notify from '@/mixins/notify';
import role from '@/api/role';

export default {
    mixins: [notify],
    data() {
        return {
            visible: 0
        }
    },
    computed: {
        ...mapGetters(['getMe', 'getRole'])
    },
    methods: {
        ...mapMutations(['SET_ROLE']),
        date(day) { return moment(day).format('YYYY-MM-DD'); },
        skills() { return this.getRole.personal_skills.map(skill => skill.name); },
        materials() {
            let v = '';
            this.visible += 1; 
            const materials = document.getElementsByClassName("preparation-materials");

            if (this.visible % 2 !== 0) v = 'block';
            else v = 'none';

            Array.from(materials).forEach((item) => { item.style.display = v; });
        },
        productionLocation() {
            let location = '';
            if (this.getRole.country?.name) location += this.getRole.country.name;
            if (this.getRole.city && this.getRole.country?.name) location += ', ';
            if (this.getRole.city) location += this.getRole.city;
            if (this.getRole.city && this.getRole.address) location += ', ';
            if (this.getRole.address) location += this.getRole.address;
            return location;
        },
        genderAndActingAge() {
            const minAge = this.getRole.min_age;
            const maxAge = this.getRole.max_age;
            const actingGenderValues = this.getRole.acting_gender_values;
            const isAge = minAge !== null && maxAge !== null;

            let title = '';

            title += actingGenderValues?.length ? actingGenderValues.join(', ') : '';
            title += actingGenderValues?.length && isAge ? ', ' : '';
            title += isAge ? `${minAge} - ${maxAge}` : '';

            return title;
        },
        roleUrl() {
            if (this.getRole.dynamic_link.length) this.copyRoleUrl(this.getRole.dynamic_link)
            else {
                role.renewDynamicLink(this.getRole.id).then((res) => {
                    this.copyRoleUrl(res.data.dynamic_link)
                    this.SET_ROLE({ ...this.getRole, dynamic_link: res.data.dynamic_link })
                })
            }
        },
        copyRoleUrl(url) {
            navigator.clipboard.writeText(url)
                .then(() => { this.notifySuccess('Link copied') })
                .catch(() => { this.notifyError('Something went wrong') })
        }
    }
}
</script>

<style>
    .casting-details {
        width: 335px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.05);
        padding: 30px 0 20px;
    }
    .casting-details-title, .role-details-title {
        color: white;
        font-size: 20px;
    }
    .casting-details-box {
        padding: 15px 0;
        border-bottom: 1px solid rgba(255, 255, 255, 0.05);
    }
    .casting-details-box-title {
        color: rgba(255, 255, 255, 0.7);
        height: 30px;
    }
    .casting-details-box-title span {
        margin-left: 10px;
        position: relative;
        top: 3px;
        display: inline-block;
        max-width: 205px;
        vertical-align: middle;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .casting-details-box-desc span {
        font-style: italic;
        color: rgba(255, 255, 255, 0.4);
        font-size: 15px;
    }
    .casting-details-box-desc, .role-details-info-desc {
        font-size: 17px;
        color: rgba(255, 255, 255, 0.8);
    }
    .materials {
        text-decoration: underline;
        cursor: pointer;
    }
    .materials:hover {
        color: rgba(255, 255, 255, 0.9);
    }
    .role-details {
        width: 335px;
        padding: 30px 0;
    }
    .role-details-info-title {
        color: rgba(255, 255, 255, 0.5);
        font-style: italic;
    }
    .role-details-info {
        margin-top: 15px;
    }
    .role-details-info-box {
        height: fit-content;
        margin-bottom: 20px;
    }
    .role-description {
        text-indent: 30px;
        text-align: justify;
        margin-top: 20px;
        width: 335px;
    }
    .role-info {
        overflow-y: auto;
        max-height: calc(100vh - 235px);
        width: 346px;
        margin-top: 10px;
    }
    .role-container {
        height: 110px;
        padding-bottom: 30px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.05);
    }
    .preparation-materials {
        display: none;
        margin: 15px 20px 0px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.05);
        padding-bottom: 10px;
        word-wrap: break-word;
        width: 250px;
    }
    .preparation-materials:last-of-type {
        border-bottom: none;
        padding-bottom: 0;
    }
    .btn-download-materials {
        position: absolute;
        left: 255px;
    }
</style>