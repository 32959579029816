<template>
	<b-modal 
        v-if="isSelfTape"
        v-model="isShowModal"
        @hide="$emit('hide')"
        id="self-tape-request" 
        size="lg"
        centered 
        footer-class="justify-content-center footer-pop-up"
        header-class="header-pop-up"
        body-class="body-pop-up"
        content-class="content-self-tape-request"
        no-close-on-esc
        no-close-on-backdrop
    >
        <template #modal-header>
            <div>Self-tape Request</div>
            <button @click="isShowModal = false">
                <img src="@/assets/images/close.png" alt="close" />
            </button>
        </template>
        <template #default>
            <div class="body-pop-up-modal body-pop-up-modal-self-tape-request">
                <div class="modal-textarea modal-textarea-self-tape-request">
                    <transition name="fade-transform">
                        <div v-if="description" class="self-tape-description-optional">Self-tape description</div>
                    </transition>
                    <b-form-textarea
                        v-model="description"
                        autocomplete="off"
                        type="text"
                        placeholder="Self-tape description"
                        class="project-description self-tape-description"
                        no-resize
                        maxlength="5000"
                    />
                </div>
                <hr />
                <!-- Upload materials -->
                <div class="upload-materials">
                    <div
                        class="upload-materials__list"
                        v-for="(item, index) in uploadMaterials"
                        :key="index"
                    >
                        <template v-if="!item.name">
                            <Multiselect
                                :options="materialTypesOptions"
                                v-model="materialType"
                                :searchable="false"
                                :close-on-select="true"
                                :show-labels="false"
                                :allow-empty="false"
                                placeholder="Choose material type"
                                label="name"
                            />
                            <div class="upload-file-section">
                                <span class="upload-file-section__title">
                                    Upload preparation material
                                </span>
                                <label :for="'file-input_' + index">
                                    <img
                                        :class="[{ disabled: !materialType.name }, 'upload-file-section__icon']"
                                        :src="getImageUpload"
                                        alt="upload"
                                    />
                                </label>
                                <input
                                    v-if="materialType.name"
                                    class="file-input"
                                    :id="'file-input_' + index"
                                    type="file"
                                    @change="previewFiles"
                                />
                            </div>
                        </template>
                        <template v-else>
                            <div class="upload-materials__uploaded-item">
                                <span>{{ item.name }}</span>
                                <div class="upload-materials-actions">
                                    <label :for="'file-edit_' + index">
                                        <img
                                            :src="getImageEdit"
                                            alt="edit"
                                        />
                                    </label>
                                    <input class="file-input" :id="'file-edit_' + index" type="file" @change="e => editMaterialFile(e, item.id, index)"/>

                                    <img
                                        :src="getImageVerticalLine"
                                        class="upload-materials-actions__vertical-line"
                                        alt="vertical-line"
                                    />
                                    <img
                                        @click="deleteMaterialFile(item.id, index)"
                                        :src="getImageDelete"
                                        alt="delete"
                                    />
                                </div>
                            </div>
                        </template>
                    </div>
                    <div class="upload-materials-button" @click="addUploadMaterial">
                        <span class="upload-materials-button__title">
                            Upload more materials
                        </span>
                        <img :src="getImagePlus" alt="plus" />
                    </div>
                </div>
                <hr />
                <div class="date-picker-self-tape-request">
                    <date-picker
                        v-model="deadline_datetime"
                        format="h:mm A on DD.MM.YYYY"
                        placeholder="Self-tape deadline (date & time)"
                        lang="en"
                        :popup-style="{'z-index': 1100}"
                        type="datetime"
                        :disabled-date="disabledDate"
                    />
                </div>
            </div>
        </template>
        <template #modal-footer>
            <button  :class="['btn-continue', {'btn-inactive': !active}]" style="width: 220px;" @click="submit">Request Self-tape</button>
        </template>
    </b-modal>
</template>

<script>
import notify from '@/mixins/notify';
import 'vue2-datepicker/index.css';
import Multiselect from 'vue-multiselect';
import { isEmpty } from 'lodash';
import moment from 'moment';
import other from '@/api/other';
import selfTape from '@/api/selfTape';
import selfTapeMaterial from '@/api/selfTapeMaterial';

export default {
    mixins: [notify],
    components: {
        Multiselect
    },
    data() {
        return {
            description: '',
            uploadMaterials: [{}],
            materialType: [],
            materialTypes: [],
            materialTypesOptions: [],
            deadline_datetime: '',
            getImageUpload: require('@/assets/images/upload.png'),
            getImageEdit: require('@/assets/images/edit.svg'),
            getImageDelete: require('@/assets/images/delete.svg'),
            getImageVerticalLine: require('@/assets/images/vertical-line.svg'),
            getImagePlus: require('@/assets/images/plus.svg'),
            isEdit: false,
            removeMaterialId: [],
            applicationSelfTapeId: null,
            editMaterialId: [],
            isShowModal: true,
            isSelfTape: false,
            isUploadMaterials: false
        }
    },
    created() {
        this.getMaterialType();
        selfTape.getSelfTape(this.$route.params.applicationId).then(res => {
            if (res.data.description) {
                this.isEdit = true;
                this.description = res.data.description;

                const date = new Date(res.data.deadline_datetime);
                this.deadline_datetime = new Date(date.toISOString().slice(0, -1));

                selfTapeMaterial.getSelfTapeMaterials(res.data.id).then(res => {
                    this.applicationSelfTapeId = res.data.id;
                    res.data.application_selftape_materials.forEach(material => {
                        this.uploadMaterials.unshift({
                            id: material.id,
                            name: material.attachment.name
                        })
                        this.materialTypes.push(material.attachment.attachment_type);
                    })
                    this.isSelfTape = true;
                })
            }else {
                this.isSelfTape = true;
            }
        })
    },
    computed: {
        active() {
            if (!this.description.length || !this.deadline_datetime)  return false;
            return true;
        }
    },
    methods: {
        async editMaterialFile(event, id, index) {
            const file = event.target.files[0]
            
            if (id) {
                this.uploadMaterials.map((el, i) => {
                    if (i === index) el.name = file.name
                })
                this.editMaterialId.push({id: id, name: file.name});
            }

            this.uploadMaterials.splice(index, 1, file)
        },
        previewFiles(event) {
            try {
                this.isUploadMaterials = true
                const file = event.target.files[0]

                let lastIndex = Object.keys(this.uploadMaterials)[this.uploadMaterials.length - 1]
                this.uploadMaterials.splice(lastIndex, 1, file)

                this.materialTypes.push(this.materialType)
            } catch (error) {
                return error
            }
        },
        async getMaterialType() {
            try {
                let res = await other.getMaterialTypes()
                this.materialTypesOptions = res.data
            } catch (err) {
                return err
            }
        },
        addUploadMaterial() {
            if (
                this.uploadMaterials.some(i => i.name === undefined) ||
                isEmpty(this.materialType)
            ) {
                return false
            }

            this.materialType = []
            this.uploadMaterials.push({})
        },
        async deleteMaterialFile(id, index) {
            if (id) this.removeMaterialId.push(id);

            this.uploadMaterials.splice(index, 1)
            this.materialTypes.splice(index, 1)

            if (this.uploadMaterials.length === 0) {
                this.materialType = []
                this.uploadMaterials.push({})
            } 
        },
        async submit() {
            const formData = new FormData();
            
            this.materialTypes.forEach(item => {
                formData.append('materials[][material_type_id]', item.id);
            })

            this.uploadMaterials.forEach(item => {
                if (item.name) formData.append('materials[][attachment]', item);
            })

            if (!this.description.length) return this.notifyWarning('Self-tape description can`t be empty!');
            formData.append('description', this.description);

            if (!this.deadline_datetime) return this.notifyWarning('Self-tape deadline can`t be empty!');
            formData.append('deadline_datetime', moment(this.deadline_datetime).format('YYYY-MM-DD HH:mm'));

            if (!this.isEdit) {
                let res = await selfTape.createSelfTape(this.$route.params.applicationId, formData);
                if (res.status === 200) {
                    this.isShowModal = false;
                    this.$store.dispatch('GET_APPLICATION', { role_id: this.$route.params.roleId, application_id: this.$route.params.applicationId });
                }
            }else {
                let res = await selfTape.updateSelfTape(this.$route.params.applicationId, { description: this.description, deadline_datetime: moment(this.deadline_datetime).format('YYYY-MM-DD HH:mm:ss') })
                if (res.status === 200) { 
                    this.isShowModal = false;
                    
                    if (this.removeMaterialId.length) {
                        this.removeMaterialId.forEach(id => { selfTapeMaterial.deleteSelfTapeMaterial(id) });
                    }

                    if (this.editMaterialId.length) {
                        this.editMaterialId.forEach(el => { 
                            this.uploadMaterials.filter(i => i.name === el.name).map((item, index) => {
                                this.selfTapeMaterials(item, index, el.id, 'update')
                            })
                        });
                    }

                    if (this.isUploadMaterials) {
                        if (this.editMaterialId.length > 0) {
                            this.editMaterialId.forEach(el => {
                                this.uploadMaterials.filter(i => i.name !== el.name).map((item, index) => {
                                    this.selfTapeMaterials(item, index, this.applicationSelfTapeId)
                                })
                            })
                        }else {
                            this.uploadMaterials.map((item, index) => {
                                this.selfTapeMaterials(item, index, this.applicationSelfTapeId)
                           })
                        }
                    }

                    this.notifySuccess('Successfully Updated!')
                }
            }
        },
        selfTapeMaterials(item, index, id, v) {
            let formData = new FormData()

            if (item.name && !item.id) {
                formData.append('material_type_id', this.materialTypes[index].id)
                formData.append('attachment', item)

                if (v) selfTapeMaterial.updateSelfTapeMaterial(id, formData)
                if (!v) selfTapeMaterial.createSelfTapeMaterial(id, formData)
            }
        },
        disabledDate(date) {
            const today = new Date(moment().subtract(1, 'day'));
            return date <= today;
        }
    }
}
</script>

<style lang="scss">
    @import '@/assets/styles/pages/self-tape-request-upload-materials.sass';
</style>

<style>
    .content-self-tape-request {
        background: #252F44 !important;
        border-radius: 24px !important;
        line-height: 1.5;
    }
    .self-tape-description {
        height: 90px;
    }
    .body-pop-up-modal-self-tape-request {
        line-height: 1.5 !important;
        height: fit-content !important;
    }
    .modal-textarea-self-tape-request {
        position: relative;
    }
    .modal-textarea-self-tape-request > textarea::placeholder {
        font-weight: 400;
        font-size: 16.5px;
    }
    .modal-textarea-self-tape-request ~ hr {
        opacity: 0.1;
    }
    .self-tape-description-optional {
        position: absolute;
        bottom: 95px;
        left: 72%;
        font-size: 13px;
        width: 150px;
        border: 0.5px solid rgba(139, 136, 136, 0.5);
        background: #30394D;
        border-radius: 16px;
    }
    .date-picker-self-tape-request {
        margin: 25px 0 25px;
    }
    .date-picker-self-tape-request > .mx-datepicker {
        display: initial;
    }
    .date-picker-self-tape-request > .mx-datepicker > .mx-input-wrapper > .mx-input {
        height: 46px;
        padding-left: 18px;
        font-size: 15.5px;
    }
    .mx-time {
        background: #2f384b;
        border: 0.5px solid rgba(139, 136, 136, 0.5);
        border-radius: 24px;
    }
    .mx-time-item:hover {
        color: white !important;
        background-color: #EE6E17 !important;
    }
</style>