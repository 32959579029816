<template>
	<b-modal 
        v-if="project"
        v-model="isShowModal"
        @hide="$emit('hide')"
        id="offer-different-role" 
        size="lg"
        centered 
        footer-class="justify-content-center footer-pop-up"
        header-class="header-pop-up"
        body-class="body-pop-up"
        content-class="content-pop-up"
        no-close-on-esc
        no-close-on-backdrop
    >
        <template #modal-header>
            <div>Offer Different Role</div>
            <button @click="isShowModal = false">
                <img src="@/assets/images/close.png" alt="close" />
            </button>
        </template>
        <template #default>
            <div class="body-pop-up-modal body-pop-up-modal-offer-different-role">
                <div class="header-content">
                    <div class="select-project">
                        <span class="select-offer-text">Role:</span>
                        <Multiselect 
                            v-model="role" 
                            :options="roles" 
                            :searchable="false" 
                            :close-on-select="true" 
                            :show-labels="false"
                            :allow-empty="false"
                            track-by="name"
                            label="name" 
                            placeholder="Role" 
                            class="project-multiselect casting-multiselect"
                            @open="isOpen = true"
                            @close="isOpen = false"
                        />
                        <span> | </span>
                        <span class="select-offer-text" style="margin-left: 25px;">Film:</span>
                        <Multiselect 
                            v-model="project" 
                            :options="projects" 
                            :searchable="false" 
                            :close-on-select="true" 
                            :show-labels="false"
                            :allow-empty="false"
                            track-by="name"
                            label="name" 
                            placeholder="Film" 
                            class="project-multiselect casting-multiselect"
                            @input="changeProject"
                            @open="isOpen = true"
                            @close="isOpen = false"
                        />
                    </div>
                </div>
                <div :class="['recommend-role', {'is-open': isOpen }]">
                    <div class="recommend-role-name">{{role.name}}</div>
                    <div class="recommend-role-details">{{ role.acting_gender_values.join(', ') }}<span v-if="role.acting_gender_values?.length">,</span> {{role.min_age}}<span v-if="role.max_age">-</span>{{role.max_age}}<span v-if="role.ethnicity && role.ethnicity.name">,</span> {{role.ethnicity && role.ethnicity.name}}</div>
                </div>
            </div>
        </template>
        <template #modal-footer>
            <button class="btn-continue" style="width: 220px;" @click="recommendRole">
                Recommend a Role
            </button>
        </template>
    </b-modal>
</template>

<script>
import notify from '@/mixins/notify';
import Multiselect from 'vue-multiselect';
import recommendRole from '@/api/recommendRole';
import project from '@/api/project';
import { mapGetters } from 'vuex';


export default {
    mixins: [notify],
    components: {
        Multiselect
    },
    data() {
        return {
            project: null,
            role: null,
            isOpen: false,
            activeProjects: [],
            roleId: null,
            isShowModal: true
        }
    },
    created() {
        project.getActiveProjectsAndRoles().then(res => {
            this.activeProjects = res.data;
            if (this.roleName) {
                recommendRole.getRecommendRole(this.$route.params.applicationId).then(res => {
                    this.roleId = res.data.role.id;
                    this.activeProjects.forEach(project => {
                        project.roles.forEach(role => {
                            if (role.id === this.roleId) this.project = project;
                        })
                    })
                    this.role = this.project.roles.find(role => role.id === this.roleId);
                })
            }else {
                this.project = this.activeProjects.find(project => project.id === Number(localStorage.getItem('project_id')));
                const role = this.project.roles.find(role => role.id === Number(localStorage.getItem('role_id')));
                if (role) this.role = role;
                else this.role = this.project.roles[0];
            }
        })
    },
    computed: {
        projects() { return this.activeProjects; },
        roles() { return this.project.roles; },
        ...mapGetters(['roleName'])
    },
    methods: {
        changeProject(project) {
            this.role = {
                id: project.roles[0].id,
                name: project.roles[0].name,
                acting_gender_values: project.roles[0].acting_gender_values,
                min_age: project.roles[0].min_age,
                max_age: project.roles[0].max_age,
                ethnicity: { name: project.roles[0].ethnicity && project.roles[0].ethnicity.name}
            }
        },
        recommendRole() {
            if (this.roleName) {
                recommendRole.editRecommendRole(this.$route.params.applicationId, {role_id: this.role.id}).then(() => {
                    this.$store.commit('SET_ROLE_NAME', this.role.name);	
                    this.isShowModal = false;
                })
            }else {
                recommendRole.recommendRole(this.$route.params.applicationId, {role_id: this.role.id}).then(() => {
                    this.isShowModal = false;
                    this.$store.dispatch('GET_APPLICATION', { role_id: localStorage.getItem('role_id'), application_id: this.$route.params.applicationId });
                })
            }
        }
    }
}
</script>

<style>
    .body-pop-up-modal-offer-different-role {
        line-height: 1 !important;
        height: 195px !important;
    }
    .select-offer-text {
        width: 60px;
    }
    .recommend-role {
        margin: 40px 20%;
        width: 400px;
        height: 100px;
        background: linear-gradient(180deg, rgba(47, 60, 86, 0.3) 0%, #2F3C56 100%);
        border: 1px solid rgba(255, 255, 255, 0.3);
        border-radius: 24px;
    }
    .recommend-role-name {
        margin: 24px 0 12px;
        font-size: 22px;
    }
    .recommend-role-details {
        color: rgba(255, 255, 255, 0.7);
        font-size: 16px;
    }
    .is-open {
        background: #252f44;
        border: 1px solid rgba(255, 255, 255, 0.05);
        color: rgba(255, 255, 255, 0.7);
    }
</style>