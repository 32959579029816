import moment from 'moment';
import project from '@/api/project';

const state = {
    projects: [],
    project: {}
}

const getters = {
    getProjects: state => state.projects,
    getProject: state => state.project
}

const mutations = {
    SET_PROJECTS(state, payload) {
        state.projects = payload.map(project => {
            project.attributes.start_date = moment(project.attributes.start_date).format('ll');
            project.attributes.deadline = moment(project.attributes.deadline).format('ll');
            return project;
        })
    },
    SET_PROJECT(state, payload) { state.project = payload }
}

const actions = {
    GET_PROJECTS({commit}, status) {
        return project.getProjects(status).then((projects) => {
            commit('SET_PROJECTS', projects.data.data);
            return projects.data.data;
        })
    },
    GET_PROJECT({commit}, id) {
        return project.getProject(id).then((project) => {
            commit('SET_PROJECT', project.data);
            return project.data;
        })
    }
}
export default {
    state,
    getters,
    mutations,
    actions
}