<template>
    <div class="application-status">
        <div
            class="application-status-wrapper"
            :style="{
                'background': `linear-gradient(104.45deg, ${darkColor} 29.37%, ${lightColor} 63.08%, ${darkColor} 100%)`
            }"
        >
            <div>
                {{getApplication.actor.first_name}} {{getApplication.actor.last_name}} 
                <span v-if="getStatusName() === 'approved'">has been cast for</span> 
                <span v-else-if="getStatusName() === 'approval'">has been offered</span>
                <span v-else>
                    <template v-if="audition?.status === 0">has been sent an audition request</template>
                    <template v-else-if="audition?.status === 1">has accepted the audition invitation for</template>
                    <template v-else-if="audition?.status === 2">has rejected the audition invitation for</template>
                </span> 
                <span> the </span>{{getRole.name}}<span> role </span> 
                <template v-if="getStatusName() === 'approval'">
                    <br />
                    <span style="font-size: 14px;">Waiting for the actor to accept the offer</span>
                </template>

                <img
                    v-if="getStatusName() === 'audition'"
                    :src="getImageEdit"
                    alt="edit"
                    class="edit"
                    @click="showAuditionInvitation = true"
                />

                <template v-if="getStatusName() === 'audition' && audition?.status === 0">
                    <br />
                    <span style="font-size: 14px;">Waiting for the actor to accept the request</span>
                </template>
            </div>
        </div>

        <audition-invitation
            v-if="showAuditionInvitation"
            @hide="showAuditionInvitation = false"
            :audition="audition"
            @getAudition="getAudition"
        />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AuditionInvitation from '@/components/popups/audition-invitation';
import audition from '@/api/audition';

export default {
    name: 'ApplicationStatus',
    components: {
        AuditionInvitation
    },
    data() {
        return {
            getImageEdit: require('@/assets/images/edit.svg'),
            darkColor: null,
            lightColor: null,
            showAuditionInvitation: false,
            audition: null
        }
    },
    computed: {
        ...mapGetters(['getRole', 'getApplication']),
    },
    watch: {
        getApplication: {
            handler(getApplication) {
                if (getApplication.status === 40) {
                    this.darkColor = '#EE6E17'
                    this.lightColor = '#FFCC48'

                    this.getAudition()
                }else if (getApplication.status === 11) {
                    this.darkColor = '#EEDD17'
                    this.lightColor = '#FFCC48'
                }else {
                    this.darkColor = '#00B682'
                    this.lightColor = '#90FFDF'
                }
            },
            immediate: true
        }
    },
    methods: {
        getStatusName() {
            let map = {
                10: 'approved',
                11: 'approval',
                40: 'audition'
            }

            return map[this.getApplication.status]
        },
        async getAudition() {
            try {
                let res = await audition.getAudition(this.$route.params.applicationId)

                /**
                 * Is problem backend
                 * If res.data is array is empty audition
                 */
                if (!Array.isArray(res.data)) {
                    this.audition = res.data
                }
            } catch (resErr) {
                return resErr
            }
        }
    }
}
</script>

<style>
    .application-status {
        display: flex;
        justify-content: center;
        margin: 0px 30px 45px;
        border-bottom: 1px dashed rgba(255, 255, 255, 0.3)
    }
    .application-status-wrapper {
        width: 700px;
        border-radius: 32px;
        padding: 1px 2px;
        margin-bottom: 45px;
    }
    .application-status-wrapper div {
        background: #303a50;
        color: white;
        padding: 9px 15px 11px;
        border-radius: 32px;
        text-align: center;
        font-size: 18px;
    }
    .application-status-wrapper span {
        color: rgba(255, 255, 255, 0.6);
    }
    .edit {
        padding-top: 2px;
        float: right;
    }
    .edit:hover {
        cursor: pointer;
    }
</style>