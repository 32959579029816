<template>
    <div>
        <div 
            v-for="application in applications" 
            :key="application.id" 
            :id="activeTabId === 5 ? 'reject-applications' : ''"
            :class="['applications', {'applications-tab': activeTabId !== 1}, applicationClass(application.attributes.status)]" 
        >   
            <template v-if="application.attributes.actor">
                <div class="casting-headshot">
                    <img v-if="getHeadshot(application)" :src="getHeadshot(application)" alt="headshot" />
                    <div v-else>
                        <span>{{application.attributes.actor.first_name[0]}}</span>
                    </div>
                </div> 
                <div :id="activeTabId === 5 ? 'reject-casting-actor-info' : ''" class="casting-actor-info-container">
                    <div class="casting-actor-info">
                        {{application.attributes.actor.first_name}} 
                        {{application.attributes.actor.last_name}}<span v-if="application.attributes.actor.actorInfo.acting_gender_values">, </span>
                        <span v-if="application.attributes.actor.actorInfo.acting_gender_values">{{ application.attributes.actor.actorInfo.acting_gender_values.join(', ') }}</span>
                        <span v-if="application.attributes.actor.actorInfo.min_age">,</span>
                        {{application.attributes.actor.actorInfo.min_age}}<span v-if="application.attributes.actor.actorInfo.max_age"> - </span>{{application.attributes.actor.actorInfo.max_age}}
                    </div>
                    <div class="casting-actor-skills">
                        <template v-if="application.attributes.actor.personal_skills.length">
                            <span 
                                v-for="skill in application.attributes.actor.personal_skills.slice(0, activeTabId === 5 ? (width < 1650 ? 1 : 2) : count)" 
                                :key="skill.id" 
                                class="casting-actor-skill"
                            >
                                {{skill.name}}
                            </span>
                            <span v-if="application.attributes.actor.personal_skills.length > 3" class="casting-actor-skill">...</span>
                        </template>
                    </div>
                    <div class="casting-actor-bio">{{application.attributes.actor.actorInfo.bio}}</div>
                </div>
                <div 
                    :class="['view-full-application-btn', {'view-full-application-btn-approved': ![0].includes(application.attributes.status) && activeTabId === 1} ]"
                >
                    <div 
                        v-if="![0].includes(application.attributes.status) && activeTabId === 1"
                        :class="['applications-status', applicationClass(application.attributes.status)]"
                    >
                        {{application.attributes.status_value}}
                    </div>
                    <button 
                        :class="{'application-status-btn': ![0].includes(application.attributes.status) && activeTabId === 1}"
                        @click="previewApplication(application.id)"
                        :style="activeTabId !== 5 ? '' : 'width: 70px;'"
                    >
                        {{activeTabId !== 5 ? 'View full application' : 'View'}}
                    </button>
                </div>
            </template>
            <template v-else>
                <div class="casting-headshot">
                    <img src="@/assets/images/ellipse.svg" alt="headshot" />
                </div> 
                <div class="deleted-account">
                    The applicant for the role has deleted his account
                </div>
            </template>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        applications: {
            default: () => [],
            type: Array
        },
        activeTabId: {
            default: 1,
            type: Number
        },
        width: {
            default: null,
            type: Number
        },
        count: {
            default: 2,
            type: Number
        }
    },
    methods: {
        previewApplication(applicationId) {
            this.$router.push(`/role/${localStorage.getItem('role_id')}/application/${applicationId}`);
        },
        applicationClass(status) {
            const statusClasses = {
                0: 'in-review',
                10: 'approved',
                11: 'approval',
                40: 'audition',
                50: 'selftape-request',
                60: 'role-offer',
                51: 'selftape-provided'
            };
            return { [statusClasses[status]]: this.activeTabId === 1 };
        },
        getHeadshot(application) {
            if (application?.attributes?.actor?.headshots && application.attributes.actor.headshots.length) {
                return application.attributes.actor.headshots[0].location;
            }

            return null
        }
    },
}
</script>

<style>
    .applications {
        display: flex;
        width: 1320px;
        height: 110px;
        margin-bottom: 25px;
        background: #2C374C;
        border: 1px solid #2C374C;
        border-radius: 120px 80px 80px 120px;
    }
    .casting-headshot {
        width: 115px;
        height: 110px;
    }
    .casting-actor-info-container {
        width: 950px;
        height: 110px;
        color: #FFFFFF;
    }
    .casting-actor-info {
        font-size: 20px;
        margin: 8px 0 12px;
    }
    .casting-actor-skills {
        margin-bottom: 12px;
        white-space: nowrap;
    }
    .casting-actor-skill {
        background: #222A3D;
        border-radius: 20px;
        width: fit-content;
        padding: 5px 20px; 
        font-size: 14px;
        margin-right: 10px;
    }
    .casting-actor-bio {
        font-size: 14px;
    }
    .view-full-application-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 250px;
    }
    .view-full-application-btn button {
        width: 180px;
        height: 36px;
        border: 0.5px solid rgba(250, 250, 250, 0.2);
        border-radius: 20px;
        background: #2C374C;
        color: white;
        font-size: 14px;
    }
    .view-full-application-btn button:hover {
        border: 0.5px solid rgba(250, 250, 250, 0.4);
    }
    .casting-headshot img {
        height: 98px;
        width: 98px;
        border-radius: 50%;
        margin: 5px 0 0 5px;
        object-fit: cover;
    }
    .casting-headshot div {
        background-color: #00B683;
        border-radius: 50%;
        width: 98px;
        height: 98px;
        text-align: center;
        line-height: 92px;
        margin: 6px;
    }
    .casting-headshot div span {
        color: white;
        height: 98px;
        width: 98px;
        font-size: 48px;
    }
    #reject-applications {
        width: 800px;
    }
    #reject-casting-actor-info {
        width: 435px; 
    }
    .applications-status {
        position: absolute;
        top: -1px;
        width: 255px;
        height: 30px;
        text-align: center;
        background: #252F44;
        border-radius: 0px 120px 0px 120px;
        border: 1px solid #252F44;
        font-size: 16px;
        line-height: 26px;
    }
    .in-review, .applications-tab {
        border: none;
        border-radius: 120px 16px 16px 120px; 
    }
    .approved {
        border-color: #29CB9C;
    }
    .approval {
        border-color: #EEDD17;
    }
    .audition {
        border-color: #d38751;
    }
    .selftape-request {
        border-color: #59aacf;
    }
    .role-offer {
        border-color: rgb(229, 220, 241);
    }
    .selftape-provided {
        border-color: rgb(210, 118, 223);
    }
    .view-full-application-btn-approved {
        position: relative;
    }
    .application-status-btn {
        margin-top: 20px;
        border: 0.5px solid rgba(250, 250, 250, 0.05) !important;
    }
    .application-status-btn:hover {
        border: 0.5px solid rgba(250, 250, 250, 0.4) !important;
    }
    .deleted-account {
        line-height: 105px;
        color: white;
        margin: 0 20px 0 auto;
    }
    @media(max-width: 2060px) { 
        #reject-applications {
            width: 700px;
        }
        #reject-applications div {
            margin-right: 12px;
        }
    }
    @media(max-width: 1900px) { 
        #reject-applications {
            width: 600px;
        }
        #reject-casting-actor-info {
            width: 335px; 
        }
    }
    @media(max-width: 1775px) { 
        .applications {
            width: 1100px;
        }
        .casting-actor-info-container {
            width: 730px;
        }
    }
    @media(max-width: 1650px) { 
        #reject-applications {
            width: 500px;
        }
        #reject-casting-actor-info {
            width: 270px; 
        }
        .deleted-account {
            font-size: 14px;
        }
    }
    @media(max-width: 1536px) { 
        .search-actor-container input {
            width: 550px !important;
        }
        .applications {
            width: 900px;
        }
        .casting-actor-info-container {
            width: 530px;
        }
    }
    @media(max-width: 1470px) { 
        #reject-applications {
            width: 430px;
        }
        #reject-casting-actor-info {
            width: 210px; 
        }
        .deleted-account {
            font-size: 13.5px;
        }
    }
    @media(max-width: 1400px) { 
        .applications {
            width: 800px;
        }
        .casting-actor-info-container {
            width: 430px;
        }
    }
</style>