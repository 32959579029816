<template>
    <div
        @click="roleDetails"
        :class="[
            'roles-container-item',
            { 'roles-container-item--blur': hasBlue },
            { 'roles-container-item-approved': isApprovedActor },
            { 'roles-container-item-approval': isApprovalActor },
            { 'roles-container-item-audition': this.role.attributes.status === 40 }
        ]"
        :style="{
            'background': `linear-gradient(104.45deg, ${darkColor} 29.37%, ${lightColor} 63.08%, ${darkColor} 100%), #D9D9D9`
        }"
    >
        <div class="wrapper-roles-container-item">
            <div
                v-if="hasBlue"
                :class="['roles-container-overlay', { 'roles-container-overlay--blur': hasBlue }]"
            >
                <template v-if="!showColorsLabels">
                    <div class="roles-container-edit-role" @click.stop="showModal = true">
                        <span>Edit role details</span>
                    </div>
                    <div class="roles-container-add-color" @click.stop="showColorsLabels = true">
                        <span>Add a color label</span>
                    </div>
                </template>
                <template v-else>
                    <div class="roles-colors-labels">
                        <span class="roles-colors-labels__title">
                            Add a color label
                        </span>
                        <div class="roles-colors-labels__colors-list">
                            <img class="test" @click.stop="setBorderColor('green')" :src="getImageGreenLabel" />
                            <img class="test" @click.stop="setBorderColor('black')" :src="getImageBlackLabel" />
                            <img class="test" @click.stop="setBorderColor('blue')" :src="getImageBlueLabel" />
                            <img class="test" @click.stop="setBorderColor('purple')" :src="getImagePurpleLabel" />
                            <img class="test" @click.stop="setBorderColor('red')" :src="getImageRedLabel" />
                            <img class="test" @click.stop="setBorderColor('pink')" :src="getImageLightPurpleLabel" />
                        </div>
                    </div>
                </template>
            </div>

            <div :class="['roles-container-item__header', { 'roles-container-item__header--blur': hasBlue }]">
                <img :src="getImageEdit" class="roles-container-item__logo-edit" @click.stop="hasBlue = true" />
                <span class="roles-container-item__name">{{ role.attributes.name }}</span>
                <div class="roles-container-item__description">
                    <span>{{ getSubTitle() }}</span>
                </div>
            </div>

            <img :src="getImageLine" />

            <div
                v-if="role.attributes.applications_count > 0"
                :class="['roles-container-item__footer', { 'roles-container-item__footer--blur': hasBlue }]"
            >
                <div class="roles-container-item__footer--left">
                    <div
                        v-if="isSeveralActors"
                        class="roles-actors-avatars"
                    >
                        <img v-if="role.attributes.applications[0]" width="34" height="34" :src="getAvatar(role.attributes.applications[0])"/>
                        <img v-if="role.attributes.applications[1]" width="34" height="34" :src="getAvatar(role.attributes.applications[1])"/>
                        <img v-if="role.attributes.applications_count > 2" :src="getImageDotsPlaceholder"/>
                        <img v-if="deletedApplications" width="34" height="34" src="@/assets/images/ellipse.svg" />
                    </div>
                    <div
                        v-else
                        :class="[deletedApplications ? 'roles-actors-avatars' : 'roles-actor-avatar']"
                    >
                        <img v-if="role.attributes.applications[0]" width="34" height="34" :src="getAvatar(role.attributes.applications[0])"/>
                        <span v-if="!deletedApplications">{{role.attributes.applications[0].actor.first_name +' '+ role.attributes.applications[0].actor.last_name}}</span>
                        <img v-if="deletedApplications" width="34" height="34" src="@/assets/images/ellipse.svg" />
                    </div>
                </div>

                <img
                    :src="getImageSmallVerticalLine"
                    :class="{'roles-container-item__footer--line-hidden': isApprovedActor || isApprovalActor}"
                />

                <div class="roles-container-item__footer--right">
                    <template v-if="isApprovedActor || isApprovalActor">
                        <span>{{role.attributes.status === 10 ? 'Approved' : 'Approval'}} actor</span>
                    </template>
                    <template v-else>
                        <span>
                            {{ this.role.attributes.applications_count + ' Applications' }}
                        </span>
                        <span v-if="role.attributes.new_applications" class='applications-count'>
                            {{ '+' + this.role.attributes.new_applications }}
                        </span>
                    </template>
                </div>
            </div>
            <div v-else class="roles-container-item__no-applications" :style="deletedApplications ? '' : 'text-align: center;'">
                <img v-if="deletedApplications" width="34" height="34" src="@/assets/images/ellipse.svg" />
                <span :style="deletedApplications ? 'margin-left: 90px;' : ''">NO applications</span>
            </div>
        </div>
        <div class="hint-role">
            <div>open casting dashboard</div>
        </div>
        <role-creating-modal
            v-if="showModal"
            :project-id="$route.params.id"
            :roleId="role.id"
            @hide="showModal = false"
        />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import role from '@/api/role';
import RoleCreatingModal from '@/components/popups/role-creating/index'
import notify from '@/mixins/notify';

export default {
    name: 'RolesItem',
    mixins: [notify],
    components: {
        RoleCreatingModal
    },
    props: {
        role: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            getImageEdit: require('@/assets/images/edit.svg'),
            getImageLine: require('@/assets/images/short-line.svg'),
            getImageRoleAvatarPlaceholder: require('@/assets/images/role-avatar-placeholder.svg'),
            getImageVerticalLine: require('@/assets/images/vertical-line.svg'),
            getImageDotsPlaceholder: require('@/assets/images/dots-placeholder.svg'),
            getImageSmallVerticalLine: require('@/assets/images/small-vertical-line.svg'),
            getImageGreenLabel: require('@/assets/images/green-label.svg'),
            getImageBlackLabel: require('@/assets/images/black-label.svg'),
            getImageBlueLabel: require('@/assets/images/blue-label.svg'),
            getImagePurpleLabel: require('@/assets/images/purple-label.svg'),
            getImageRedLabel: require('@/assets/images/red-label.svg'),
            getImageLightPurpleLabel: require('@/assets/images/light-purple-label.svg'),

            hasBlue: false,
            darkColor: '#676e7c',
            lightColor: '#959fb2',
            showColorsLabels: false,
            showModal: false,
            colors: {
                green: {
                    dark_color: '#9CEE17',
                    light_color: '#D7FF67'
                },
                black: {
                    dark_color: '#42537D',
                    light_color: '#9CAACB'
                },
                blue: {
                    dark_color: '#00AACF',
                    light_color: '#90F2FF'
                },
                purple: {
                    dark_color: '#7B5391',
                    light_color: '#C9A3DE'
                },
                red: {
                    dark_color: '#E74A5C',
                    light_color: '#FF96A2'
                },
                pink: {
                    dark_color: '#CB00CF',
                    light_color: '#E290FF'
                }
            }
        }
    },
    computed: {
        ...mapGetters(['getProject']),
        isSeveralActors() {
            return this.role.attributes.applications_count > 1
        },
        isReviewActor() {
            return this.role.attributes.status === 0
        },
        isApprovedActor() {
            return this.role.attributes.status === 10
        },
        isApprovalActor() {
            return this.role.attributes.status === 11
        },
        iaAuditionActor() {
            return this.role.attributes.status === 40
        },
        getFullName() {
            return `${this.role.attributes.applications[0].actor.first_name}
                ${this.role.attributes.applications[0].actor.last_name}`
        },
        deletedApplications() {
            return this.role.attributes.deleted_applications_count > 0
        }
    },
    methods: {
        roleDetails() {
            if (this.hasBlue) {
                this.hasBlue = false
                this.showColorsLabels = false
                return // exit
            }

            this.$router.push(`/projects/${this.getProject.id}/roles/${this.role.id}`)
        },
        getAvatar(application) {
            if (application?.actor?.headshots && application.actor.headshots.length) {
                return application.actor.headshots[0].location;
            }

            return this.getImageRoleAvatarPlaceholder;
        },
        async setBorderColor(color) {
            try {
                await role.setBorderColor(
                    this.$route.params.id,
                    this.role.id,
                    this.colors[color]
                )

                this.darkColor = this.colors[color].dark_color
                this.lightColor = this.colors[color].light_color

            } catch (err) {
                return err
            }
        },
        getSubTitle() {
            const { attributes: { acting_gender_values, min_age, max_age, ethnicity } } = this.role

            let title = acting_gender_values?.join(', ') ?? ''

            title += min_age !== null && !!max_age ? `${title ? ', ' : ''}${min_age} - ${max_age}` : ''

            title += !Array.isArray(ethnicity) 
                ? min_age !== null || acting_gender_values?.length ? `, ${ethnicity.name}` : ethnicity.name
                : ''

            return title
        }
    },
    created() {
        if (this.role.attributes.label_range_color !== null) {
            this.darkColor = this.role.attributes.label_range_color.dark_color
            this.lightColor = this.role.attributes.label_range_color.light_color
        }
    }
}
</script>
