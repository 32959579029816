import axiosIns from '@/libs/axios'

export default {
    getGenres() {
        return axiosIns.get('/genres')
    },
    getProjectTypes() {
        return axiosIns.get('/project-types')
    },
    getEthnicity() {
        return axiosIns.get('/ethnicities')
    },
    getPersonSkills() {
        return axiosIns.get('/personal-skills')
    },
    getMaterialTypes() {
        return axiosIns.get('/material-types')
    },
    getCurrency() {
        return axiosIns.get('/currencies')
    },
    getPaymentTypes() {
        return axiosIns.get('/payment-types')
    }
}