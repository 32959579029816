import axiosIns from '@/libs/axios'

export default {
    createAudition(application_id, data) {
        return axiosIns.post(`/applications/${application_id}/audition-request`, data)
    },
    updateAudition(application_id, data) {
        return axiosIns.post(`/applications/${application_id}/audition-request`, data)
    },
    getAudition(application_id) {
        return axiosIns.get(`/applications/${application_id}/audition-request`)
    }
}