import axiosIns from '@/libs/axios'

export default {
   createRoleMaterial(id, data) {
       return axiosIns.post(`/role-materials/${id}/role`, data)
   },
   deleteRoleMaterial(id) {
       return axiosIns.delete(`/role-materials/${id}`)
   },
   updateRoleMaterial(id, data) {
       return axiosIns.post(`/role-materials/${id}/update`, data)
   }
}