import axiosIns from '@/libs/axios'

export default {
    getNotifications() {
        return axiosIns.get('/user-notifications')
    },
    getStatusAboutNewNotifications() {
        return axiosIns.get('/user-notifications/status-about-new-notifications')
    },
    readNotification(notification_id) {
        return axiosIns.get(`/user-notifications/${notification_id}/mark-as-read`)
    }
}