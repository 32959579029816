import axiosIns from '@/libs/axios'

export default {
    getRole(project_id, role_id) {
        return axiosIns.get(`/projects/${project_id}/roles/${role_id}`)
    },
    getRoles(project_id) {
        return axiosIns.get(`/projects/${project_id}/roles`)
    },
    createRole(project_id, data) {
        return axiosIns.post(`/projects/${project_id}/roles`, data)
    },
    updateRole(project_id, role_id, data) {
        return axiosIns.post(`/projects/${project_id}/roles/${role_id}`, data)
    },
    setBorderColor(project_id, role_id, data) {
        return axiosIns.post(`/projects/${project_id}/roles/${role_id}/label-color-range`, data)
    },
    renewDynamicLink(role_id) {
        return axiosIns.get(`/roles/${role_id}/renew-dynamic-link`)
    }
}