<template>
	<b-modal 
        id="give-role" 
        size="lg"
        centered 
        footer-class="justify-content-center footer-pop-up"
        header-class="header-pop-up"
        body-class="body-pop-up"
        content-class="content-pop-up"
        no-close-on-esc
        no-close-on-backdrop
    >
        <template #modal-header>
            <div>Give the role</div>
            <button @click="$bvModal.hide('give-role')">
                <img src="@/assets/images/close.png" alt="close" />
            </button>
        </template>
        <template #default>
            <div class="body-pop-up-modal">Are you sure you want to give a role to this Actor?</div>
        </template>
        <template #modal-footer>
            <button class="btn-continue btn-modal-give-role" @click="giveRole">Yes</button>
        </template>
    </b-modal>
</template>

<script>
import application from '@/api/application';
import notify from '@/mixins/notify';

export default {
    mixins: [notify],
    methods: {
        giveRole() {
            application.giveRole(this.$route.params.applicationId).then(() => {
                this.$bvModal.hide('give-role');
                this.$store.dispatch('GET_APPLICATION', { role_id: localStorage.getItem('role_id'), application_id: this.$route.params.applicationId });
            })
        }
    }
}
</script>

<style>
    .btn-modal-give-role {
        background: #233430;
        border-color: #1c6d37;
        width: 220px;
    }
    .btn-modal-give-role:hover {
        background: #2f3c33;
    }
</style>