import axiosIns from '@/libs/axios'

export default {
    getMe() {
        return axiosIns.post('/user/me')
    },
    register(data) {
        return axiosIns.post('/user/register', data)
    },
    login(data) {
        return axiosIns.post('/user/login', data)
    },
    logout() {
        return axiosIns.post('/user/logout')
    },
    forgotPassword(data) {
        return axiosIns.post('/user/forgot-password', data)
    },
    checkResetPasswordCode(data) {
        return axiosIns.post('/user/check-reset-password-code', data)
    },
    resetPassword(data) {
        return axiosIns.put('/user/reset-password', data)
    },
    changePassword(data) {
        return axiosIns.put('/user/change-password', data)
    },
    uploadAvatar(data) {
        return axiosIns.put('/user/upload-avatar', data)
    }
}