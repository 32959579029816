<template>
	<b-modal 
        id="media-modal" 
        size="lg"
        centered 
        footer-class="footer-pop-up-media footer-pop-up"
        header-class="header-pop-up"
        body-class="body-pop-up-media-modal"
        content-class="content-pop-up"
        no-close-on-esc
        no-close-on-backdrop
        hide-header
    >
        <template #default>
            <div class="body-pop-up-media">
                <button @click="$bvModal.hide('media-modal')">
                    <img src="@/assets/images/close.png" alt="close" />
                </button>
                <Media
                    :kind="'video'"
                    :muted="(false)"
                    :src="[media.mp4]"
                    :poster="media.thumbnail"
                    :controls="true"
                    :loop="true"
                />
            </div>
        </template>
        <template #modal-footer>
            <div>{{media.media_description }}</div>
        </template>
    </b-modal>
</template>

<script>
import Media from '@dongido/vue-viaudio'

export default {
    components: {
        Media
    },
    props: {
        media: {
            default: () => {},
            type: Object
        }
    }
}
</script>

<style>
    .body-pop-up-media-modal {
        padding: 0 !important;
    }
    .body-pop-up-media {
        text-align: center;
    }
    .body-pop-up-media video {
        width: 100%;
        object-fit: cover;
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
        border-radius: 24px 24px 0 0;
    }
    .body-pop-up-media button {
        position: absolute;
        right: 10px;
        background: transparent;
        top: 5px;
        border: none;
        z-index: 1;
    }
    .footer-pop-up-media {
        text-align: justify;
        padding-bottom: 15px !important;
        word-break: break-all;
    }
    .footer-pop-up-media div {
        width: 100%;
    }
</style>