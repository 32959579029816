<template>
	<b-modal 
        id="create-project" 
        size="lg"
        centered 
        footer-class="justify-content-center footer-pop-up"
        header-class="header-pop-up"
        body-class="body-pop-up"
        content-class="content-pop-up"
        no-close-on-esc
        no-close-on-backdrop
    >
        <template #modal-header>
            <div>{{isEdit ? "Edit Project" : "Project Creation"}}</div>
            <button @click="closeModal">
                <img src="@/assets/images/close.png" alt="close" />
            </button>
        </template>
        <template #default>
            <div :class="['modal-col1', {picture: form.placeholder}]" @click="addPicture">
                <img v-show="form.placeholder" id="picture" class="image-project" :src="form.placeholder" />
                <div class="upload-picture"></div>
                <div class="choose-placeholder">
                    <button @click="addPicture('upload')">
                        <img src="@/assets/images/upload.png" alt="upload" />
                        Upload Picture
                    </button>
                    <!-- <button @click="addPicture('placeholder')">
                        <img src="@/assets/images/choose_placeholder.png" alt="choose placeholder" />
                        Choose Placeholder
                    </button> -->
                </div>
            </div>
            <div class="modal-col2">
                <div class="pr-i">
                    <label>
                        <transition name="fade-transform">
                            <span v-if="form.name" class="modal-hint">Film name</span>
                        </transition>
                        <input 
                            v-model="form.name"
                            placeholder="Film name" 
                        />
                    </label>
                </div>
                <div class="modal-textarea" style="position: relative;">
                    <transition name="fade-transform">
                        <div v-if="form.description" class="modal-hint">Project Description</div>
                    </transition>
                    <b-form-textarea
                        v-model="form.description"
                        autocomplete="off"
                        type="text"
                        placeholder="Project Description"
                        class="project-description"
                        no-resize
                        maxlength="5000"
                    />
                    <span>{{form.description.length}}/5000</span>
                </div>
                <div class="datepicker">
                    <date-picker
                        v-model="form.startDate"
                        format="D MMMM, YYYY"
                        placeholder="Production start date"
                        lang="en"
                        :popup-style="{'z-index': 1050}"
                    />
                    <img src="@/assets/images/line.png" alt="line" style="margin: 0 8px;"/>
                    <date-picker
                        v-model="form.deadline"
                        format="D MMMM, YYYY"
                        placeholder="Expected launch date"
                        lang="en"
                        :popup-style="{'z-index': 1050}"
                        :disabled-date="disabledDate"
                    />
                </div>
                <div class="project-select">
                    <Multiselect 
                        v-model="form.genre" 
                        :options="genres" 
                        :searchable="false" 
                        :close-on-select="true" 
                        :show-labels="false" 
                        placeholder="Genre" 
                        style="margin-right: 30px;"
                        label="name"
                        class="project-multiselect"
                        @select="onChange"
                    />
                    <Multiselect 
                        v-model="form.type" 
                        :options="projectTypes" 
                        :searchable="false" 
                        :close-on-select="true" 
                        :show-labels="false" 
                        placeholder="Project Types" 
                        label="name"
                        class="project-multiselect"
                    />
                </div>
            </div>
        </template>
        <template #modal-footer>
            <button 
                :class="['btn-continue', {'btn-inactive': !active}]" 
                style="width: 220px;"
                @click="createProject" 
            >
                {{isEdit ? "Save changes" : "Create Project"}}
            </button>
        </template>
    </b-modal>
</template>

<script>
import 'vue2-datepicker/index.css';
import Multiselect from 'vue-multiselect';
import { mapGetters } from 'vuex';
import notify from '@/mixins/notify';
import moment from 'moment';
import project from '@/api/project';
import other from '@/api/other';

export default {
    mixins: [notify],
    components: { 
		Multiselect
	},
    props: {
        isEdit: {
            default: false,
            type: Boolean
        },
        currentProject: {
            default: () => {},
            type: Object
        }
    },
    data () {
        return {
            form: {
                name: '',
                description: '',
                placeholder: null,
                startDate: null,
                deadline: null,
                genre: null,
                type: null
            },
            genres: [],
            projectTypes: [],
            active: false,
            file: null
        }
    },
    created() {
        other.getGenres().then((res) => { this.genres = res.data; });
        other.getProjectTypes().then((res) => { this.projectTypes = res.data; })
    },
    computed: {
		...mapGetters(['getMe'])
	},
    watch: {
        form: {
            handler(form) {
                if (form.name.length >= 2 && form.description.length > 5 && form.placeholder && form.startDate && form.deadline && form.genre && form.type) this.active = true; 
                else this.active = false; 
            },
            deep: true
        },
        isEdit(v) {
            if (v)
                this.form = {
                    name: this.currentProject.name || this.currentProject.attributes.name,
                    description: this.currentProject.description || this.currentProject.attributes.description,
                    placeholder: this.currentProject.placeholder || this.currentProject.attributes.placeholder,
                    startDate: new Date(this.currentProject.start_date || this.currentProject.attributes.start_date),
                    deadline: new Date(this.currentProject.deadline || this.currentProject.attributes.deadline),
                    genre: this.currentProject.genre || this.currentProject.attributes.genre,
                    type: this.currentProject.project_type || this.currentProject.attributes.project_type
                };
        }
    },
    methods: {
        addPicture(v) {
            if (this.form.placeholder || v === 'upload') {
                const input = document.createElement('input');
                input.type = 'file';

                input.onchange = e => {
                    this.file = e.target.files[0];
                    document.getElementById("picture").src = URL.createObjectURL(this.file);

                    var reader = new FileReader();
                    if (this.file) reader.readAsDataURL(this.file); 
                    reader.onloadend = () => {
                        this.form.placeholder = reader.result;  
                    }
                }
            
                input.click();
            }
        },
        cleanForm() {
            this.form = {
                name: '',
                description: '',
                placeholder: null,
                startDate: null,
                deadline: null,
                genre: null,
                type: null
            }
            this.file = null;
        },
        closeModal() {
            this.$bvModal.hide('create-project');
            this.cleanForm();
            this.$emit('undoEdit', false);
            this.file = null;
        },
        createProject() {
            if (this.active) {
                let form = {
                    name: this.form.name,
                    description: this.form.description,
                    start_date: moment(this.form.startDate).format('YYYY-MM-DD'),
                    deadline: moment(this.form.deadline).format('YYYY-MM-DD'),
                    genre_id: this.form.genre.id,
                    project_type_id: this.form.type.id,
                    status: '1',
                    user_id: this.getMe.id
                }

                if (!this.form.placeholder.includes('https')) form = {...form, placeholder: this.form.placeholder};
                else if (this.form.placeholder.includes('genres')) form = {...form, placeholder: null}

                if (this.isEdit) {
                    project.updateProject(form, this.currentProject.id).then(() => {
                        this.$emit('undoEdit', false);
                        this.$store.dispatch('GET_PROJECT', this.currentProject.id);
                        this.success("updated");
                    });
                }else {
                    project.createProject(form).then(() => {
                        this.success("created");
                    });
                }
            }else {
                this.notifyWarning("Please fill in all fields.");  
            }
        },
        disabledDate(date) {
            const today = new Date(moment().subtract(1, 'day'));
            if (this.form.startDate) return date <= this.form.startDate || date <= today;
            return date <= today;
        },
        success(message) {
            this.notifySuccess(`The project has been successfully ${message}.`); 	
            this.cleanForm();
            this.$bvModal.hide('create-project');
            this.$store.dispatch('GET_PROJECTS', 1);
        },
        onChange(genre) { 
            if (!this.file && !this.isEdit || this.isEdit && this.form.placeholder.includes('genres')) {
                this.form.placeholder = genre.placeholder;
            }
        }
    }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style>
    #create-project {
        font-family: 'Jost';
        font-size: 16px;
        font-weight: 300;
    }
    .header-pop-up div {
        font-size: 21px;
        width: 100%;
        text-align: center;
        padding-left: 32px;
    }
    .header-pop-up img {
        height: 16px;
        width: 16px;
    }
    .content-pop-up {
        background: #252F44 !important;
        border-radius: 24px !important;
        width: 145% !important;
        line-height: 1.5;
    }
    .header-pop-up, .footer-pop-up {
        border: none !important;
        color: #FFFFFF;
    }
    .header-pop-up button {
        background: transparent;
        border: none;
        position: relative;
        bottom: 14px;
    }
    .body-pop-up {
        display: flex;
        text-align: center;
        border: 0.2px solid #6e6e6e6b;
        margin: 0 30px;
        border-radius: 24px;
        border-right-style: none;
        border-left-style: none;
        padding: 30px !important;
    }
    .modal-col1 {
        width: 284px;
        height: 482px;
        border: 1px dashed #596171;
        border-radius: 24px;
        margin-right: 25px;
    }
    .picture {
        border-style: solid;
    }
    .modal-textarea, .datepicker {
        margin: 24px 0;
    }
    .modal-textarea {
        border: 0.5px solid rgba(139, 136, 136, 0.5);
        border-radius: 24px;
        background: #2f384b;
        padding: 10px 15px 5px;
    }
    .modal-textarea span {
        position: relative;
        left: 255px;
        color: rgba(255, 255, 255, 0.4);
        font-size: 14px;
    }
    .project-description {
        height: 240px;
        padding: 0 !important;
        background: #2f384b !important;
        color: white !important;
        border: none !important;
    }
    .project-description:focus{
        box-shadow: none !important;
        border-color: rgba(175, 172, 172, 0.8) !important;
    }
    .project-description::placeholder {
        color: rgba(255, 255, 255, 0.4) !important;
        font-weight: 300;
    }
    .datepicker > .mx-datepicker {
        width: 284px;
    }
    .datepicker > .mx-datepicker > .mx-input-wrapper > .mx-input {
        height: 44px;
        padding-left: 18px;
        font-size: 15.5px;
    }
    .project-select {
        display: flex;
    }
    .project-multiselect > .multiselect__tags {
        width: 284px;
        height: 44px;
        padding-left: 18px;
    }
    .project-multiselect > .multiselect__select {
        left: 240px;
    }
    .project-multiselect > .multiselect__content-wrapper {
        width: 284px;
    }
    .project-multiselect > .multiselect__tags > .multiselect__single { 
        padding: 3px;
    }
    .upload-picture {
        height: 346px;
        background: #2f384b;
        border-radius: 24px 24px 0 0;
        background-image: url("../../../assets/images/movie_poster.png");
        background-repeat: no-repeat;
        background-position: center center;
    }
    .choose-placeholder {
        height: 136px;
        border-top: 1px dashed #596171;
        background: rgba(255, 255, 255, 0.07);
        border-radius: 0 0 24px 24px; 
    }
    .choose-placeholder button {
        width: 246px;
        height: 40px;
        background: rgba(161, 161, 161, 0.07);
        border: 0.5px solid rgba(139, 136, 136, 0.5);
        border-radius: 24px;
        text-align: left;
        color: white;
        margin: 18px 0 0;
        padding-left: 18px;
    }
    .choose-placeholder img {
        position: relative;
        bottom: 2px;
        margin-right: 5px;
    }
    .choose-placeholder button:hover {
        color: rgba(255, 255, 255, 0.7);
    }
    .image-project {
        object-fit: cover;
        width: 280px;
        height: 480px;
        position: absolute;
        border-radius: 24px;
        left: 32px;
        z-index: 1;
    }
    .modal-hint {
        position: absolute;
        top: -10px;
        left: 410px;
        font-size: 14px;
        width: 150px;
        border: 0.5px solid rgba(139, 136, 136, 0.5);
        border-radius: 16px;
        background: #2f384b;
        color: white;
    }
    @media (min-width: 992px) {
        #create-project .modal-lg {
            --bs-modal-width: 1030px !important;
        }
    }
</style>