import Cookies from 'js-cookie';
import user from '@/api/user';
import userNotification from '@/api/userNotification';

const state = {
    me: [],
    notifications: []
}

const getters = {
    getMe: state => state.me,
    getNotifications: state => state.notifications
}

const mutations = {
    SET_ME(state, payload) { state.me = payload },
    SET_NOTIFICATIONS(state, payload) { state.notifications = payload }
}

const actions = {
    async REGISTER({commit}, formData) {
        const res = await user.register(formData)
        const {access_token, refresh_token, data} = res.data[0]

        Cookies.set('access_token', access_token)
        Cookies.set('refresh_token', refresh_token)

        commit('SET_ME', data)
    },
    async LOGIN({commit}, formData) {
        const res = await user.login(formData)
        const {access_token, refresh_token, data} = res.data[0]

        Cookies.set('access_token', access_token)
        Cookies.set('refresh_token', refresh_token)
        Cookies.set('remember_me', formData.remember_me)

        commit('SET_ME', data)
    },
    async LOGOUT ({commit}) {
        if (!!Cookies.get('access_token') && !!Cookies.get('refresh_token')) {
            await user.logout()
        }

        commit('SET_ME', [])

        Cookies.remove('access_token')
        Cookies.remove('refresh_token')
    },
    GET_ME({commit}) {
        return user.getMe().then((me) => {
            commit('SET_ME', me.data);
            return me.data;
        })
    },
    GET_NOTIFICATIONS({commit}) {
        return userNotification.getNotifications().then(notifications => {
            commit('SET_NOTIFICATIONS', notifications.data.data);
            return notifications.data.data;
        })
    }
}
export default {
    state,
    getters,
    mutations,
    actions
}