<template>
    <div class="wrapper-portfolio" v-if="setApplication">
        <!-- Body -->
        <div class="portfolio-body">
            <ApplicationStatus v-if="[10, 11, 40].includes(application.status)" />
            <RecommendRole v-if="application.status === 60" />
            <SelfTape v-if="application.status === 50" />
            <div class="portfolio-info-wrapper">
                <!-- Info -->
                <div class="portfolio-info">
                    <div class="portfolio-info__header">
                        <div class="portfolio-info__avatar">
                            <!-- img -->
                            <div class="portfolio-info__avatar-img">
                                <img v-if="getHeadshot()" :src="getHeadshot()" alt="headshot" />
                            </div>
                        </div>
                        <div class="portfolio-info__title">
                            <span>
                                {{ application.actor.first_name + ' ' + application.actor.last_name + (application.actor.actorInfo.pseudonym ? ',' : '') }}
                            </span><br />
                            <span v-if="application.actor.actorInfo.pseudonym">{{ application.actor.actorInfo.pseudonym }}</span>
                        </div>
                        <span></span>
                    </div>

                    <div class="portfolio-info__details">
                        <div>Contact Details</div>
                        <span>
                            <img :src="getImagePhone" />
                            {{ application.actor.phone_number }}
                        </span>
                        <span class="portfolio-info__email">
                            <a :href="'mailto:' + application.actor.email">
                                <img :src="getImageEmail"/>
                            </a>
                            <span ref="actor-email" class="email-text">{{ application.actor.email }}</span>
                            <img :src="getImageClipboard" class="img-clipboard" @click="copyEmail" />
                        </span>
                        <span v-if="application.actor.actorInfo.city">
                            <img :src="getImageLocation" />
                            {{ application.actor.actorInfo.city }}
                        </span>
                    </div>

                    <div class="portfolio-info__media-presence">
                        <div>Media Presence</div>
                        <div class="portfolio-info__social-network">
                            <div class="portfolio-info__social-network-icons">
                                <img :class="['behance-icon', {'portfolio-info__disabled-social-network-icons': !application.actor.actorInfo.behance_link}]" width="25" height="25" :src="getImageBehance" />
                                <img :class="{'portfolio-info__disabled-social-network-icons': !application.actor.actorInfo.instagram_link}" width="25" height="25" :src="getImageInstagram" />
                                <img :class="{'portfolio-info__disabled-social-network-icons': !application.actor.actorInfo.youtube_link}" width="25" height="25" :src="getImageYoutube" />
                                <img :class="{'portfolio-info__disabled-social-network-icons': !application.actor.actorInfo.facebook_link}" width="25" height="25" :src="getImageFacebook" />
                            </div>
                            <div 
                                class="portfolio-info__social-network-titles">
                                <a :class="{'portfolio-info__disabled-social-network-titles': !application.actor.actorInfo.behance_link}" :href="application.actor.actorInfo.behance_link" target="_blank">Behance</a>
                                <a :class="{'portfolio-info__disabled-social-network-titles': !application.actor.actorInfo.instagram_link}" :href="application.actor.actorInfo.instagram_link" target="_blank">Instagram</a>
                                <a :class="{'portfolio-info__disabled-social-network-titles': !application.actor.actorInfo.youtube_link}" :href="application.actor.actorInfo.youtube_link" target="_blank">YouTube</a>
                                <a :class="{'portfolio-info__disabled-social-network-titles': !application.actor.actorInfo.facebook_link}" :href="application.actor.actorInfo.facebook_link" target="_blank">Facebook</a>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Line -->
                <div class="portfolio-vertical-line">
                    <img :src="getImageVerticalLinePortfolio" />
                </div>
                <!-- Content -->
                <div class="portfolio-content">
                    <div class="portfolio-content__description" v-if="application.actor.actorInfo.bio">
                        <span>
                            {{ application.actor.actorInfo.bio }}
                        </span>
                    </div>
                    <div class="portfolio-content__tags" v-if="application.actor.personal_skills.length">
                        <span
                            v-for="skill in application.actor.personal_skills"
                            :key="skill.id"
                            :class="{'active': application.matches_found.personal_skills.includes(skill.id)}"
                        >
                            {{ skill.name }}
                        </span>
                    </div>
                    <!-- Images -->
                    <div class="portfolio-content__wrapper-media">
                        <div
                            class="portfolio-content__media"
                            v-for="(item, index) in getMediaFiles" :key="index"
                        >
                            <div class="portfolio-content__media-type">{{item.media_type}}</div>
                            <Media
                                :kind="'video'"
                                :muted="(false)"
                                :src="[item.url]"
                                :poster="item.thumbnail"
                                :controls="item.mime_type === 'link' ? false : true"
                                :loop="true"
                                :style="{ cursor: item.mime_type === 'link' ? 'pointer' : '' }"
                                @click="link(item)"
                            /> 
                            <div :class="['portfolio-content__media-description', { 'portfolio-content__media-no-description': !item.media_description }]">
                                {{truncatedDescription(item.media_description)}}
                                <button v-if="isTruncated(item.media_description)" v-b-modal.media-modal @click="media = item">
                                    more
                                </button>
                            </div>
                        </div>
                        <MediaModal :media="media" />
                    </div>

                    <div class="portfolio-content__personal-detail" v-if="showPersonalDetails">
                        <span>Personal Details</span>
                        <div class="portfolio-content__personal-detail-wrapper">
                            <div class="portfolio-content__personal-detail-item">
                                <div class="portfolio-content__personal-detail-header">
                                    <span>Ethnicity:</span>
                                    <span>{{ application.actor.actorInfo.ethnicity?.name }}</span>
                                </div>
                                <img :src="getImageAccept" v-if="application.matches_found.ethnicity" />
                                <img :src="getImageReject" v-else />
                            </div>

                            <img class="personal-detail-vertical-line" :src="getImageSmallVerticalLinePortfolio" />

                            <div class="portfolio-content__personal-detail-item">
                                <div class="portfolio-content__personal-detail-header">
                                    <span>Acting gender</span>
                                    <span>{{ getGender() }}</span>
                                </div>
                                <img :src="getImageAccept" v-if="application.matches_found.acting_gender" />
                                <img :src="getImageReject" v-else />
                            </div>
                            
                            <img class="personal-detail-vertical-line" :src="getImageSmallVerticalLinePortfolio" />

                            <div class="portfolio-content__personal-detail-item">
                                <div class="portfolio-content__personal-detail-header">
                                    <span>Acting age range</span>
                                    <div>
                                        <span>{{ application.actor.actorInfo.min_age }}</span>
                                        <span v-if="application.actor.actorInfo.min_age && application.actor.actorInfo.max_age">-</span>
                                        <span>{{ application.actor.actorInfo.max_age }}</span>
                                    </div>
                                </div>
                                <img :src="getImageAccept" v-if="application.matches_found.acting_age_range" />
                                <img :src="getImageReject" v-else />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Right side bar -->
        <div class="portfolio-right-sidebar">
            <div class="right-sidebar-header">
                <template v-if="[0, 30, 31].includes(application.status)">
                    <div :class="['btn-portfolio-right-wrapper btn-give-role', {'btn-give-role-inactive': [30, 31].includes(application.status)}]" 
                        @click="giveRoleWithoutAudition"
                    >
                        <div>Give role without audition</div>
                    </div>
                    <div :class="['btn-portfolio-right-wrapper btn-invite-to-audition', {'btn-invite-to-audition-inactive': [30, 31].includes(application.status)}]"
                        @click="application.status === 0 ? showAuditionInvitation = true : showAuditionInvitation = false"
                    >
                        <div>Invite to Audition</div>
                    </div>
                    <div :class="['btn-portfolio-right-wrapper btn-reject-application', {'btn-reject-application-inactive': [30, 31].includes(application.status)}]" 
                        @click="openModal"
                    >
                        <div>Reject Application</div>
                    </div>
                    <div :class="['btn-portfolio-right-wrapper btn-request-self-tape', {'btn-portfolio-right--black': application.status === 0}, {'btn-offer-different-recommend-role-inactive': [30, 31].includes(application.status)}]" 
                        @click="application.status === 0 ? showSelfTapeRequest = true : showSelfTapeRequest = false"
                    >
                        <div>Request Self-tape</div>
                    </div>
                    <div :class="['btn-portfolio-right-wrapper', {'btn-portfolio-right--black': application.status === 0}, {'btn-offer-different-recommend-role-inactive': [30, 31].includes(application.status)}]" 
                        @click="application.status === 0 ? showOfferDifferentRole = true : showOfferDifferentRole = false"
                    >
                        <div>Offer Different Role</div>
                    </div>
                </template>
                <template v-else-if="[60, 50, 51].includes(application.status)">
                    <div v-if="[50, 51].includes(application.status)" class="btn-portfolio-right-wrapper btn-give-role" @click="giveRole()">
                        <div>Give the Role</div>
                    </div>
                    <div class="btn-portfolio-right-wrapper btn-invite-to-audition" @click="showAuditionInvitation = true"><div>Invite to Audition</div></div>
                    <div v-if="application.status === 50" class="btn-portfolio-right-wrapper btn-offer-different-recommend-role-inactive"><div>Request Self-tape</div></div>
                    <div v-if="application.status === 60" class="btn-portfolio-right-wrapper btn-offer-different-recommend-role-inactive"><div>Offer Different Role</div></div>
                    <div class="btn-portfolio-right-wrapper btn-reject-application" @click="$bvModal.show('reject-application')"><div>Reject Application</div></div>
                </template>
                <template v-else>
                    <div 
                        :class="['btn-portfolio-right-wrapper btn-give-role', {'btn-give-role-inactive': application.status === 10 || application.status === 11}]"
                        @click="giveRole()"
                    >
                        <div>Give the Role</div>
                    </div>
                    <!-- <div class="btn-portfolio-right-wrapper btn-understudy"><div>Understudy</div></div> -->
                    <div 
                        :class="['btn-portfolio-right-wrapper btn-reject-application', {'btn-reject-application-inactive': application.status === 10 || application.status === 11}]" 
                        @click="rejectActor"
                    >
                        <div>Reject actor</div>
                    </div>
                </template>
            </div>
            <div class="right-sidebar-footer">
                <!-- <div v-if="application.status === 0" class="btn-portfolio-right-wrapper btn-portfolio-right--black"><div>Compare to role info</div></div> -->
                <div class="btn-portfolio-right-wrapper btn-portfolio-right--black" v-if="false"><div>Attach a note</div></div>
                <div class="btn-portfolio-right-wrapper btn-portfolio-right--black" v-if="false"><div>Write a message</div></div>
            </div>
        </div>
        <GiveRole />
        <RejectApplication />
        <OfferDifferentRole 
            v-if="showOfferDifferentRole"
            @hide="showOfferDifferentRole = false"
        />
        <audition-invitation
            v-if="showAuditionInvitation"
            @hide="showAuditionInvitation = false"
        />
        <SelfTapeRequest 
            v-if="showSelfTapeRequest"
            @hide="showSelfTapeRequest = false"
        />
    </div>
</template>

<script>

import Media from '@dongido/vue-viaudio'
import GiveRole from './components/GiveRole.vue'
import ApplicationStatus from './components/ApplicationStatus.vue'
import { mapGetters } from 'vuex'
import notify from '@/mixins/notify'
import RejectApplication from './components/RejectApplication.vue'
import OfferDifferentRole from './components/OfferDifferentRole.vue'
import RecommendRole from './components/RecommendRole.vue'
import AuditionInvitation from '@/components/popups/audition-invitation'
import { isEmpty, uniqBy } from 'lodash'
import SelfTapeRequest from './components/SelfTapeRequest.vue'
import SelfTape from './components/SelfTape.vue'
import MediaModal from './components/MediaModal.vue'
import selfTape from '@/api/selfTape'


export default {
    mixins: [notify],
    name: 'ActorPortfolio',
    components: {
        Media,
        GiveRole,
        ApplicationStatus,
        RejectApplication,
        OfferDifferentRole,
        RecommendRole,
        AuditionInvitation,
        SelfTapeRequest,
        SelfTape,
        MediaModal
    },
    data() {
        return {
            getImageVerticalLinePortfolio: require('@/assets/images/Rectangle3798.png'),
            getImagePhone: require('@/assets/images/phone.svg'),
            getImageEmail: require('@/assets/images/email.svg'),
            getImageLocation: require('@/assets/images/location.svg'),
            getImageInstagram: require('@/assets/images/instagram.svg'),
            getImageYoutube: require('@/assets/images/youtube.svg'),
            getImageFacebook: require('@/assets/images/facebook.svg'),
            getImageAccept: require('@/assets/images/accept.svg'),
            getImageReject: require('@/assets/images/reject.svg'),
            getImageClipboard: require('@/assets/images/clipboard.svg'),
            getImageSmallVerticalLinePortfolio: require('@/assets/images/small-vertical-line-portfolio.svg'),
            getImageBehance: require('@/assets/images/behance.svg'),

            showAuditionInvitation: false,
            showOfferDifferentRole: false,
            showSelfTapeRequest: false,
            setApplication: false,
            media: {},
            selftapeProvidedVideo: []
        }
    },
    created() {
        EventBus.$on('setApplication', () => { this.setApplication = true; })
        this.$store.commit('SET_ROLE_NAME', '')

        selfTape.getSelfTape(this.$route.params.applicationId).then(res => {
            this.selftapeProvidedVideo = res.data.provided_selftapes;
        })
    },
    methods: {
        copyEmail() {
            navigator.clipboard.writeText(this.$refs['actor-email'].innerText)
                .then(() => {
                    this.notifySuccess('Email copied: <br> <strong>' + this.application.actor.email + '</strong>');
                })
                .catch(() => {
                    this.notifyError('Something went wrong')
                })
        },
        getGender() {
            let actingGenderOptions = {
                0: 'Male',
                1: 'Female',
                2: 'Other'
            }
            
            return actingGenderOptions[
                this.application.actor.actorInfo.acting_gender[0]
            ];
        },
        openModal() {
            if (this.application.status === 0) this.$bvModal.show('reject-application');
        },
        giveRole() {
            if ([40, 50, 51].includes(this.application.status) ) {
                this.$bvModal.show('give-role')
            } else if (this.application.status === 10 || this.application.status === 11) {
                this.$router.push(`/projects/${localStorage.getItem('project_id')}/roles/${localStorage.getItem('role_id')}`)
            }
        },
        giveRoleWithoutAudition() {
            if (this.application.status === 0) {
                const approvedActor = this.roleApplications.find(item => item.attributes.status === 10 || item.attributes.status === 11);
                if (approvedActor) this.notifyInfo("You can't give a role to this application. You already have an approved actor.");
                else this.$bvModal.show('give-role');
            }
        },
        rejectActor() {
            if (this.application.status === 40 ) this.$bvModal.show('reject-application');
        },
        isTruncated(str) {
            return str ? str.split(" ").join("").length > 32 : false
        },
        truncatedDescription(str) {
            return str ? (this.isTruncated(str) ? str.slice(0, 32) + '...' : str) : 'No description'
        },
        getHeadshot() {
            if (this.application?.actor?.headshots && this.application.actor.headshots.length) {
                return this.application.actor.headshots[0].location;
            }

            return null;
        },
        link(item) {
            if (item.mime_type === 'link') window.open(item.url, '_blank');
        }
    },
    computed: {
        ...mapGetters({
            application: 'getApplication',
            roleApplications: 'getRoleApplications',
            role: 'getRole'
        }),
        getMediaFiles() {
            const mediaFiles = [];

            const media = (media) => {
                return {
                    'id': media.id,
                    'url': media.location,
                    'mime_type': media.mime_type,
                    'thumbnail': media.mime_type === 'link' ? require('@/assets/images/link.png') : media.payload.thumbnail,
                    'media_type': media.attachment_type.name,
                    'media_description': media.description
                };
            };

            if (this.application.actor.video) mediaFiles.push(...this.application.actor.video.map(media));

            if (this.selftapeProvidedVideo?.length) mediaFiles.push(...this.selftapeProvidedVideo.map(media));

            return uniqBy(mediaFiles, 'id');
        },
        showPersonalDetails() {
            return !isEmpty(this.application.actor.actorInfo)
        }
    }
}
</script>

<style lang="scss">
    @import '@/assets/styles/pages/actor-portfolio.sass';
</style>