<template>
    <div class="logo-container">
        <router-link :to="auth ? '/active/projects' : '/'" class="logo">
            <img src="../assets/images/logo.png" alt="Logo">
        </router-link>
    </div>
</template>

<script>

export default {
    props: {
        auth: {
            type: Boolean,
            required: false
        }
    }
	
}
</script>

<style>
    .logo-container {
        margin-left: 36px;
    }
</style>