<template>
	<div class="auth-container">
		<HeaderComponent />
		<div class="auth-content">
			<div class="pfl" :style="signup ? active ? activeCamera : camera : pfl"></div>
			<div class="auth-form">
				<SwitchAuth v-if="!forgotPassword" @switch="switchAuth" />
				<router-view @activeRegister="activeRegister"/>
			</div>
			<div class="pfr" :style="signup ? active ? activeChair : chair : pfr"></div>
		</div>
		<SpinnerEpic />
    </div>
</template>

<script>
import SpinnerEpic from '../components/SpinnerEpic.vue';
import HeaderComponent from '../components/HeaderComponent.vue';
import SwitchAuth from '../components/SwitchAuth.vue';

export default {
	components: {
		SpinnerEpic,
		HeaderComponent,
		SwitchAuth
	},
	data() {
		return {
			signup: localStorage.getItem('switch') === 'true',
			pfl: { backgroundImage: `url(${require('@/assets/images/pfl.png')})` },
			pfr: { backgroundImage: `url(${require('@/assets/images/pfr.png')})` },
			camera: { backgroundImage: `url(${require('@/assets/images/camera.png')})` },
			chair: { backgroundImage: `url(${require('@/assets/images/chair.png')})` },
			activeCamera: { backgroundImage: `url(${require('@/assets/images/camera_active.png')})` },
			activeChair: { backgroundImage: `url(${require('@/assets/images/chair_active.png')})` },
			active: false,
			forgotPassword: null
		}
	},
	watch: {
		$route: {
			handler(route) {
				this.forgotPassword = route.name === 'forgotPassword';
			},
			immediate: true
		}
	},
	methods: {
		switchAuth(checked) { 
			this.signup = checked; 
			if (this.signup) this.active = false;
		},
		activeRegister(active) { this.active = active; }
	}
}
</script>

<style>
	.auth-container {
		background-image: url("@/assets/images/background.png");
	}
	.auth-content {
		display: flex;
	}
	.pfl, .pfr {
		width: 20%;
		min-height: calc(100vh - 71px);
		background-repeat: no-repeat;
		background-size: 80%;
	}
	.pfl {
		background-position: left bottom;
	}
	.pfr {
		background-position: right bottom;
	}
	.auth-form {
		width: 60%;
		line-height: initial;
	}
	@media(max-width: 1820px) { 
		.pfl, .pfr {
			background-size: 99%;
		}
	}
</style>