export default {
    methods: {
        format(dateObj) {
            let year = dateObj.getFullYear()
            let month = (dateObj.getMonth() + 1)
            let day = dateObj.getDate()

            if (month < 10) { month = '0' + month }
            if (day < 10) { day = '0' + day }

            return year + '-' + month + '-' + day
        },
        fillMissingDates(startDate, endDate) {
            let minDate = startDate.getTime()
            let maxDate = endDate.getTime()

            let newDates = []
            let currentDate = minDate

            while (currentDate <= maxDate) {
                newDates.push(new Date(currentDate))
                currentDate += (24 * 60 * 60 * 1000) // add one day
            }

            return newDates
        },
        isValidDate(date) {
            return date instanceof Date && !isNaN(date)
        },
    }
}