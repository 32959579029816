<template>
    <div class="application-status">
        <div class="self-tape-wrapper">
            <div>Self-tape requested</div>
        </div>
        <button class="btn-recommend-role" style="margin: 7px 0px;" @click="showSelfTapeRequest = true">
            <img src="@/assets/images/pencil.svg" alt="edit" />
        </button>
        <SelfTapeRequest 
            v-if="showSelfTapeRequest"
            @hide="showSelfTapeRequest = false"
        />
    </div>
</template>

<script>
import SelfTapeRequest from './SelfTapeRequest.vue';

export default {
    components: {
        SelfTapeRequest
    },
    data() {
        return {
            showSelfTapeRequest: false
        }
    }
}
</script>

<style>
    .self-tape-wrapper {
        margin-bottom: 40px;
        color: white;
    }
    .self-tape-wrapper div {
        width: 400px;
        height: 50px;
        background: rgba(255, 255, 255, 0.03);
        border: 0.5px solid rgba(255, 255, 255, 0.1);
        border-radius: 24px;
        line-height: 47px;
        margin: 10px;
        font-size: 19px;
        padding: 0 12px;
        text-align: center;
    }
</style>