<template>
	<b-modal 
        id="reject-application" 
        size="lg"
        centered 
        footer-class="justify-content-center footer-pop-up"
        header-class="header-pop-up"
        body-class="body-pop-up body-pop-up-reject-application"
        content-class="content-pop-up"
        no-close-on-esc
        no-close-on-backdrop
    >
        <template #modal-header>
            <div>Reject Application</div>
            <button @click="$bvModal.hide('reject-application')">
                <img src="@/assets/images/close.png" alt="close" />
            </button>
        </template>
        <template #default>
            <div class="body-pop-up-modal body-pop-up-modal-reject-application">
                {{ application.actor.first_name + ' ' + application.actor.last_name }} will be informed about the rejection via the Boom App. <br />
                Are you sure you want to reject the application of this actor?
            </div>
        </template>
        <template #modal-footer>
            <button 
                class="btn-continue btn-modal-reject-application" :style="[0, 60, 50].includes(application.status) ? 'width: 220px;' : 'width: 270px;'" 
                @click="rejectApplication"
            >
                {{ [0, 60, 50].includes(application.status) ? 'Reject Application' : 'Reject actor & cancel audition' }}
            </button>
        </template>
    </b-modal>
</template>

<script>
import application from '@/api/application';
import notify from '@/mixins/notify';
import { mapGetters } from 'vuex';


export default {
    mixins: [notify],
    computed: {
        ...mapGetters({ application: 'getApplication'})
    },
    methods: {
        rejectApplication() {
            application.rejectApplication(this.application.id).then(() => {
                localStorage.setItem('tab', 5);
                this.$bvModal.hide('reject-application');
                this.$router.push(`/projects/${localStorage.getItem('project_id')}/roles/${localStorage.getItem('role_id')}`);
            })
        }
    }
}
</script>

<style>
    .btn-modal-reject-application {
        background: #3e2d3c;
        border-color: #E74A5C;
    }
    .btn-modal-reject-application:hover {
        background: #4a3548;
    }
    .body-pop-up-reject-application {
        padding: 80px 0 0 !important;
    }
    .body-pop-up-modal-reject-application {
        line-height: 1.6 !important;
    }
</style>