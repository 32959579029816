import application from '@/api/application';

const state = {
    roleApplications: [],
    application: {},
    roleName: ''
}

const getters = {
    getRoleApplications: state => state.roleApplications,
    getApplication: state => state.application,
    roleName: state => state.roleName,
}

const mutations = {
    SET_ROLE_APPLICATIONS(state, payload) { 
        const priorityStatus = { 10: 1, 11: 2, 40: 3, 50: 4, 60: 5, 0: 6 };
        state.roleApplications = payload.reduce((acc, application) => {
            const priority = priorityStatus[application.attributes.status] || 0;
            const newApplication = { ...application, attributes: { ...application.attributes, priority } };
            return [...acc, newApplication];
        }, []);
    },
    SET_APPLICATION(state, payload) { state.application = payload },
    SET_ROLE_NAME(state, payload) { state.roleName = payload },
}

const actions = {
    GET_ROLE_APPLICATIONS({commit}, id) {
        return application.getRoleApplications(id).then((applications) => {
            commit('SET_ROLE_APPLICATIONS', applications.data.data);
            return applications.data.data;
        })
    },
    GET_APPLICATION({commit}, data) {
        return application.getApplication(data.role_id, data.application_id).then((application) => {
            commit('SET_APPLICATION', application.data);
            EventBus.$emit('setApplication');
            return application.data;
        })
    }
}
export default {
    state,
    getters,
    mutations,
    actions
}