import project from '@/api/project';
import notify from '@/mixins/notify';

export default {
	mixins: [notify],
	extends: parent,
	props: {...parent.props},
	data() {
		return {
			isEdit: false,
			currentProject: {},
			activeProjects: null,
			archivedProject: null
		}
	},
	methods: { 
		remove(id) {
			project.deleteProject(id).then(() => {
				if (this.status) this.$store.dispatch('GET_PROJECTS', 1);
				else this.$store.dispatch('GET_PROJECTS', 2);
				this.$bvModal.hide('delete-project');
				this.notifySuccess("The project has been successfully deleted.");
			})
		},
		edit(project) {
			this.isEdit = true;
			this.currentProject = project;
			this.$root.$emit('editProject', true);
			this.$root.$emit('currentProject', project);
			this.$bvModal.show('create-project');
		},
		unEdit(v) { this.isEdit = v; },
		archive(id) {
			if (this.status) {
				project.archiveProject(id).then(() => {
					if (!this.archivedProject && !this.activeProjects) {
						this.success(2, "archive");
						this.$router.push("/archived/projects");
					}else {
						this.success(1, "archive");
					}
				})
			}else {
				project.unArchiveProject(id).then(() => {
					this.success(2, "unarchive");
					this.$router.push("/active/projects");
				})
			}
		},
		success(status, message) {
			this.$store.dispatch('GET_PROJECTS', status);
			this.notifySuccess(`The project has been successfully ${message}.`);
		}
	}
}