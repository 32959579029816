<template>
    <div>
        <div class="a-name">{{getMe.first_name}} {{getMe.last_name}}</div>
        <div class="a-company">{{getMe.company_name}}</div>
        <div class="a-set"><img src="@/assets/images/setting.png" alt="setting" />
            <router-link to="" v-on:click.native="$refs.accountSettings.showModal()">Account Settings</router-link>
            <AccountSettingsModal ref="accountSettings" />
        </div>
        <div class="a-set" v-b-modal.notifications>
            <img src="@/assets/images/notification.png" alt="notification" />
            <div v-if="newNotifications.length" class="new-notifications"></div>
            <router-link to="">Notifications</router-link>
            <UserNotification />
        </div>
        <div class="create-new-project">
            <button class="btn-action btn-add-role-project" v-b-modal.create-project>
                <img src="@/assets/images/plus.png" alt="edit" width="15" height="15" />
            </button>
           <span>Create new project</span>
        </div>
        <CreateProject :is-edit="isEdit" :current-project="currentProject" @undoEdit="unEdit" />
        <div class="footer af">
            <hr>
            <div class="a-set" style="margin-top: 55px;"><img src="../assets/images/help.png" alt="help" />
                <a href="https://www.boompp.com/contact/" target="_blank">Help & Support</a>
            </div>
            <div class="a-set"><img src="../assets/images/book.png" alt="policy" />
                <a href="https://www.boompp.com/terms_ppsuite/" target="_blank">Terms & Privacy Policy</a>
            </div>
            <div class="a-set"><img src="../assets/images/info.png" alt="info" />
                <a href="https://www.boompp.com/about/" target="_blank">About «Boom»</a>
            </div>
		</div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AccountSettingsModal from '@/components/popups/account-settings/index';
import UserNotification from '@/view/dashboard/components/UserNotification.vue';
import CreateProject from '@/view/dashboard/components/CreateProjects.vue';
import project from '@/mixins/project';

export default {
    mixins: [project],
    components: {
        AccountSettingsModal,
        UserNotification,
        CreateProject 
    },
    mounted() {
        this.$root.$on('editProject', res => { this.isEdit = res; })
        this.$root.$on('currentProject', res => { this.currentProject = res; })
    },
    computed: {
        ...mapGetters(['getMe', 'getNotifications']),
        newNotifications() { return this.getNotifications.filter(item => !item.attributes.is_read) }
    }
}
</script>

<style>
    .a-name {
        font-size: 20px;
        color: white;
        word-break: break-all;
    }
    .a-company, .a-set a {
        margin-top: 11px;
        font-size: 16px;
        color: rgba(255, 255, 255, 0.7);
    }
    .a-set {
        margin-top: 30px;
        height: 25px;
        position: relative;
    }
    .a-set img {
        margin-right: 10px;
    }
    .a-set a {
        text-decoration: none;
        position: relative;
        top: 2px;
    }
    .a-set a:hover {
        color: rgba(255, 255, 255, 0.9);
    }
    .af {
        text-align: left !important;
        padding: 0 32px 0 32px;
        bottom: 250px !important;
        height: 0 !important;
    }
    .af button {
        border: none;
        width: 100%;
        background-color: transparent;
        color: white;
        font-size: 16px;
        background-image: url("@/assets/images/border.png");
        height: 48px;
        margin-bottom: 35px;
    }
    .af button:hover {
        color: rgba(255, 255, 255, 0.7);
    }
    .new-notifications {
        height: 8px;
        width: 8px;
        border-radius: 50%;
        position: absolute;
        top: 0px;
        left: 13px;
        background: #EE6E17;
    }
    .create-new-project {
        margin: 30px 0;
        color: white;
        font-size: 18px;
    }
    .create-new-project span {
        margin-left: 10px;
    }
</style>