<template>
    <div class="login">
        <div class="wb">
            <img src="@/assets/images/wb.png" alt="Welcome Back!" />
        </div>
        <div class="auth-text">
            Log in with your email address and password to access your projects. If you do <br/>
            not have an account, you can register by selecting 'Sign up' at the top of this page
        </div>
        <div :class="['pr-i', {'log': validationEmail}]">
            <label>
                <transition name="fade-transform">
                    <span v-if="form.email" class="input-hint">Personal email</span>
                </transition>
                <input 
                    v-model="form.email" 
                    type="email" 
                    placeholder="Personal email" 
                    :class="{'is-invalid': !validationEmail}"
                />
            </label>
            <div v-if="!validationEmail" class="invalid">
                <img src="@/assets/images/invalid.png" alt="invalid" />
                <span>Please enter a valid email address</span>
            </div>
        </div>
        <div class="pr-i">
            <label>
                <transition name="fade-transform">
                    <span v-if="form.password" class="input-hint">Password</span>
                </transition>
                <input 
                    v-model="form.password" 
                    :type="type"
                    placeholder="Password" 
                    :class="{'is-invalid': !validationPassword}"
                />
            </label>
            <div><button @click.prevent="togglePassword" :class="type === 'password' ? 'show-password' : 'hide-password'" /></div>
            <div v-if="!validationPassword" class="invalid invalid-password">
                <img src="@/assets/images/invalid.png" alt="invalid" />
                <span>
                    Please enter minimum eight characters, at least one uppercase <br />
                    letter, one lowercase letter, one number and one special character
                </span>
            </div>
        </div>
        <div class="rm-fp">
            <div class="container-checkbox-rm">
                <span class="checkbox-rm">
                    <input v-model="form.remember_me" type="checkbox" />
                    <svg><use xlink:href="#checkbox-remember-me" class="checkbox-rm"></use></svg>
                </span>
                <svg xmlns="http://www.w3.org/2000/svg" style="display:none">
                    <symbol id="checkbox-remember-me" viewBox="0 0 22 22">
                        <path fill="none" stroke="currentColor" d="M5.5,11.3L9,14.8L20.2,3.3l0,0c-0.5-1-1.5-1.8-2.7-1.8h-13c-1.7,0-3,1.3-3,3v13c0,1.7,1.3,3,3,3h13 c1.7,0,3-1.3,3-3v-13c0-0.4-0.1-0.8-0.3-1.2"/>
                    </symbol>
                </svg>
            </div>
            <span class="rm">remember me</span>
            <button @click="forgotPassword">forgot password?</button>
        </div>
        <div class="btn-auth">
            <button class="btn-continue" @click="login">Continue</button>
        </div>
    </div>
</template>

<script>
import auth from '@/mixins/auth';

export default {
    mixins: [auth],
    methods: {
        login() {
            if ( !this.validEmail()) this.validationEmail = false;
            if ( !this.validPassword()) this.validationPassword = false;
            if ( !this.validationEmail || !this.validationPassword ) return; 

            this.$store.dispatch('LOGIN', this.form)
                .then(() => {
                    localStorage.setItem('switch', false);
                    this.$router.push({ path: '/active/projects' }); 
                })
        },
        forgotPassword() { this.$router.push( { path:'/forgot/password' } ); }
    }
}
</script>

<style>
    /* Checkbox - Remember me */
    .container-checkbox-rm {
        display: inline;
    }
    #checkbox-remember-me {
        filter: invert(100%) sepia(91%) saturate(0%) hue-rotate(189deg) brightness(108%) contrast(108%);
    }
    .checkbox-rm {
        --dur: calc((var(--size, 2)/2) * 0.6s);
        display: inline-block;
        width: 16px;
        position: relative;
    }
    .checkbox-rm:after {
        content: "";
        width: 100%;
        padding-top: 100%;
        display: block;
    }
    .checkbox-rm > * {
        position: absolute;
    }
    .checkbox-rm input {
        -webkit-appearance: none;
        -moz-appearance: none;
        -webkit-tap-highlight-color: transparent;
        cursor: pointer;
        border-radius: 4px;
        border: 1px solid rgba(255, 255, 255, 0.4);
        background-color: #252F44;
        outline: none;
        margin: 0;
        padding: 0;
        transition: all calc(var(--dur) / 3) linear;
    }
    .checkbox-rm input:checked {
        transition-delay: calc(var(--dur) /1.3);
        background: #EE6E17;
    }
    .checkbox-rm input:checked + svg {
        --dashArray: 16 93;
        --dashOffset: 109;
    }
    .checkbox-rm svg {
        fill: none;
        left: 0;
        pointer-events: none;
        stroke: var(--stroke, var(--border-active));
        stroke-dasharray: var(--dashArray, 93);
        stroke-dashoffset: var(--dashOffset, 94);
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-width: 2px;
        top: 0;
        transition: stroke-dasharray var(--dur), stroke-dashoffset var(--dur);
    }
    .checkbox-rm svg,
    .checkbox-rm input {
        display: block;
        height: 100%;
        width: 100%;
    }
    /* // */
    .login {
        font-family: 'Jost';
        font-style: normal;
        font-weight: 400;
        position: relative;
    }
    .wb {
        margin: 200px 0 20px;
    }
    .wb, .auth-text, .pr-i, .rm-fp, .btn-auth {
        text-align: center;
    }
    .auth-text {
        font-size: 16px;
        color: white;
        margin-bottom: 50px;
    }
    .log {
        margin-bottom: 20px;
    }
    .pr-i input {
        width: 600px;
        height: 44px;
        background: #2f384b;
        border: 0.5px solid rgba(139, 136, 136, 0.5);
        border-radius: 48px;
        padding: 21px;
        color: white;
        font-weight: 400;
    }
    .pr-i input:focus{
        outline: none;
        border-color: rgba(175, 172, 172, 0.8);
    }
    .pr-i input::placeholder {
        font-weight: 300;
    }
    .pr-i input::placeholder, .rm, .rm-fp button {
        color: rgba(255, 255, 255, 0.4);
    }
    .rm {
        font-size: 14px;
        margin: 0 350px 0 10px;
        position: relative;
        bottom: 3px;
    }
    .rm-fp button {
        background: transparent;
        border: none;
        font-size: 14px;
    }
    .rm-fp button:hover {
        color: rgba(206, 189, 189, 0.4);
    }
    .btn-continue {
        width: 280px;
        height: 44px;
        background: #EE6E17;
        border: 1px solid #EE6E17;
        border-radius: 32px; 
        font-weight: 500;
        font-size: 16px;
        color: white;
    }
    .btn-continue:hover {
        background: #dc6211;
    }
    .btn-auth {
        margin-top: 40px;
    }
    .invalid {
        height: 30px;
        color: #E74A5C;
        font-size: 14px;
        margin-top: 5px;
        position: relative;
        right: 170px;
    }
    .is-invalid {
        border-color:  #E74A5C !important;
    }
    .invalid-password {
        right: 88px;
        bottom: 25px;
    }
    .invalid img {
        height: 14px;
        width: 14px;
    }
    .invalid span {
        margin: 10px;
        position: relative;
        top: 2px;
    }
    .pr-i button {
        position: relative;
        left: 275px;
        bottom: 35px;
        border: none;
        height: 24px;  
        width: 24px;
        background-repeat: no-repeat;
        background-position: center center;
    }
    .input-hint {
        width: 130px;
        padding: 0 20px;
        position: absolute;
        left: 430px;
        bottom: 32px;
        background: #2f384b;
        border-radius: 12px;
        font-size: 14px;
        border: 0.5px solid rgba(139, 136, 136, 0.5);
        color: white;
        text-align: center;
    }
    .show-password {
        background-image: url( '@/assets/images/show_password.png' );
    }
    .hide-password {
        background-image: url( '@/assets/images/hide_password.png' );
    }
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        transition: background-color 5000s ease-in-out 0s;
        caret-color: #fff;
        -webkit-text-fill-color: #fff !important;
    }
    @media(max-height: 860px) { 
		.wb {
			margin-top: 100px;
		}
	}
</style>
