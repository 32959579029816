import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './assets/styles/global.css';

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

// 3rd party
import '@/helper'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
// import 'element-ui/lib/theme-chalk/reset.css';
Vue.use(ElementUI);

import InputMask from 'inputmask';
const VueInputMask = {
  install(Vue) {
    Vue.directive('mask', {
      bind(el, binding) {
        InputMask(binding.value).mask(el);
      }
    })
  }
}
Vue.use(VueInputMask)

import DatePicker from 'vue2-datepicker'
DatePicker.install(Vue); 

Vue.config.productionTip = false;

window.EventBus = new Vue()

new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app')
