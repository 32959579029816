<template>
    <date-picker
        format="D MMMM, YYYY"
        :placeholder="placeholder"
        lang="en"
        @open="open"
        @close="setPlaceholder"
        ref="e-calendar"
        @calendar-change="changeCalendar"
    >
        <template v-if="hasHeaderSlot" #header>
            <slot name="header"></slot>
        </template>
        <template #content>
            <calendar-panel
                ref="calendar-panel"
                input-class="rounded w-1/2"
                calendar-class="rounded"
                :value="dates"
                :get-classes="getClasses"
                @select="handleSelect"
                :disabled-date="disabledDate"
            ></calendar-panel>
        </template>
        <template v-if="hasFooterSlot" #footer>
            <slot name="footer"></slot>
        </template>
    </date-picker>
</template>

<script>
import DatePicker from "vue2-datepicker";
import date from '../../mixins/date';
import moment from 'moment';

const { CalendarPanel } = DatePicker;

export default {
    name: 'e-date-picker',
    components: {
        CalendarPanel
    },
    mixins: [date],
    props: {
        placeholder: {
            type: String,
            required: true
        },
        type: {
            type: String,
            default: 'singe'
        },
        disableCalendar: {
            type: Boolean
        },
        dates: {
            type: Array,
            required: true
        },
        disabledPassedDates: {
            type: Boolean,
            default: false
        }
    },
    model: {
        prop: 'dates',
        event: 'changeDates'
    },
    methods: {
        disabledDate(date) {
            if (!this.disabledPassedDates) {
                return false
            }

            return date <= new Date(moment().subtract(1, 'day'))
        },
        changeCalendar() {
            this.$nextTick(() => {
                if (this.type === 'range') {
                    this.clearCircleRows('cell in-range')

                    let rows = document.getElementsByClassName('cell active')

                    if (rows[0]) {
                        /**
                         * Get first .cell.active row
                         */
                        let date = rows[0].getAttribute('title')

                        /**
                         * Check start OR end date
                         */
                        let activeDate = new Date(date)
                        activeDate.setHours(0,0,0,0)

                        let firstDateInDates = this.dates[0]
                        firstDateInDates.setHours(0,0,0,0)

                        if (this.isEqualsDates(activeDate, firstDateInDates)) {
                            this.drawStartCircle(rows[0])
                        } else {
                            this.drawEndCircle(rows[0])
                        }
                    }
                } else {
                    this.clearCircleRows('cell active')

                    let rowsActive = document.getElementsByClassName('cell active')

                    for (let el in rowsActive) {
                        if (Object.prototype.hasOwnProperty.call(rowsActive, el)) {
                            this.drawCircle(rowsActive[el])
                        }
                    }
                }
            })
        },
        getClasses(cellDate, currentDates, classes) {
            if (
                this.type === 'range' &&
                !/disabled|active|not-current-month/.test(classes) &&
                currentDates.length === 2 &&
                cellDate.getTime() > currentDates[0].getTime() &&
                cellDate.getTime() < currentDates[1].getTime()
            ) {
                return 'in-range'
            }

            return ''
        },
        getExistsIndex(date) {
            return this.dates.findIndex(i => i.getTime() === date.getTime())
        },
        handleSelect(date) {
            let dates = []

            if (this.type === 'range') {
                const [startValue, endValue] = this.dates;

                if (this.isValidDate(startValue) && !this.isValidDate(endValue)) {
                    if (startValue.getTime() > date.getTime()) {
                        dates = [date, startValue];
                    } else {
                        dates = [startValue, date];
                    }
                } else {
                    dates = [date, new Date(NaN)];
                }
            } else if (this.type === 'multi-days') {
                let index = this.getExistsIndex(date)

                if (index === -1) {
                    dates = this.dates.concat(date)
                } else {
                    let copyDatesLocal = this.dates.slice()
                    copyDatesLocal.splice(index, 1)

                    dates = copyDatesLocal
                }
            } else if (this.type === 'single') {
                dates = [date]
            }

            this.$emit('changeDates', dates)

            this.setPlaceholder()
        },
        disabledCalendar() {
            let el = document.getElementsByClassName('mx-datepicker-body')[0]

            el.style['pointer-events'] = 'none'
            el.style.opacity = 0.4
        },
        enabledCalendar() {
            let el = document.getElementsByClassName('mx-datepicker-body')[0]

            el.style['pointer-events'] = 'unset'
            el.style.opacity = 'unset'
        },
        open() {
            this.$nextTick(() => {
                if (this.disableCalendar) {
                    this.disabledCalendar()
                } else {
                    this.enabledCalendar()
                }

                if (this.type === 'range') {
                    let rowsActive = document.getElementsByClassName('cell active')

                    if (rowsActive.length === 2) {
                        this.drawStartCircle(rowsActive[0])
                        this.drawEndCircle(rowsActive[1])
                    } else {
                        this.clearCircleRows('cell')

                        if (!this.isValidDate(this.dates[1])) {
                            this.drawCircle(rowsActive[0])
                        } else {
                            if (rowsActive[0]) {
                                /**
                                 * Get first .cell.active row
                                 */
                                let date = rowsActive[0].getAttribute('title')

                                /**
                                 * Check start OR end date
                                 */
                                let activeDate = new Date(date)
                                activeDate.setHours(0,0,0,0)

                                let firstDateInDates = this.dates[0]
                                firstDateInDates.setHours(0,0,0,0)

                                if (this.isEqualsDates(activeDate, firstDateInDates)) {
                                    this.drawStartCircle(rowsActive[0])
                                } else {
                                    this.drawEndCircle(rowsActive[0])
                                }
                            }

                        }
                    }
                } else {
                    let rowsActive = document.getElementsByClassName('cell active')

                    for (let el in rowsActive) {
                        if (Object.prototype.hasOwnProperty.call(rowsActive, el)) {
                            this.drawCircle(rowsActive[el])
                        }
                    }
                }
            })
        },
        setPlaceholder() {
            let el = this.$refs['e-calendar'].$el.getElementsByClassName('mx-input-wrapper')
            let input = el[0].querySelector('input')

            if (this.type === 'single') {
                input.setAttribute('placeholder', this.dates.length
                    ? this.format(this.dates[0])
                    : this.placeholder
                )
            } else if (this.type === 'multi-days') {
                input.setAttribute('placeholder', this.dates.length
                    ? this.dates.length + ' shooting days'
                    : this.placeholder
                )
            } else if (this.type === 'range') {
                let countDays = 0
                if (this.dates.length) {
                    if (this.isValidDate(this.dates[1])) {
                        countDays = this.fillMissingDates(this.dates[0], this.dates[1]).length
                    } else {
                        countDays = 1
                    }
                }

                input.setAttribute('placeholder', countDays
                    ? countDays + ' shooting days'
                    : this.placeholder
                )
            }
        },
        isEqualsDates(d1, d2) {
            let date1 = d1.getTime()
            let date2 = d2.getTime()

            if (date1 < date2 || date1 > date2) {
                return false
            }

            return true
        },
        drawCircle(el) {
            el.style.borderRadius = '50%'
        },
        drawStartCircle(el) {
            el.style.borderTopLeftRadius = '50%'
            el.style.borderBottomLeftRadius = '50%'
        },
        drawEndCircle(el) {
            el.style.borderBottomRightRadius = '50%'
            el.style.borderTopRightRadius = '50%'
        },
        removeCircle(el) {
            el.style.removeProperty('border-top-left-radius')
            el.style.removeProperty('border-bottom-left-radius')
            el.style.removeProperty('border-top-right-radius')
            el.style.removeProperty('border-bottom-right-radius')
        },
        clearCircleRows(className) {
            let rows = document.getElementsByClassName(className)

            for (let el in rows) {
                if (Object.prototype.hasOwnProperty.call(rows, el)) {
                    this.removeCircle(rows[el])
                }
            }
        }
    },
    watch: {
        /**
         * Call when initial calendar inputs
         */
        dates: {
            handler() {
                if (this.dates.length) {
                    this.$nextTick(() => {
                        this.setPlaceholder()

                        let rows = document.getElementsByClassName('cell active')

                        if (this.type === 'range') {
                            this.clearCircleRows('cell')

                            if (rows.length === 2) {
                                this.drawStartCircle(rows[0])
                                this.drawEndCircle(rows[1])
                            } else {
                                this.clearCircleRows('cell')

                                if (!this.isValidDate(this.dates[1])) {
                                    this.drawCircle(rows[0])
                                } else {
                                    if (rows[0]) {
                                        /**
                                         * Get first .cell.active row
                                         */
                                        let date = rows[0].getAttribute('title')

                                        /**
                                         * Check start OR end date
                                         */
                                        let activeDate = new Date(date)
                                        activeDate.setHours(0,0,0,0)

                                        if (this.isEqualsDates(activeDate, this.dates[0])) {
                                            this.drawStartCircle(rows[0])
                                        } else {
                                            this.drawEndCircle(rows[0])
                                        }
                                    }

                                }
                            }
                        } else {
                            for (let el in rows) {
                                if (Object.prototype.hasOwnProperty.call(rows, el)) {
                                    this.drawCircle(rows[el])
                                }
                            }
                        }
                    })
                }
            },
            immediate: true
        },
        type() {
            this.$emit('changeDates', [])
        },
        disableCalendar() {
            this.$emit('changeDates', [])

            if (this.disableCalendar) {
                this.disabledCalendar()
            } else {
                this.enabledCalendar()
            }
        }
    },
    computed: {
        hasFooterSlot() {
            return !!this.$slots.footer
        },
        hasHeaderSlot() {
            return !!this.$slots.header
        }
    }
};
</script>
