import axiosIns from '@/libs/axios'

export default {
    getAuditionMaterial(audition_id) {
        return axiosIns.get(`/audition-materials/${audition_id}/audition`)
    },
    createAuditionMaterial(audition_id, data) {
        return axiosIns.post(`/audition-materials/${audition_id}/audition`, data)
    },
    updateAuditionMaterial(audition_material_id, data) {
        return axiosIns.post(`/audition-materials/${audition_material_id}/update`, data)
    },
    deleteAuditionMaterial(audition_material_id) {
        return axiosIns.delete(`/audition-materials/${audition_material_id}`)
    }
}