<template>
    <div class="application-status">
        <div class="recommend-role-wrapper">
            You Offered the role <div>{{ roleName }}</div> for this actor
        </div>
        <button class="btn-recommend-role" @click="showOfferDifferentRole = true">
            <img src="@/assets/images/pencil.svg" alt="edit" />
        </button>
        <OfferDifferentRole
            v-if="showOfferDifferentRole"
            @hide="showOfferDifferentRole = false"
        />
    </div>
</template>

<script>
import OfferDifferentRole from './OfferDifferentRole.vue';
import recommendRole from '@/api/recommendRole';
import { mapGetters } from 'vuex';

export default {
    components: {
        OfferDifferentRole
    },
    data() {
        return {
            showOfferDifferentRole: false
        }
    },
    created() {
        recommendRole.getRecommendRole(this.$route.params.applicationId).then(res => {
            this.$store.commit('SET_ROLE_NAME', res.data.role.name);	
        })
    },
    computed: {
        ...mapGetters(['roleName'])
    }
}
</script>

<style>
    .recommend-role-wrapper {
        width: 700px;
        height: 105px;
        background: rgba(255, 255, 255, 0.03);
        border: 0.5px solid rgba(255, 255, 255, 0.1);
        border-radius: 24px;
        margin-bottom: 40px;
        text-align: center;
        color: #FFFFFF;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .recommend-role-wrapper div {
        width: 200px;
        height: 50px;
        background: linear-gradient(180deg, rgba(47, 60, 86, 0.3) 0%, #2F3C56 100%);
        border: 1px solid rgba(255, 255, 255, 0.3);
        border-radius: 24px;
        line-height: 45px;
        margin: 10px;
        font-size: 19px;
        padding: 0 12px;
    }
    .btn-recommend-role {
        height: 32px;
        width: 32px;
        background: transparent;
        border: none;
        margin: 0 5px;
        position: relative;
        top: 10px;
        right: 50px;
    }
</style>