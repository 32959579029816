<template>
    <div>
        <nav>
            <ul class="casting-tabs">
                <li 
                    v-for="tab in tabs" 
                    :key="tab.id" 
                    :class="[{'casting-active-tab': activeTabId === tab.id}]"
                    @click="handleClick(tab.id)"
                >
                    {{tab.name}}
                    <span class="applications-count">{{tab.applications_count}}</span>
                    <hr v-if="activeTabId === tab.id" class="active-tab-line" />
                </li>
            </ul>
        </nav>
        <div v-if="activeTabId !== 5" class="search-actor-container">
            <div>
                <img src="@/assets/images/search.svg" alt="search" />
                <b-form-input
                    v-model="search"
                    type="search"
                    placeholder="Search for a specific actor (only people with the 'Boom' app installed will be shown)"
                ></b-form-input>    
            </div>
            <!-- <button>Invite by email</button> -->
        </div>
        <div v-if="activeTabId !== 5" class="applications-container"> 
            <ApplicationsLists :applications="applications" :active-tab-id="activeTabId" :width="width" :count="count" /> 
        </div>
        <div v-if="activeTabId === 5" class="reject-tab-head">
            <div>Rejected by casting manager</div>
            <div>Rejected by actor in app</div>
        </div>
        <div v-if="activeTabId === 5" class="reject-tab-main">
            <div class="reject-tab-main-col">
                <ApplicationsLists 
                    :applications="applications.filter(item => item.attributes.status === 30)" 
                    :active-tab-id="activeTabId" 
                    :width="width" 
                    :count="count" 
                />
            </div>
            <div class="reject-tab-main-col">
                <ApplicationsLists 
                    :applications="applications.filter(item => item.attributes.status === 31)" 
                    :active-tab-id="activeTabId" 
                    :width="width" 
                    :count="count"
                />
            </div>
        </div>
    </div>
</template>

<script>
import ApplicationsLists from './components/ApplicationsLists.vue';
import { mapGetters } from 'vuex';

export default {
    components: {
        ApplicationsLists
    },
    data() {
        return {
            activeTabId: Number(localStorage.getItem('tab')) || 1,
            search: null,
            width: null,
            count: null
        };
    },
    created() {
        window.addEventListener('resize', this.updateWidth);
        this.updateWidth();
    },
    computed: {
        ...mapGetters(['getRoleApplications']),
        applications() {   
            if (this.activeTabId === 2) return this.applicationsFilter(40);
            if (this.activeTabId === 3) return this.applicationsFilter(50);
            if (this.activeTabId === 4) return this.applicationsFilter(60);
            if (this.activeTabId === 5) return this.rejectedApplications();
            if (this.activeTabId === 6) return this.applicationsFilter(51);
            const applications = this.allApplications().sort((a, b) => { return a.attributes.priority - b.attributes.priority});
            return this.applicationsSearch(applications);
        },
        tabs() {
            return [
                { id: 1, name: 'All active applications', applications_count: this.applicationsCount(0) },
                { id: 2, name: 'Audition requested', applications_count: this.applicationsCount(40) },
                { id: 3, name: 'Self-tape requested', applications_count: this.applicationsCount(50) },
                { id: 4, name: 'Different role offered', applications_count: this.applicationsCount(60) },
                { id: 6, name: 'Self-tape provided', applications_count: this.applicationsCount(51) },
                { id: 5, name: 'Rejected applications', applications_count: this.applicationsCount(30) }
            ]
        }
    },
    methods: {
        handleClick(id) {
            localStorage.setItem('tab', id);
            this.activeTabId = id;
            this.$store.dispatch('GET_ROLE_APPLICATIONS', this.$route.params.role_id);
        },
        updateWidth() {
            this.width = window.innerWidth;
            if (this.width > 1775) { this.count = 5; }
            else if (this.width < 1775 && this.width > 1536) { this.count = 4; }
            else { this.count = 2; }
        },
        applicationsFilter(status) {
            const applications = this.getRoleApplications.filter(item => item.attributes.status === status);
            return this.applicationsSearch(applications);
        },
        applicationsSearch(applications) {
            if (this.search) {
                return applications.filter(item => 
                    item.attributes.actor && item.attributes.actor.first_name.toLowerCase().includes(this.search.toLowerCase()) || 
                    item.attributes.actor && item.attributes.actor.last_name.toLowerCase().includes(this.search.toLowerCase()) 
                );
            }
            return applications;
        },
        allApplications() { 
            return this.getRoleApplications.filter(item => ![30, 31].includes(item.attributes.status)); 
        },
        rejectedApplications() { return this.getRoleApplications.filter(item => [30, 31].includes(item.attributes.status)); },
        applicationsCount(status) {
            if ([40, 50, 60, 51].includes(status)) return this.applicationsFilter(status).filter(item => item.attributes.actor).length;
            else if (status === 0) return this.allApplications().filter(item => item.attributes.actor).length;
            return this.rejectedApplications().filter(item => item.attributes.actor).length;
        }
    }
};
</script>

<style>
    .casting-tabs {
        display: flex;
        padding-left: 0;
        color: rgba(255, 255, 255, 0.5);
        margin-bottom: 1rem;
    }
    .casting-tabs li {
        height: 54px;
        width: 20%;
        text-align: center;
        line-height: 54px;
        border-right: 1px solid rgba(255, 255, 255, 0.05);
        border-bottom: 1px solid rgba(255, 255, 255, 0.05);
        background: #222A3D;
        position: relative;
    }
    .casting-tabs li:hover {
        cursor: pointer;
        color: white;
    }
    .casting-active-tab {
        color: white;
        border-width: 2px;
        border-style: solid;
        border-image: 
            linear-gradient(
            to top, 
            white, 
            rgba(0, 0, 0, 0)
            ) 1 100%;
    }
    .active-tab-line {
        margin: 0px -1px 0px -2px;
        border: 1px solid white;
        opacity: 1;
    }
    .search-actor-container input {
        width: 610px;
        height: 40px;
        border: 0.5px solid rgb(255, 255, 255, 0.7);
        border-radius: 32px;
        background: #252F44;
        color: white !important;
        font-size: 15px;
        padding-left: 35px;
    }
    .search-actor-container input:focus{
        background: #252F44;
        border-color: rgb(255, 255, 255, 0.9);
        box-shadow: none;
    }
    .search-actor-container input::placeholder {
        color: rgba(255, 255, 255, 0.4);
        font-size: 13px;
    }
    .search-actor-container input::-webkit-search-cancel-button {
        -webkit-appearance: none;
        height: 1em;
        width: 1em;
        border-radius: 50em;
        background: url('@/assets/images/close.png') no-repeat 50% 50%;
        background-size: contain;
        opacity: 0;
        pointer-events: none;
        cursor: pointer;
    }
    .search-actor-container input:focus::-webkit-search-cancel-button {
        opacity: .3;
        pointer-events: all;
    }
    .search-actor-container div {
        position: relative;
    }
    .search-actor-container div img {
        position: absolute;
        top: 10px;
        left: 10px;
    }
    .search-actor-container {
        margin: 35px 0;
        display: flex;
        justify-content: center;
    }
    .search-actor-container button {
        width: 160px;
        height: 40px;
        background: #252F44;
        border: 0.5px solid rgb(255, 255, 255, 0.7);
        border-radius: 32px;
        color: rgba(255, 255, 255, 0.7);
        font-size: 15px;
        margin-left: 30px;
    }
    .search-actor-container button:hover {
        color: white;
        border-color: rgb(255, 255, 255, 0.9);
    }
    .applications-container {
        color: white;
        display: flex;
        justify-content: center;
        overflow: auto;
        max-height: calc(100vh - 255px);
    }
    .reject-tab-head {
        color: white;
        font-size: 20px;
        display: flex;
        height: 55px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);;
        position: relative;
        bottom: 10px;
    }
    .reject-tab-head div {
        width: 50%;
        text-align: center;
        height: 48px;
        line-height: 48px;
        border-right: 1px solid rgba(255, 255, 255, 0.2);;
    }
    .reject-tab-head div:last-of-type {
        border-right: none;
    }
    .reject-tab-main {
        display: flex;
        position: relative;
        bottom: 3px;
        min-height: calc(100vh - 196px);
    }
    .reject-tab-main-col {
        width: 50%;
        border-right: 1px solid rgba(255, 255, 255, 0.2);
        display: flex;
        justify-content: center;
        padding-top: 30px;
        overflow: auto;
        max-height: calc(100vh - 200px);
    }
    .reject-tab-main-col:last-of-type {
        border-right: none;
    }
    .applications-count {
        float: right;
        padding-right: 5px;
    }
    @media(max-width: 1536px) { 
        .casting-tabs {
            font-size: 15px;
        }
    }
    @media(max-width: 1400px) { 
        .casting-tabs {
            font-size: 13px;
        }
    }
</style>