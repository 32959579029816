<template>
	<b-modal 
        id="delete-project" 
        size="lg"
        centered 
        footer-class="justify-content-center footer-pop-up"
        header-class="header-pop-up"
        body-class="body-pop-up"
        content-class="content-pop-up"
        no-close-on-esc
        no-close-on-backdrop
    >
        <template #modal-header>
            <div>Delete Project</div>
            <button @click="$bvModal.hide('delete-project')">
                <img src="@/assets/images/close.png" alt="close" />
            </button>
        </template>
        <template #default>
            <div class="body-pop-up-modal">Are you sure you want to delete the project?</div>
        </template>
        <template #modal-footer>
            <button class="btn-continue btn-inactive" style="width: 220px;" @click="remove(projectId)">Delete</button>
        </template>
    </b-modal>
</template>

<script>
import project from '@/mixins/project';

export default {
    mixins: [project],
    props: {
        projectId: {
            required: false,
            type: String
        }
    },
    data() {
        return {
            status: null
        }
    },
    watch: {
        $route: {
            handler(route) {
                this.status = route.name === 'activeProject';
            },
            immediate: true
        }
    }
}
</script>