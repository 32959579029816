export default {
    data() {
        return {
            type: 'password',
            form: {
                email: '',
                password: '',
                password_confirmation: '',
                remember_me: false
            },
            validationEmail: true,
            validationPassword: true,
            validationConfirmPassword: true
        }
    },
    watch: {
        'form.email'() { this.validationEmail = true; },
        'form.password'() { this.validationPassword = true; },
        'form.password_confirmation'() { this.validationConfirmPassword = true; }
    },
    methods: { 
        togglePassword() {
            if (this.type === "password") this.type = "text";
            else this.type = "password";    
        },
        validEmail(){
            var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(this.form.email);
        },
        validPassword(){
            var pas = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&_])[A-Za-z\d$@$!%*?&_]{8,}/
            return pas.test(this.form.password);
        }
    }
}