import axiosIns from '@/libs/axios'

export default {
    recommendRole(application_id, data) {
        return axiosIns.post(`/applications/${application_id}/recommend-role`, data)
    },
    getRecommendRole(application_id) {
        return axiosIns.get(`/applications/${application_id}/recommend-role`)
    },
    editRecommendRole(application_id, data) {
        return axiosIns.put(`/applications/${application_id}/recommend-role`, data)
    }
}