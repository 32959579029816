<template>
	<div class="forgot-password">
        <div class="lock">
            <img src="@/assets/images/lock.png" alt="Lock" />
        </div>
        <div class="pr">
            <img src="@/assets/images/pr.png" alt="Password Recovery!" />
        </div>
        <div v-if="!isCode">
            <div class="auth-text">
                Enter the email address you used for registration in the <br />
                Boom app, and click "Continue" 
            </div>
            <div class="pr-i">
                <label>
                    <transition name="fade-transform">
                        <span v-if="form.email" class="input-hint input-sm-hint">Enter your email</span>
                    </transition>
                    <input 
                        v-model="form.email"
                        type="email" 
                        placeholder="Enter your email" 
                        :class="['fp-i', {'is-invalid': !validationEmail}]"
                    />
                </label>
                <div v-if="!validationEmail" class="invalid invalid-fp">
                    <img src="@/assets/images/invalid.png" alt="invalid" />
                    <span>Please enter a valid email address</span>
                </div>
            </div>
            <div class="btn-auth">
                <button class="btn-continue fp-btn" @click="forgotPassword">Continue</button>
            </div>
        </div>
        <div v-else-if="isCode && !isValidCode">
            <div class="auth-text">
                Check your Email. We've sent you the recovery code to <br />
                {{form.email}}
            </div>
            <div class="pr-i">
                <label>
                    <transition name="fade-transform">
                        <span v-if="code" class="input-hint input-sm-hint">Recovery Code</span>
                    </transition>
                    <input 
                        v-model="code"
                        type="text" 
                        placeholder="Recovery Code" 
                        class="fp-i"
                    />
                </label>
            </div>
            <div class="btn-auth">
                <button class="btn-continue fp-btn" @click="checkCode">Continue</button>
            </div>
            <div class="resent">
                <div class="code">Haven’t received your code?</div>
                <button class="resent-btn" @click="forgotPassword">Resent</button>
            </div>
        </div>
        <div v-else>
            <div class="auth-text">
                Type a new strong password which you'll remember
            </div>
            <div class="pr-i">
                <label>
                    <transition name="fade-transform">
                        <span v-if="form.password" class="input-hint input-rp-hint">Password</span>
                    </transition>
                    <input 
                        v-model="form.password" 
                        :type="type" 
                        placeholder="Password" 
                        class="fp-i"
                    />
                </label>
                <div><button id="password" @click="togglePassword()" style="left: 165px;" :class="type === 'password' ? 'show-password' : 'hide-password'" /></div>
                <div v-if="!validationPassword" class="invalid invalid-password fp-p">
                    <img src="@/assets/images/invalid.png" alt="invalid" />
                    <span>
                        Please enter minimum 8 characters, at least 1 uppercase <br />
                        letter, 1 lowercase letter, 1 number and 1 special character
                    </span>
                </div>
            </div>
            <div class="pr-i">
                <label>
                    <transition name="fade-transform">
                        <span v-if="form.password_confirmation" class="input-hint input-rp-hint">Confirm password</span>
                    </transition>
                    <input 
                        v-model="form.password_confirmation" 
                        :type="type" 
                        placeholder="Confirm password" 
                        class="fp-i"
                    />
                </label>
                <div><button @click="togglePassword()" style="left: 165px;" :class="type === 'password' ? 'show-password' : 'hide-password'" /></div>
                <div v-if="!validationConfirmPassword" class="invalid invalid-password fp-cp">
                    <img src="@/assets/images/invalid.png" alt="invalid" />
                    <span>
                        The passwords don't match
                    </span>
                </div>
            </div>
            <div class="btn-auth">
                <button class="btn-continue fp-btn" @click="resetPassword">Continue</button>
            </div>
        </div>
    </div>
</template>

<script>
import auth from '@/mixins/auth';
import notify from '@/mixins/notify';
import user from '@/api/user';

export default {
    mixins: [auth, notify],
    data() {
        return {
            isCode: false,
            isValidCode: false,
            code: ''
        }
    },
    methods: {
        forgotPassword() {
            if ( !this.validEmail()) this.validationEmail = false;
            if ( !this.validationEmail ) return; 
            user.forgotPassword({email: this.form.email}).then(() => { 
                this.isCode = true; 
            })
        },
        checkCode() {
            user.checkResetPasswordCode({code: this.code}).then(() => { 
                this.isValidCode = true; 
            })
        },
        resetPassword() {
            const equalityPassword = this.form.password != this.form.password_confirmation;
            if ( !this.validPassword()) this.validationPassword = false;
            if ( equalityPassword ) this.validationConfirmPassword = false;
            if ( !this.validationPassword || !this.validationConfirmPassword) return; 

            const form = {
                'code': this.code,
                'email': this.form.email,
                'password': this.form.password,
                'password_confirmation': this.form.password_confirmation
            }

            user.resetPassword(form).then(() => {
                this.notifySuccess("Password has been successfully changed.");
                setTimeout(() => { this.$router.push( { path:'/' } ); }, 2000);
            })
        }
    }
}
</script>

<style>
    .forgot-password {
        font-family: 'Jost';
        font-style: normal;
        font-weight: 400;
        text-align: center;
    }
    .lock {
        margin: 280px 0 30px;
    }
    .pr {
        margin-bottom: 20px;
    }
    .fp-i {
        width: 380px !important;
    }
    .fp-btn {
        width: 160px;
    }
    .invalid-fp {
        right: 55px;
    }
    .code {
        color: rgba(255, 255, 255, 0.4);
    }
    .resent-btn {
        background: transparent;
        border: none;
        font-size: 16px;
        color: white;
        margin-top: 5px;
    }
    .resent-btn:hover {
        color: rgba(243, 241, 241, 0.8);
    }
    .resent {
        margin-top: 25px;
    }
    .fp-p {
        left: 10px;
    }
    .fp-cp {
        right: 75px;
    }
    .input-sm-hint {
        width: 140px;
        left: 200px;
    }
    .input-rp-hint {
        width: 165px;
        left: 170px;
    }
</style>