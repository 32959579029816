<template>
    <b-modal
        id="role-creating-modal"
        centered
        v-model="isShowModal"
        hide-footer
        hide-header
        size="huge"
        @hide="$emit('hide')"
    >
        <div class="role-creating-body">
            <!-- Close icon in the upper right corner -->
            <div class="role-creating-close">
                <span class="role-creating-close__icon">
                    <img
                        @click="isShowModal = false"
                        :src="getImageClose"
                        alt="Close role creating"
                        height="20"
                    />
                </span>
            </div>

            <!-- Title -->
            <div class="role-creating-title">
                <span>Role Creation</span>
            </div>

            <!-- Header line -->
            <div class="role-creating-header-line">
                <img :src="getImageHeaderLine" alt="Header line">
            </div>

            <!-- Form -->
            <div class="role-creating-form">
                <!-- Checkbox -->
                <div class="role-creating-form__checker" v-if="false">
                    <span
                        :class="[{ disabled: form.status }, 'public-casting-label']"
                    >
                        Public casting
                    </span>
                    <e-checkbox
                        switch-color="orange"
                        v-model="form.status"
                    />
                    <span
                        :class="[{ disabled: !form.status }, 'private-casting-label']"
                    >
                        Private casting
                    </span>
                </div>

                <!-- Form | name -->
                <div class="role-creating-form__name">
                    <label>
                        <transition name="fade-transform">
                            <span v-if="form.name" class="input-hint">Role name</span>
                        </transition>
                        <input 
                            type="email"
                            placeholder="Role name"
                            v-model="form.name"
                        />
                    </label>
                </div>

                <!-- Form | description -->
                <div class="role-creating-form__description">
                    <transition name="fade-transform">
                        <div v-if="form.description" class="modal-hint">Role description</div>
                    </transition>
                    <textarea
                        maxlength="5000"
                        placeholder="Role description"
                        v-model="form.description"
                    ></textarea>
                    <span>{{ form.description.length }}/5000</span>
                </div>

                <!-- Form | text -->
                <div class="role-creating-form__text">
                    Please leave blank the parameters below that are not important for the casting. 
                    More actors will be able to apply for the role if some of the characteristics are more flexible.
                </div>

                <!-- Gender -->
                <div class="role-creating-form__gender">
                    <Multiselect 
                        :options="ethnicityOptions"
                        v-model="form.ethnicity"
                        :searchable="false"
                        :close-on-select="true"
                        :show-labels="false"
                        placeholder="Ethnicity"
                        label="name"
                    />
                    <div class="role-creating-form__placeholder-width"></div>
                    <Multiselect
                        :options="actingGenderOptions"
                        v-model="form.actingGender"
                        :searchable="false"
                        :multiple="true"
                        :close-on-select="true"
                        :show-labels="false"
                        placeholder="Acting gender"
                        label="name"
                        track-by="name"
                        class="acting-gender"
                    />
                </div>

                <!-- Skills -->
                <div class="role-creating-form__skills">
                    <Multiselect 
                        :options="skillsOptions"
                        v-model="form.personalSkills"
                        :searchable="false"
                        :multiple="true"
                        :close-on-select="true"
                        :show-labels="false"
                        track-by="name"
                        placeholder="List of required skills"
                        label="name"
                        class="required-skills"
                    />
                </div>
                
                <!-- Min, Max action age -->
                <div class="role-creating-form__age">
                    <div class="role-creating-form__hint">
                        <label>
                            <transition name="fade-transform">
                                <span v-if="form.minAge" class="input-hint age-hint">Min acting age</span>
                            </transition>
                            <input
                                type="number"
                                placeholder="Min acting age"
                                v-model="form.minAge"
                            />
                        </label>
                    </div>
                    <div class="role-creating-form__placeholder-width"></div>
                    <div class="role-creating-form__hint">
                        <label>
                            <transition name="fade-transform">
                                <span v-if="form.maxAge" class="input-hint age-hint">Max acting age</span>
                            </transition>
                            <input
                                type="number"
                                placeholder="Max acting age"
                                v-model="form.maxAge"
                            />
                        </label>
                    </div>
                </div>

                <!-- Line -->
                <div class="role-creating-form__line">
                    <img :src="getImageLine" alt="line" />
                </div>

                <!-- Upload materials -->
                <div class="upload-materials">
                    <div
                        class="upload-materials__list"
                        v-for="(item, index) in uploadMaterials"
                        :key="index"
                    >
                        <template v-if="!item.name">
                            <Multiselect
                                :options="materialTypesOptions"
                                v-model="materialType"
                                :searchable="false"
                                :close-on-select="true"
                                :show-labels="false"
                                :allow-empty="false"
                                placeholder="Choose material type"
                                label="name"
                            />
                            <div class="upload-file-section">
                                <span class="upload-file-section__title">
                                    Upload preparation material
                                </span>
                                <label :for="'file-input_' + index">
                                    <img
                                        :class="[{ disabled: !materialType.name }, 'upload-file-section__icon']"
                                        :src="getImageUpload"
                                        alt="upload"
                                    />
                                </label>
                                <input
                                    v-if="materialType.name"
                                    class="file-input"
                                    :id="'file-input_' + index"
                                    type="file"
                                    @change="previewFiles"
                                />
                            </div>
                        </template>
                        <template v-else>
                            <div class="upload-materials__uploaded-item">
                                <span>{{ item.name }}</span>
                                <div class="upload-materials-actions">
                                    <label :for="'file-edit_' + index">
                                        <img
                                            :src="getImageEdit"
                                            alt="edit"
                                        />
                                    </label>
                                    <input class="file-input" :id="'file-edit_' + index" type="file" @change="e => editMaterialFile(e, item.id, index)"/>

                                    <img
                                        :src="getImageVerticalLine"
                                        class="upload-materials-actions__vertical-line"
                                        alt="vertical-line"
                                    />
                                    <img
                                        @click="deleteMaterialFile(item.id, index)"
                                        :src="getImageDelete"
                                        alt="delete"
                                    />
                                </div>
                            </div>
                        </template>
                    </div>
                    <div class="upload-materials-button" @click="addUploadMaterial">
                        <span class="upload-materials-button__title">
                            Upload more materials
                        </span>
                        <img :src="getImagePlus" alt="plus" />
                    </div>
                </div>

                <!-- Line -->
                <div class="role-creating-form__line">
                    <img :src="getImageLine" alt="line" />
                </div>

                <!-- Payment -->
                <div class="role-creating-form__payment">
                    <div class="role-creating-form__hint">
                        <label>
                            <transition name="fade-transform">
                                <span v-if="form.rate" class="input-hint rate-hint">Rate</span>
                            </transition>
                            <input
                                type="number"
                                class="payment-rate"
                                placeholder="Rate"
                                v-model="form.rate"
                                :disabled="disablePaymentFields"
                            />
                        </label>
                    </div>
                    <div class="payment-currency">
                        <Multiselect
                            v-model="form.paymentCurrency"
                            :options="currenciesOptions"
                            :searchable="false"
                            :close-on-select="true"
                            :show-labels="false"
                            label="name"
                            placeholder="Currency"
                            :disabled="disablePaymentFields"
                        />
                    </div>
                    <div class="payment-type">
                        <Multiselect 
                            :options="getPaymentTypesOptions"
                            v-model="form.paymentType"
                            :searchable="false"
                            :close-on-select="true"
                            :show-labels="false"
                            placeholder="Payment type"
                            label="name"
                        />
                    </div>
                </div>

                <!-- Location -->
                <div class="role-creating-form__location">
                    <div class="country-hint">
                        <label>
                            <transition name="fade-transform">
                                <span v-if="form.countryId" class="input-hint span-country-hint">Country</span>
                            </transition>
                            <input
                                type="text"
                                class="location-country"
                                placeholder="Country"
                                v-model="form.countryId"
                            />
                        </label>
                    </div>
                    <div class="city-hint">
                        <label>
                            <transition name="fade-transform">
                                <span v-if="form.city" class="input-hint span-city-hint">City</span>
                            </transition>
                            <input
                                type="text"
                                class="location-city"
                                placeholder="City"
                                v-model="form.city"
                            />
                        </label>
                    </div>
                    <div class="address-hint">
                        <label>
                            <transition name="fade-transform">
                                <span v-if="form.address" class="input-hint span-address-hint">Street Address</span>
                            </transition>
                            <input
                                type="text"
                                class="location-street-address"
                                placeholder="Street Address"
                                v-model="form.address"
                            />
                        </label>
                    </div>
                </div>

                <!-- Date -->
                <div class="role-creating-form__date">
                    <div class="e-date-picker">
                        <e-date-picker
                            placeholder="Pick shooting dates"
                            :type="isMultiCalendar ? 'multi-days' : 'range'"
                            :disableCalendar="disableShootingDatesCalendar"
                            v-model="form.pickShootingDate"
                        >
                            <template #header>
                                <div class="e-date-picker__header">
                                    <span>Choose the dates range</span>
                                    <e-checkbox
                                        class="e-date-picker__checkbox"
                                        size="s"
                                        v-model="isMultiCalendar"
                                    />
                                    <span>Choose specific days</span>
                                </div>
                            </template>
                            <template #footer>
                                <div class="e-date-picker__floor">
                                    <div class="e-date-picker-floor-or">
                                        <hr />
                                        <span>or</span>
                                        <hr />
                                    </div>
                                    <div class="e-date-picker-floor-checker">
                                        <label class="e-date-picker-floor-checker__container">
                                            <input
                                                type="checkbox"
                                                v-model="disableShootingDatesCalendar"
                                            >
                                            <span class="e-date-picker-floor-checker__checkmark"></span>
                                            <span class="e-date-picker-floor-checker__title">
                                                Shooting dates will be defined later
                                            </span>
                                        </label>
                                    </div>
                                </div>
                            </template>
                        </e-date-picker>
                    </div>
                    <div class="role-creating-form__placeholder-width"></div>
                    <div class="e-date-picker">
                        <e-date-picker
                            placeholder="Application deadline"
                            type="single"
                            :disableCalendar="disableApplicationDateCalendar"
                            v-model="form.applicationDeadline"
                            :disabled-passed-dates="true"
                        >
                            <template #footer>
                                <div class="e-date-picker__floor">
                                    <div class="e-date-picker-floor-or">
                                        <hr />
                                        <span>or</span>
                                        <hr />
                                    </div>
                                    <div class="e-date-picker-floor-checker">
                                        <label class="e-date-picker-floor-checker__container">
                                            <input
                                                type="checkbox"
                                                v-model="disableApplicationDateCalendar"
                                            >
                                            <span class="e-date-picker-floor-checker__checkmark"></span>
                                            <span class="e-date-picker-floor-checker__title">
                                                Application date will be defined later
                                            </span>
                                        </label>
                                    </div>
                                </div>
                            </template>
                        </e-date-picker>
                    </div>
                </div>

                <!-- Footer line -->
                <div class="role-creating-footer-line">
                    <img :src="getImageFooterLine" alt="Footer line">
                </div>

                <!-- Btn -->
                <div class="role-creating-form__btn">
                    <button @click="submit" :class="{'active': isFormFilled}" :disabled="disableSubmit">
                        {{ this.roleId ? 'Save changes' : 'Create Role' }}
                    </button>
                </div>
            </div>
        </div>
    </b-modal>
</template>

<script>
import 'vue2-datepicker/index.css';
import Multiselect from 'vue-multiselect';
import { isEmpty } from 'lodash';
import date from '@/mixins/date';
import notify from '@/mixins/notify';

import eCheckbox from "@/elements/inputs/e-checkbox";
import eDatePicker from "@/elements/e-date-picker";
import other from '@/api/other';
import role from '@/api/role';
import roleMaterial from '@/api/roleMaterial';

export default {
    name: 'RoleCreating',
    mixins: [date, notify],
    components: {
        Multiselect,
        eCheckbox,
        eDatePicker
    },
    props: {
        projectId: {
            type: String,
            required: true
        },
        roleId: {
            type: String,
            default: undefined
        }
    },
    data() {
        return {
            getImageClose: require('@/assets/images/close.png'),
            getImageHeaderLine: require('@/assets/images/header-line-role.svg'),
            getImageFooterLine: require('@/assets/images/footer-line-role.svg'),
            getImageLine: require('@/assets/images/line.svg'),
            getImageUpload: require('@/assets/images/upload.png'),
            getImagePlus: require('@/assets/images/edit.svg'),
            getImageEdit: require('@/assets/images/edit.svg'),
            getImageDelete: require('@/assets/images/delete.svg'),
            getImageVerticalLine: require('@/assets/images/vertical-line.svg'),

            isShowModal: true,
            form: {
                status: true,
                name: '',
                description: '',
                ethnicity: null,
                actingGender: [],
                personalSkills: [],
                paymentCurrency: null,
                paymentType: null,
                rate: '',
                countryId: '',
                city: '',
                address: '',
                minAge: '',
                maxAge: '',
                applicationDeadline: [],
                pickShootingDate: [],
            },

            ethnicityOptions: [],
            skillsOptions: [],
            actingGenderOptions: [
                {
                    index: 0,
                    name: 'Male'
                },
                {
                    index: 1,
                    name: 'Female'
                },
                {
                    index: 2,
                    name: 'Other'
                }
            ],
            materialTypesOptions: [],
            currenciesOptions: [],
            getPaymentTypesOptions: [],

            materialType: [],
            materialTypes: [],
            uploadMaterials: [{}],
            removeMaterialId: [],
            editMaterialId: [],
            isUploadMaterials: false,

            isMultiCalendar: false,

            disableShootingDatesCalendar: false,
            disableApplicationDateCalendar: false,
            disableSubmit: false,
            success: false
        }
    },
    watch: {
        disablePaymentFields: {
            handler(val) {
                if (val) {
                    this.form.paymentCurrency = null
                    this.form.rate = ''
                }
            },
            deep: true
        }
    },
    methods: {
        previewFiles(event) {
            try {
                this.isUploadMaterials = true
                const file = event.target.files[0]

                let lastIndex = Object.keys(this.uploadMaterials)[this.uploadMaterials.length - 1]
                this.uploadMaterials.splice(lastIndex, 1, file)

                this.materialTypes.push(this.materialType)
            } catch (error) {
                return error
            }
        },
        async editMaterialFile(event, id, index) {
            const file = event.target.files[0]

            if (id) {
                this.uploadMaterials.map((el, i) => {
                    if (i === index) el.name = file.name
                })
                this.editMaterialId.push({id: id, name: file.name});
            }

            file.id = id
            this.uploadMaterials.splice(index, 1, file)
        },
        addUploadMaterial() {
            if (
                this.uploadMaterials.some(i => i.name === undefined) ||
                isEmpty(this.materialType)
            ) {
                return false
            }

            this.materialType = []
            this.uploadMaterials.push({})
        },
        async deleteMaterialFile(id, index) {
            if (id) this.removeMaterialId.push(id)

            this.uploadMaterials.splice(index, 1)
            this.materialTypes.splice(index, 1)

            if (this.uploadMaterials.length === 0) {
                this.materialType = []
                this.uploadMaterials.push({})
            } 
        },
        async getEthnicity() {
            try {
                let res = await other.getEthnicity()
                this.ethnicityOptions = res.data
            } catch (err) {
                return err
            }
        },
        async getPersonSkills() {
            try {
                let res = await other.getPersonSkills()
                this.skillsOptions = res.data
            } catch (err) {
                return err
            }
        },
        async getMaterialType() {
            try {
                let res = await other.getMaterialTypes()
                this.materialTypesOptions = res.data
            } catch (err) {
                return err
            }
        },
        async getCurrency() {
            try {
                let res = await other.getCurrency()
                this.currenciesOptions = res.data
                this.form.paymentCurrency = this.currenciesOptions[0]
            } catch (err) {
                return err
            }
        },
        async getPaymentTypes() {
            try {
                let res = await other.getPaymentTypes()
                this.getPaymentTypesOptions = res.data
            } catch (err) {
                return err
            }
        },
        async submit() {
            const formData = new FormData();

            if (!this.form.name.length) return this.notifyWarning('Role name can`t be empty!')
            formData.append('name', this.form.name)

            if (!this.form.description.length) return this.notifyWarning('Role description can`t be empty!')
            formData.append('description', this.form.description)

            if (this.form.actingGender.length) {
                this.form.actingGender.forEach(i => {
                    formData.append('acting_gender[]', i.index)
                })
            }

            if (this.form.ethnicity !== null) {
                formData.append('ethnicity_id', this.form.ethnicity.id)
            }

            this.form.personalSkills.forEach(skill => {
                formData.append('personal_skills[]', skill.id)
            })

            formData.append('status', this.form.status)

            if (this.form.city) formData.append('city', this.form.city)
            if (this.form.address) formData.append('address', this.form.address)

            if (!this.form.minAge && this.form.maxAge) this.form.minAge = 0

            if (this.form.minAge && !this.form.maxAge) this.form.maxAge = 130

            const minAge = Number(this.form.minAge);
            const maxAge = Number(this.form.maxAge);

            if (this.form.minAge && this.form.maxAge && minAge >= maxAge) {
                return this.notifyWarning('The Max acting age must be greater than Min acting age!');
            } 

            if (minAge < 0) {
                return this.notifyWarning('Min age range should be from 0 to 130!')
            }
            formData.append('min_age', this.form.minAge)

            if (maxAge > 130) {
                return this.notifyWarning('Max age range should be from 0 to 130!')
            }
            formData.append('max_age', this.form.maxAge)

            if (!this.form.paymentType || ![7, 8].includes(this.form.paymentType.id)) {
                if (!this.form.rate.length) return this.notifyWarning('Rate can`t be empty!')
                if (Number.parseInt(this.form.rate) === 0) return this.notifyWarning('Rate can`t be zero!')
                if (!this.form.paymentCurrency) return this.notifyWarning('Currency can`t be empty!')
            }

            formData.append('rate', this.form.rate)
            if (this.form.paymentCurrency) {
                formData.append('currency_id', this.form.paymentCurrency.id)
            }

            if (this.form.countryId) {
                formData.append('country_id', this.form.countryId)
            }

            if (!this.form.paymentType) return this.notifyWarning('Payment type can`t be empty!')
            formData.append('payment_type_id', this.form.paymentType.id)

            if (this.form.applicationDeadline.length) {
                formData.append('application_deadline', this.format(this.form.applicationDeadline[0]))
            }

            formData.append('pick_shooting_date_type', this.isMultiCalendar ? 1 : 2)
            /**
             * Shooting date parser
             */
             this.getShootingDates(this.form.pickShootingDate).forEach(date => {
                formData.append('pick_shooting_dates[][date]', this.format(date))
            })

            if (this.roleId) {
                formData.append('_method', 'PUT')

                let res = null
                res = await role.updateRole(this.projectId, this.roleId, formData)

                if (res.status === 200) {
                    if (this.removeMaterialId.length) {
                        this.removeMaterialId.forEach(id => { 
                            roleMaterial.deleteRoleMaterial(id) 
                        })
                    }

                    if (this.editMaterialId.length) {
                        this.editMaterialId.forEach(el => { 
                            this.uploadMaterials.filter(i => i.name === el.name).map((item, index) => {
                                this.roleMaterials(item, index, el.id, 'update')
                            })
                        });
                    }

                    if (this.isUploadMaterials) {
                        if (this.editMaterialId.length > 0) {
                            this.editMaterialId.forEach(el => {
                                this.uploadMaterials.filter(i => i.name !== el.name).map((item, index) => {
                                    this.roleMaterials(item, index, this.roleId)
                                })
                            })
                        }else {
                            this.uploadMaterials.map((item, index) => { 
                                this.roleMaterials(item, index, this.roleId) 
                            })
                        }
                    }

                    this.notifySuccess('Successfully Updated!')

                    this.$store.dispatch('GET_ROLES', this.projectId)
                    this.isShowModal = false
                }
            } else {
                this.materialTypes.forEach(item => {
                    formData.append('materials[][material_type_id]', item.id)
                })
                this.uploadMaterials.forEach(item => {
                    if (item.name) {
                        formData.append('materials[][attachment]', item)
                    }
                })

                this.createdRole(formData)
            }
        },
        roleMaterials(item, index, id, v) {
            let formData = new FormData()

            formData.append('material_type_id', this.materialTypes[index].id)
            formData.append('attachment', item)

            if (v) roleMaterial.updateRoleMaterial(id, formData)
            if (!v && item.name && !item.id) roleMaterial.createRoleMaterial(id, formData)
        },
        getShootingDates(shootingDates) {
            let dates = []

            if (shootingDates.length) {
                if (this.isMultiCalendar) { // type = Choose specific days
                    dates = shootingDates
                } else { // range
                    if (this.isValidDate(shootingDates[1])) { // two days is valid
                        dates = this.fillMissingDates(
                            shootingDates[0],
                            shootingDates[1]
                        )
                    } else {
                        dates.push(shootingDates[0])
                    }
                }
            }

            return dates
        },
        getRole() {
            role.getRole(this.projectId, this.roleId)
                .then(res => {
                    this.form.name = res.data.name
                    this.form.description = res.data.description
                    this.form.actingGender = res.data.acting_gender.map(i => this.actingGenderOptions[i])
                    this.form.address = res.data.address
                    this.form.city = res.data.city
                    if (res.data.country) {
                        this.form.countryId = res.data.country.name
                    }
                    this.form.ethnicity = res.data.ethnicity
                    this.form.maxAge = res.data.max_age != null ? res.data.max_age : ''
                    this.form.minAge = res.data.min_age != null ? res.data.min_age : ''
                    this.form.paymentCurrency = res.data.currency
                    this.form.paymentType = res.data.payment_type
                    this.form.personalSkills = res.data.personal_skills
                    this.form.rate = res.data.rate != null ? String(res.data.rate) : ''
                    
                    if (res.data.materials.length) {
                        res.data.materials.forEach(material => {
                            this.uploadMaterials.unshift({
                                id: material.id,
                                name: material.attachment.name
                            })
                            this.materialTypes.push(material.attachment.attachment_type)
                        })
                    }

                    if (res.data.application_deadline) {
                        this.form.applicationDeadline = [new Date(res.data.application_deadline)]
                    }

                    if (res.data.pick_shooting_date_type === 1) { // specific days
                        this.isMultiCalendar = true
                        setTimeout(() => {
                            this.form.pickShootingDate = res.data.pick_shooting_dates.map(i => {
                                let date = new Date(i.date)
                                date.setHours(0,0,0,0)

                                return date
                            })
                        }, 500)
                    } else { // range
                        if (res.data.pick_shooting_dates.length) {
                            this.form.pickShootingDate = [
                                new Date(res.data.pick_shooting_dates[0].date),
                                new Date(res.data.pick_shooting_dates[res.data.pick_shooting_dates.length - 1].date)
                            ]
                        }
                    }
                })
        },
        createdRole(formData) {
            this.disableSubmit = true

            role.createRole(this.projectId, formData)
                .then(res => {
                    if (res.status === 201) {
                        this.notifySuccess('Role created!')

                        this.isShowModal = false

                        this.$store.dispatch('GET_ROLES', this.projectId)
                        this.disableSubmit = false
                    }
                })
                .catch(() => {
                    this.disableSubmit = false
                })
        },
        async addRoleMaterials() {
            return await Promise.all(this.uploadMaterials.map(async (item, index) => {
                let formData = new FormData()

                if (item.name && !item.id) {
                    formData.append('attachment', item)
                    formData.append('material_type_id', this.materialTypes[index].id)

                    let response = await roleMaterial.createRoleMaterial(this.roleId, formData)

                    return response
                }

                return Promise.resolve(null)
            }))
        }
    },
    computed: {
        disablePaymentFields() {
            if (!this.form.paymentType) {
                return false
            }

            if ([7, 8].includes(this.form.paymentType.id)) {
                return true
            }

            return false
        },
        isFormFilled() {
            if (
                !this.disablePaymentFields &&
                (!this.form.rate.length || !this.form.paymentCurrency)
            ) {
                return false
            }

            if (!this.form.applicationDeadline.length && !this.disableApplicationDateCalendar) {
                return false
            }

            if (
                this.form.name.length &&
                this.form.description.length &&
                this.form.paymentType
            ) {
                return true;
            }

            return false;
        }
    },
    created() {
        if (this.roleId) {
            this.getRole()
        }

        this.getEthnicity()
        this.getPersonSkills()
        this.getMaterialType()
        this.getCurrency()
        this.getPaymentTypes()
    }
}
</script>