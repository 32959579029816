const state = {
    isSpinner: false
}

const getters = {
    isSpinner: state => state.isSpinner
}

const mutations = {
    SET_IS_SPINNER(state, payload) {
        state.isSpinner = payload
    }
}

export default {
    state,
    mutations,
    getters
}