<template>
    <div class="account-settings">
        <b-modal
            id="account-settings-modal"
            ref="ref-modal"
            centered
            v-model="isShowModal"
            hide-footer
            hide-header
        >
            <div class="account-settings-body">
                <!-- Close icon in the upper right corner -->
                <div class="account-settings-close">
                    <span class="account-settings-close__icon">
                        <img
                            @click="isShowModal = false"
                            :src="getImageClose"
                            alt="Close account settings"
                            height="20"
                        />
                    </span>
                </div>

                <!-- Upload Avatar, show username -->
                <div class="account-settings-upload-avatar">
                    <!-- preview image -->
                    <div class="account-settings-upload-avatar__preview">
                        <template>
                            <img v-if="user.avatar" :src="user.avatar" alt="Profile avatar">
                            <div v-else><span>{{ user.first_name | firstChar | capitalize }}</span></div>
                        </template>
                    </div>

                    <!-- icon upload -->
                    <label for="file-input">
                        <img
                            class="account-settings-upload-avatar__icon"
                            :src="getImageUploadAvatar"
                            alt="Upload account avatar"
                            height="20"
                        />
                    </label>
                    <input id="file-input" type="file" @change="previewFiles"/>

                    <!-- title -->
                    <div class="account-settings-username">
                        <span class="account-settings-username__title">
                            {{ getFullName }}
                        </span>
                    </div>
                </div>

                <!-- Contact info -->
                <div class="account-settings-contact-info">
                    <div class="account-settings-product-company">
                        <div class="account-settings-product-company__title">
                            Production company:
                        </div>
                        <span class="account-settings-product-company__name">
                            {{ user.company_name | capitalize }}
                        </span>
                    </div>

                    <div class="account-settings-contact-information">
                        <div class="account-settings-contact-information__title">
                            Contact information:
                        </div>
                        <div class="account-settings-contact-information__phone">
                            <img :src="getImagePhone" alt="phone">
                            <span class="account-settings-contact-information__name">{{ getPhoneNumber() }}</span>
                        </div>
                        <div class="account-settings-contact-information__email">
                            <img :src="getImageEmail" alt="email">
                            <span class="account-settings-contact-information__name">{{ user.email }}</span>
                        </div>
                    </div>
                </div>

                <!-- Change password -->
                <div class="account-settings-change-password">
                    <div class="account-settings-change-password__title">Change password:</div>
                    <div class="account-settings-change-password__form">
                        <div class="account-settings-change-password__fields">
                            <div class="general-hint">
                                <label>
                                    <transition name="fade-transform">
                                        <span v-if="form.currentPassword" class="input-hint account-hint">Current password</span>
                                    </transition>
                                    <input
                                        :type="isShowCurrentPassword ? 'text' : 'password'"
                                        placeholder="Current password"
                                        v-model="form.currentPassword"
                                    >
                                </label>
                            </div>

                            <img
                                v-if="isShowCurrentPassword"
                                @click="isShowCurrentPassword = !isShowCurrentPassword"
                                :src="getImageHidePassword"
                                alt="Eye hide password"
                            >
                            <img
                                v-else
                                @click="isShowCurrentPassword = !isShowCurrentPassword"
                                :src="getImageShowPassword"
                                alt="Show password"
                            >

                            <div class="general-hint">
                                <label>
                                    <transition name="fade-transform">
                                        <span v-if="form.newPassword" class="input-hint account-hint">New password</span>
                                    </transition>
                                    <input
                                        :type="isShowNewPassword ? 'text' : 'password'"
                                        placeholder="New password"
                                        v-model="form.newPassword"
                                    >
                                </label>
                            </div>
                            <img
                                v-if="isShowNewPassword"
                                @click="isShowNewPassword = !isShowNewPassword"
                                :src="getImageHidePassword"
                                alt="Eye hide password"
                            >
                            <img
                                v-else
                                @click="isShowNewPassword = !isShowNewPassword"
                                :src="getImageShowPassword"
                                alt="Show password"
                            >
                        </div>

                        <div class="account-settings-change-password__button">
                            <button @click="updatePassword">update password</button>
                        </div>
                    </div>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import notify from '@/mixins/notify';
import user from '@/api/user';

export default {
    name: 'AccountSettings',
    mixins: [notify],
    filters: {
        capitalize(value) {
            if (!value) return ''

            value = value.toString()
            return value.charAt(0).toUpperCase() + value.slice(1)
        },
        firstChar(value) {
            if (!value) return ''

            return value.charAt(0)
        }
    },
    data() {
        return {
            getImageUploadAvatar: require('@/assets/images/upload-avatar.svg'),
            getImageClose: require('@/assets/images/close.png'),
            getImagePhone: require('@/assets/images/phone.svg'),
            getImageEmail: require('@/assets/images/email.svg'),
            getImageHidePassword: require('@/assets/images/eye-hide-password.svg'),
            getImageShowPassword: require('@/assets/images/eye-password.svg'),

            isShowModal: false,
            isShowCurrentPassword: false,
            isShowNewPassword: false,
            url: null,
            form: {
                currentPassword: '',
                newPassword: ''
            }
        }
    },
    methods: {
        ...mapMutations([
            'SET_ME'
        ]),
        showModal() { this.isShowModal = true },
        async previewFiles(event) {
            try {
                /**
                 * Get file
                 */
                const file = event.target.files[0];

                /**
                 * Convert to base64
                 */
                let base64 = await this.toBase64(file)

                /**
                 * Update in database
                 */

                user.uploadAvatar({avatar: base64}).then((res) => {
                    this.SET_ME(res.data)
                })
            } catch(error) {
                console.error(error);
                return;
            }
        },
        updatePassword() {
            if (this.form.currentPassword === '' || this.form.newPassword === '') {
                this.notifyError('Fill in all fields!')
                return
            }

            const form = {
                "old_password": this.form.currentPassword,
                "password": this.form.newPassword,
                "password_confirmation": this.form.newPassword
            }

            user.changePassword(form).then((res) => {
                this.form.currentPassword = ''
                this.form.newPassword = ''
                this.isShowModal = false
                this.notifySuccess(res.data.message)
            })
        },
        async toBase64(file) {
            return  new Promise((resolve, reject) => {
                const reader = new FileReader()
                reader.readAsDataURL(file)
                reader.onload = () => resolve(reader.result)
                reader.onerror = error => reject(error)
            })
        },
        getPhoneNumber() {
            if (this.user.phone_number[0] === '+') {
                return this.user.phone_number
            }

            return '+' + this.user.phone_number
        }
    },
    computed: {
        ...mapGetters({
            user: 'getMe'
        }),
        getFullName() {
            return `${this.$options.filters.capitalize(this.user.first_name)}
                ${this.$options.filters.capitalize(this.user.last_name)}`
        }
    }
}
</script>