import role from '@/api/role'

const state = {
    role: {},
    roles: []
}

const getters = {
    getRole: state => state.role,
    getRoles: state => state.roles
}

const mutations = {
    SET_ROLE(state, payload) { state.role = payload },
    SET_ROLES(state, payload) { state.roles = payload }
}

const actions = {
    GET_ROLE({commit}, data) {
        return role.getRole(data.project_id, data.role_id).then((role) => {
            commit('SET_ROLE', role.data);
            return role.data
        })
    },
    GET_ROLES({commit}, id) {
        return role.getRoles(id).then((roles) => {
            commit('SET_ROLES', roles.data.data);
            return roles.data.data
        })
    }
}
export default {
    state,
    getters,
    mutations,
    actions
}